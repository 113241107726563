import { UPDATE_PAGE_PATH, LOGIN_ACTION_DONE, LOGIN_BY_COOKIE } from '../constants'

const initialState = {
  path: 'plans',
  logged: false,
};

function app(state = initialState, action) {
  switch (action.type) {
    case UPDATE_PAGE_PATH:
      return {
        ...state,
        path: action.payload.params.path
      };
    case LOGIN_ACTION_DONE:
      return {
        ...state,
        logged: true,
        shopInfo: action.payload.json
      };
    case LOGIN_BY_COOKIE:
      return {
        ...state,
        logged: true,
      };
    default:
      return state
  }
}

export default app
