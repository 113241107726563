import { GET_LOGISTIC_BY_CLAIM_SUCCESS, UPDATE_SENDER_RECEIVER_INFO } from '../constants'
const initialState = {
    parcel: null,
    senderInfo: null,
    receiverInfo: null
}
export default (state = initialState, action) => {
    switch (action.type) {
        case GET_LOGISTIC_BY_CLAIM_SUCCESS:
            const { parcel } = action.payload.data
            return {
                ...state,
                parcel
            }
        case UPDATE_SENDER_RECEIVER_INFO:
            const { senderInfo, receiverInfo } = action.payload.data
            return {
                ...state,
                senderInfo,
                receiverInfo
            }
        default:
            return state
    }
}