import React from 'react';
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'

import withStyles from '@material-ui/core/styles/withStyles';
import { RETURN_FORM_COLOR_PRIMARY, RETURN_FORM_COLOR_GREY } from '../../constants';
import {
    Typography, Grid, Button, FormControl,
    TextField, FormControlLabel, Checkbox,
    IconButton, Dialog, DialogTitle, DialogActions,
    DialogContent,
    InputLabel, Select
} from '@material-ui/core'
import EditIcon from '@material-ui/icons/Edit';
import ReturnItem from '../../components/ReturnItem';
import { updateSenderReceiverInfo } from '../../actions/claim'
import { genGeneralAddress } from 'utils'
import log from 'utils/debug'
import AlertDialog from '../../components/AlertDialog'
import CountryList, { getCountryCode } from 'utils/countryList'


const styles = theme => ({
    appBar: {
        position: 'relative',
    },
    layout: {
        width: 'auto',
        marginLeft: theme.spacing.unit * 2,
        marginRight: theme.spacing.unit * 2,
        [theme.breakpoints.up(600 + theme.spacing.unit * 2 * 2)]: {
            width: 600,
            marginLeft: 'auto',
            marginRight: 'auto',
        },
    },
    paper: {
        marginTop: theme.spacing.unit * 3,
        marginBottom: theme.spacing.unit * 3,
        padding: theme.spacing.unit * 2,
        [theme.breakpoints.up(600 + theme.spacing.unit * 3 * 2)]: {
            marginTop: theme.spacing.unit * 6,
            marginBottom: theme.spacing.unit * 6,
            padding: theme.spacing.unit * 3,
        },
    },
    stepper: {
        padding: `${theme.spacing.unit * 3}px 0 ${theme.spacing.unit * 5}px`,
    },
    buttons: {
        display: 'flex',
        justifyContent: 'flex-end',
    },
    button: {
        marginTop: theme.spacing.unit * 3,
        marginLeft: theme.spacing.unit,
    },
    formControl: {
        margin: theme.spacing.unit,
        minWidth: 120,
    },
    main: {
        height: '100%',

        display: 'flex',
        flexDirection: 'column'
    },
    titleBlock: {
        paddingTop: '24px',
        marginLeft: '20px',
        marginBottom: '24px'
    },
    titleSpanBlock: {
        marginBottom: '12px'
    },
    titleSpan: {
        color: RETURN_FORM_COLOR_PRIMARY
    },
    grayTitleSpan: {
        color: RETURN_FORM_COLOR_GREY,
        marginBottom: '6px'
        // lineHeight: 2.2
    },
    textSpan: {
        lineHeight: 1.4
    },
    contentBlock: {
        flexGrow: 1,
        overflowY: 'auto'
    },
    testBlock: {
        backgroundColor: 'red',
        height: '80px'
    },
    refundItemBlock: {
        marginTop: '12px',
        marginLeft: '20px',
    },
    itemList: {
        marginTop: '12px',
        marginLeft: '20px',
        marginRight: '20px',
    }
});

class InDialog extends React.Component {
    state = {
        email: '',
        phone: '',
        address: '',
        zip: '',
        country: '',
        city: '',
        _name: '',
        emailError: false,
        phoneError: false,
        _nameError: false,
    }
    componentDidMount() {
        log(this.props)
        const { data } = this.props
        const { email, phone, address, zip, country, city, _name } = data
        this.setState({
            email, phone, address, zip, country, city, _name
        })
    }
    handleTextChange = name => event => {
        this.setState({
            [name]: event.target.value
        })
    }
    handleSumbit = () => {
        const { email, phone, _name } = this.state
        const emailError = !email
        const phoneError = !phone
        const _nameError = !_name
        if (emailError || phoneError || _nameError) {
            this.setState({
                emailError,
                phoneError,
                _nameError
            })
        } else {
            this.props.handleSumbit({ ...this.state })
        }

    }

    render() {
        const { handleClose } = this.props
        const { _name, email, phone, address, zip, country, city, emailError, phoneError, _nameError } = this.state
        const countryCode = getCountryCode(country)
        return (
            <Dialog
                open={true}
                onClose={handleClose}
                aria-labelledby="form-dialog-title"
            >
                <DialogTitle id="form-dialog-title">Edit</DialogTitle>
                <DialogContent>
                    <Grid container spacing={8}>
                        <Grid item sm={6} xs={12}>
                            <TextField
                                margin="dense"
                                id="_name"
                                label="Name"
                                fullWidth
                                value={_name}
                                error={_nameError}
                                helperText={emailError ? "Please fill in" : ""}
                                onChange={this.handleTextChange('_name')}
                            />
                        </Grid>
                        <Grid item sm={6} xs={12}>
                            <TextField
                                margin="dense"
                                id="email"
                                label="Email"
                                type="email"
                                fullWidth
                                value={email}
                                error={emailError}
                                helperText={emailError ? "Please fill in" : ""}
                                onChange={this.handleTextChange('email')}
                            />
                        </Grid>
                        <Grid item sm={6} xs={12}>
                            <TextField
                                margin="dense"
                                id="phone"
                                label="Phone"
                                fullWidth
                                value={phone}
                                error={phoneError}
                                helperText={phoneError ? "Please fill in" : ""}
                                onChange={this.handleTextChange('phone')}
                            />
                        </Grid>
                        <Grid item sm={6} xs={12}>
                            <FormControl >
                                <InputLabel htmlFor="country-native-simple">Country</InputLabel>
                                <Select
                                    native
                                    value={countryCode}
                                    onChange={this.handleTextChange('country')}
                                    inputProps={{
                                        name: 'country',
                                        id: 'country-native-simple',
                                    }}
                                >
                                    {CountryList.map((country, index) => (
                                        <option value={country.value} key={`country-option-${index}`}>{country.label}</option>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item sm={6} xs={12}>
                            <TextField
                                margin="dense"
                                id="zip"
                                label="Zip"
                                fullWidth
                                value={zip}
                                onChange={this.handleTextChange('zip')}
                            />
                        </Grid>
                        <Grid item sm={6} xs={12}>
                            <TextField
                                margin="dense"
                                id="city"
                                label="City"
                                fullWidth
                                value={city}
                                onChange={this.handleTextChange('city')}
                            />
                        </Grid>
                        <Grid item sm={12} xs={12}>
                            <TextField
                                margin="dense"
                                id="address"
                                label="Address"
                                fullWidth
                                value={address}
                                multiline
                                rowsMax="4"
                                onChange={this.handleTextChange('address')}
                            />
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={this.handleSumbit} color="primary">
                        Done
                    </Button>
                </DialogActions>
            </Dialog>
        )
    }
}



class ItemConfirm extends React.Component {
    state = {
        checked: false,
        fromInfoObject: null,
        toInfoObject: null,
        fromFormOpen: false,
        toFormOpen: false,
        invalidMsgComponent: null,
        invalidCheck: false,
        itemMap: {}
    };
    componentDidMount() {
        const { order, claim } = this.props;
        const { data } = order
        const shipping_address = JSON.parse(data.order.shipping_address)
        const fromInfoObject = claim.senderInfo || {
            address: shipping_address.Address1 + shipping_address.Address2,
            email: data.order.email,
            phone: data.order.phone,
            country: shipping_address.Country,
            city: shipping_address.City,
            zip: shipping_address.Zip,
            _name: shipping_address.Name,
        }
        const toInfoObject = claim.receiverInfo || {
            address: data.shop.address.address1 + data.shop.address.address2,
            email: data.shop.email,
            phone: data.shop.phone,
            country: data.shop.address.country,
            city: data.shop.address.City,
            zip: data.shop.address.zip_code,
            _name: data.shop.shop_name,
        }
        let itemMap = {}
        if (order && order.data) {
            for (let i = 0; i < order.data.items.length; i++) {
                const item = order.data.items[i]
                itemMap[item.item_id] = item
            }
        }
        this.setState({
            fromInfoObject,
            toInfoObject,
            itemMap
        })
    }
    handleCountryMapping = value => {
        const val = value.toLowerCase()
        if (val === 'singapore') {
            return 'SG'
        }
        if (val === 'taiwan') {
            return 'TW'
        }
        return value
    }
    handleChange = name => event => {
        this.setState({ [name]: event.target.checked });
        // s
    }
    handleSumbit = name => data => {
        this.setState({
            [name]: {
                ...data
            },
            fromFormOpen: false,
            toFormOpen: false,
        })
    }
    handleOpen = name => () => {
        this.setState({
            [name]: true
        })
    }
    handleClose = name => () => {
        this.setState({
            [name]: false
        })
    }
    isValid = () => {
        const state = this.state
        return state.checked 
        //return state.checked && state.fromInfoObject.email !== '' && state.fromInfoObject.phone !== '' && state.toInfoObject.email !== '' && state.toInfoObject.phone !== ''
    }
    handleNextButton = () => {
        const fromInfoObject = this.state.fromInfoObject
        fromInfoObject["country"] = this.handleCountryMapping(fromInfoObject["country"])
        const toInfoObject = this.state.toInfoObject
        toInfoObject["country"] = this.handleCountryMapping(toInfoObject["country"])
        this.props.updateSenderReceiverInfo(fromInfoObject, toInfoObject)
        this.props.history.push("/lazada/return/confirm/return_form");
    }
    handleInvalidCheckClose = () => {
        this.setState({
            invalidCheck: false,
            invalidMsgComponent: null
        })
    }
    render() {
        const { classes, order } = this.props;
        const { fromInfoObject, toInfoObject, invalidCheck, invalidMsgComponent, itemMap } = this.state
        log(this.state)
        const { data } = order
        const { claim } = data
        const fromName = fromInfoObject ? fromInfoObject._name : "'"
        const fromEmail = fromInfoObject ? fromInfoObject.email : ""
        const fromPhone = fromInfoObject ? fromInfoObject.phone : ""
        const returnFrom = fromInfoObject ? genGeneralAddress(fromInfoObject) : ""
        const toName = toInfoObject ? toInfoObject._name : "'"
        const returnTo = toInfoObject ? genGeneralAddress(toInfoObject) : ""
        const returnEmail = toInfoObject ? toInfoObject.email : ""
        const returnPhone = toInfoObject ? toInfoObject.phone : ""
        const isValid = this.isValid()
        return (
            <React.Fragment>
                <main className={classes.main}>
                    <div className={classes.titleBlock}>
                        <div className={classes.titleSpanBlock}>
                            <Typography variant="display3" className={classes.titleSpan}>Please confirm receiver's address and contact information</Typography>
                        </div>
                        <Grid container spacing={8}>
                            <Grid item xs={12} sm={12} container style={{ marginTop: 15 }}>
                                <Grid item xs={8} sm={9}>
                                    <Typography variant="display1" className={classes.grayTitleSpan}>Receiver information</Typography>
                                    <Typography variant="display1" className={classes.textSpan}>Address: {returnFrom}</Typography>
                                    <Typography variant="display1" className={classes.textSpan}>Email: {fromEmail}</Typography>
                                    <Typography variant="display1" className={classes.textSpan}>Phone: {fromPhone}</Typography>
                                </Grid>
                                <Grid item xs={4} sm={2}>
                                    <IconButton aria-label="Edit" onClick={this.handleOpen("fromFormOpen")}>
                                        <EditIcon fontSize="small" />
                                    </IconButton>
                                </Grid>
                            </Grid>
                        </Grid>
                    </div>
                    <div className={classes.contentBlock}>
                        <div className={classes.titleSpanBlock} style={{ marginLeft: '20px' }}>
                            <Typography variant="display3" className={classes.titleSpan}>Please confirm claim details</Typography>
                        </div>

                        <div className={classes.itemList}>
                            {claim.items ?
                                claim.items.map((item, index) => {
                                    const isEnd = index + 1 === data.items.length
                                    return (<ReturnItem key={item.name} item={item} isEnd={isEnd} itemDetail={itemMap[item.itemId]}/>)
                                }) : null}
                        </div>
                    </div>
                    <div style={{ padding: 12, marginTop: 20 }}>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={this.state.checked}
                                    onChange={this.handleChange('checked')}
                                    value="checked"
                                    color="primary"
                                />
                            }
                            label="I agree and confirm that all details (receiver's address, phone number and email address) filled out in the form are correct."
                        />
                        <Button fullWidth variant="extendedFab" color="primary" onClick={this.handleNextButton} disabled={!isValid}>Confirm</Button>
                    </div>
                </main>
                {this.state.fromFormOpen && <InDialog data={fromInfoObject} handleClose={this.handleClose("fromFormOpen")} handleSumbit={this.handleSumbit("fromInfoObject")} />}
                {this.state.toFormOpen && <InDialog data={toInfoObject} handleClose={this.handleClose("toFormOpen")} handleSumbit={this.handleSumbit("toInfoObject")} />}
                {invalidCheck &&
                    (<AlertDialog open={invalidCheck} handleClose={this.handleInvalidCheckClose}>
                        <div style={{ padding: 10 }}>{invalidMsgComponent}</div>
                    </AlertDialog>)
                }
            </React.Fragment>
        );
    }
}

const mapDispatchToProps = dispatch => bindActionCreators({
    updateSenderReceiverInfo
}, dispatch)

const mapStateToProps = ({ lazadaApp: { order, claim } }) => ({
    order,
    claim
})

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(styles)(withRouter(ItemConfirm)))
