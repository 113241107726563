import React from 'react'
import {
    Badge,
} from "@shopify/polaris";
const ClaimStatus = ({ status }) => {
    if (status == "created") {
        return (
            <Badge status="attention">PENDING APPROVAL</Badge>
        )
    }
    if (status == "approved") {
        return (
            <Badge status="success">APPROVED</Badge>
        )
    }
    if (status == "rejected") {
        return (
            <Badge status="warning">REJECTED</Badge>
        )
    }
    if (status == "shipped") {
        return (
            <Badge status="info">SHIPPED</Badge>
        )
    }
    return (
        <Badge status="warning">PENDING APPROVAL</Badge>
    )
}

export default ClaimStatus