import {
    UPDATE_CLAIM_PAYWAY_SUCCESS, LOGGING_SUCCESS,
    CREATE_CLAIM_SUCCESS, GET_CLAIM_DATA_SUCCESS,
    CREATE_LOGISTIC_REFUND_SUCCESS
} from '../constants'


const initialState = {
    data: null,
    item: null,
    afterClaimed: false,
    status: ''
}


export default (state = initialState, action) => {
    switch (action.type) {
        case LOGGING_SUCCESS:
            // const details = JSON.parse(action.payload.data.order.details)
            return {
                ...state,
                data: action.payload.data,
                // item: details.line_items,
            };
        case CREATE_CLAIM_SUCCESS:
            return {
                ...state,
                afterClaimed: true,
                data: Object.assign({}, state.data, { claim: action.payload.data.claim }),
                status: CREATE_CLAIM_SUCCESS
            }
        case UPDATE_CLAIM_PAYWAY_SUCCESS:
            return {
                ...state,
                afterClaimed: true,
                data: Object.assign({}, state.data, { claim: action.payload.data.claim }),
                status: UPDATE_CLAIM_PAYWAY_SUCCESS
            }
        case CREATE_LOGISTIC_REFUND_SUCCESS:
            return {
                ...state,
                afterClaimed: true,
                data: Object.assign({}, state.data, { claim: action.payload.data.claim }),
                status: CREATE_LOGISTIC_REFUND_SUCCESS
            }
        case GET_CLAIM_DATA_SUCCESS:
            return {
                ...state,
                data: action.payload.data,
            };
        default:
            return state
    }
}