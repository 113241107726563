import React, { Component, useState } from 'react';
import Cookies from 'js-cookie';
import { Button, Spinner } from '@shopify/polaris';
import {
  Grid, Dialog, DialogContent, DialogActions
} from '@material-ui/core'
import ContentSelector from './ContentSelector';
import log from 'utils/debug'
import Tip from '../ArrowToolTip'

const TabContext = React.createContext();

function TabButton({ text, onClick, selected, index }) {
  return (
    <div style={{ margin: 5, display: 'inline-block', width: 150 }}>
      <Button size="slim" onClick={onClick(index)} primary={selected === index} fullWidth>{text}</Button>
    </div>
  )
}

function TutorialModal({ children, open, handleClose, isStart, isEnd, selfIndex }) {
  return (
    <Dialog
      open={open}
      onClose={handleClose(-1)}
    >
      <DialogContent>
        {children}
      </DialogContent>
      <DialogActions>
        {!isStart &&
          (
            <Button onClick={handleClose(selfIndex - 1)} >
              Pre
                        </Button>
          )
        }
        <div style={{ flex: 1 }} />
        {!isEnd &&
          (
            <Button onClick={handleClose(selfIndex + 1)} >
              Next
                        </Button>
          )
        }
        {isEnd &&
          (
            <Button onClick={handleClose(-1)} primary>
              Go to tutorial
                        </Button>
          )
        }
      </DialogActions>
    </Dialog>
  )
}
function TutorialImage({ src }) {
  return (
    <div style={{ position: "relative", width: '100%', margin: "10px 0px" }}>
      <div style={{ position: "absolute", left: "50%", width: "20px" }}>
        <Spinner size="small" color="teal" />
      </div>

      <img src={src} alt="tutorial" style={{ position: "relative", width: '100%' }} />
    </div>
  )
}
function TutorialModals({ ind, setInd }) {
  return (
    <React.Fragment>
      <TutorialModal open={ind === 0} selfIndex={0} isStart isEnd handleClose={(value) => () => setInd(value)}>
        <div>
          {"Thank for using Igloo Returns."}
        </div>
        <div>
          {"Because this is your first time of using Igloo Returns"}
        </div>
        <div>
          {"Please follow guide in toturial tab"}
        </div>
        <div>
          {"It will teach you how to use Igloo Returns"}
        </div>
      </TutorialModal>
    </React.Fragment>
  )
}


class TabsComponent extends Component {
  state = {
    selected: 0,
    showHint: false,
    showTutorialModel: false,
    isFirst: false,
    modalInd: 0,
  };

  handleTabChange = (selectedTabIndex) => {
    this.setState({ selected: selectedTabIndex });
  };
  handleButtonClick = i => () => {
    if (i == 1) {
      Cookies.set("yopify_fr_tip_show", true)
      this.setState({ selected: i, showHint: false });
    } else {
      this.setState({ selected: i });
    }
  }
  toggleShowHint = () => {
    this.setState({ showHint: !this.state.showHint })
  }
  componentDidMount() {
    const notFirst = Cookies.get("yopify_fr_not_first_time")
    if (!notFirst) {
      Cookies.set("yopify_fr_not_first_time", true)
      this.setState({ selected: 5, isFirst: true })
    } else {
      const afterSetupButton = Cookies.get("ax_click_setup_button")
      if (afterSetupButton) {
        this.setState({ selected: 5 })
      }
    }
  }
  toggleShowTutorialModal = () => {
    this.setState({ showTutorialModel: !this.state.showTutorialModel, modalInd: 0 })
  }
  setTutorialModalInd = (value) => {
    this.setState({
      modalInd: value,
      showTutorialModel: value !== -1,
    })
  }
  render() {
    const { selected, showHint, isFirst, modalInd, showTutorialModel } = this.state;
    const isOpen = (selected === 0 && (!Cookies.get("yopify_fr_tip_show") || showHint))
    return (

      <div style={{ height: '100%', display: 'flex', flexDirection: "column" }}>
        <div style={{ textAlign: 'right', padding: 5 }}>
          <TabButton text={"Return Orders"} index={0} selected={selected} onClick={this.handleButtonClick} />
          <Tip open={isOpen}>
            <TabButton text={"Products"} index={1} selected={selected} onClick={this.handleButtonClick} />
          </Tip>
          {/* <TabButton text={"Dashboard"} index={2} selected={selected} onClick={this.handleButtonClick} /> */}
          <TabButton text={"Invoice"} index={3} selected={selected} onClick={this.handleButtonClick} />
          <TabButton text={"Support"} index={4} selected={selected} onClick={this.handleButtonClick} />
          <TabButton text={"Tutorial"} index={5} selected={selected} onClick={this.handleButtonClick} />
        </div>
        <TabContext.Provider value={{ toggleShowHint: this.toggleShowHint, toggleShowTutorialModal: this.toggleShowTutorialModal }}>
          <ContentSelector selected={selected} />
        </TabContext.Provider>
        {(isFirst || showTutorialModel) &&
          <TutorialModals ind={modalInd} setInd={this.setTutorialModalInd} />
        }
      </div>

    )
  }
}

export default TabsComponent;
const TabContextConsumer = TabContext.Consumer;
function withTabContext(Component) {
  return (props) => {
    return (
      <TabContextConsumer>
        {value =>
          <Component {...props} tabContext={value} />
        }
      </TabContextConsumer>
    )
  }
}
export { TabContextConsumer, withTabContext }