import sha256 from 'crypto-js/sha256';
import axFetch from './fetch'

export function uploadImagePromise(file, source, key) {
    let formData = new FormData();
    formData.append('file', file, sha256(source))
    const fetchOptions = {
        method: 'POST',
        body: formData
    }
    return axFetch('/v1/uploadfile', fetchOptions)
        .then(json => { return ({ key: key, data: json }) })
}

export function setupReturnYoPromise() {
    const fetchOptions = {
        method: 'PUT',
        headers: {
            ...DefaultHeader
        },
        credentials: 'include',
    }
    return axFetch('/v1/insurances/freereturn/setup', fetchOptions)
        .then(json => { return ({}) })
}

export function resetReturnYoPromise() {
    const fetchOptions = {
        method: 'PUT',
        headers: {
            ...DefaultHeader
        },
        credentials: 'include',
    }
    return axFetch('/v1/insurances/freereturn/reset', fetchOptions)
        .then(json => { return ({}) })
}

export function statusHandle(response) {
    if (response.status >= 400) {
        return Promise.reject(response)
    }
    return response.json()
}

export const DefaultHeader = {
    'Accept': 'application/json',
    'Content-Type': 'application/json'
}
export const API_URL = process.env.REACT_APP_API_URL || 'https://api.yopify.staging.axinan.com'


