import React from 'react';

import withStyles from '@material-ui/core/styles/withStyles';
import {
    Button,
} from '@material-ui/core'
// import CameraIcon from '@material-ui/icons/CameraAlt'
import CameraIcon from '../../assets/ic_addphoto.png'


const styles = theme => ({
    uploadButton: {
        height: '80px',
        backgroundColor: 'rgb(244, 243, 251)'
    },
    uploadButtonIcon: {
        marginRight: '10px',
        width: 20,
    },
    input: {
        display: 'none',
    },
    previewImage: {
        width: '100%'
    }
});


class UploadPhoto extends React.Component {
    state = {
        submitAvailable: false
    }
    handleClickShowPassword = () => {

    }
    render() {
        const { classes, handleImageChange, imgSrc } = this.props;
        return (
            <React.Fragment>
                {imgSrc ?
                    (<img src={imgSrc} alt='receipt preview' className={classes.previewImage} />)
                    :
                    (
                        <React.Fragment>
                            <input
                                accept="image/*;capture=camera"
                                className={classes.input}
                                id="outlined-button-file"
                                multiple
                                type="file"
                                value={imgSrc || ""} // NOTE: this is for change the component's value, so onChange will be fired each time when you have selected one
                                onChange={handleImageChange}
                            />
                            <label htmlFor="outlined-button-file">
                                <Button fullWidth variant="outlined" color="primary" component="span" className={classes.uploadButton}>
                                    <img src={CameraIcon} className={classes.uploadButtonIcon} alt='' />
                                    Add photo
                        </Button>
                            </label>
                        </React.Fragment >
                    )
                }
            </React.Fragment >
        );
    }
}


export default withStyles(styles)(UploadPhoto)
