import React from 'react'
import {
    Badge,
} from "@shopify/polaris";
const claimStatus = ['created', 'rejected', 'approved', 'shipped']
const ClaimGroupStatus = ({ subClaims }) => {
    let status = ''
    const subClaimLength = subClaims.length
    let statusMap = {}
    subClaims.forEach( claim => {
        if(statusMap[claim.status] == null) {
            statusMap[claim.status] = 1
        } else {
            statusMap[claim.status] = statusMap[claim.status] + 1
        }
    })
    claimStatus.forEach(s => {
        if(statusMap[s] != null) {
            if(statusMap[s] < subClaimLength) {
                status = `PARTIAL ${s.toUpperCase()}`
            } else {
                status =  s.toUpperCase()
            }
        }
    })
    if (status == "CREATED") {
        return (
            <Badge status="attention">PENDING APPROVAL</Badge>
        )
    }
    if (status == "APPROVED" || status == "PARTIAL APPROVED") {
        return (
            <Badge status="success">{status}</Badge>
        )
    }
    if (status == "REJECTED" || status == "PARTIAL REJECTED") {
        return (
            <Badge status="warning">{status}</Badge>
        )
    }
    if (status == "SHIPPED" || status == "PARTIAL SHIPPED") {
        return (
            <Badge status="info">{status}</Badge>
        )
    }
    return (
        <Badge status="info">APPROVED</Badge>
    )
}

export default ClaimGroupStatus