import React, { Component } from 'react'
import {
    Button,
    Checkbox,
    Badge,
    DataTable,
    Icon,
    Card,
    Select
} from "@shopify/polaris";
import {
    Grid, Dialog
} from '@material-ui/core'

class ProductSkus extends Component {
    state = {
        active: false,
        selectedValue: '',
        currentProductId: "",
        handleChecked: {},
        updateProductsData: {
            activate: {},
            unactivate: {}
        }
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        if (nextProps.currentProductId != prevState.currentProductId) {
            let handleChecked = {}
            nextProps.data.forEach(element => {
                handleChecked[element.variants.id] = {
                    checked: false,
                    hasPolicy: (element.policy) ? true : false
                }
            })
            return {
                currentProductId: nextProps.currentProductId,
                handleChecked: handleChecked,
                updateProductsData: {
                    activate: {},
                    unactivate: {}
                }
            }
        } else {
            return null
        }
    }

    handleChange = (variantId, value) => {
        let { handleChecked, updateProductsData } = this.state
        const data = handleChecked[variantId]
        handleChecked[variantId].checked = value
        this.setState({ handleChecked: handleChecked })
        if (value) {
            if (data.hasPolicy) {
                updateProductsData.unactivate[variantId] = data
            } else {
                updateProductsData.activate[variantId] = data
            }
        } else {
            delete updateProductsData.unactivate[variantId]
            delete updateProductsData.activate[variantId]
        }
        this.setState({ updateProductsData: updateProductsData })
    }

    handleSubmit = (type) => () => {
        const { handleChecked } = this.state
        let variantIds = []
        for (let key in handleChecked) {
            if (handleChecked[key].checked == true) {
                variantIds.push(key)
            }
        }
        let createPolicyParams = {}
        if (type) {
            createPolicyParams.activate = variantIds
        } else {
            createPolicyParams.unactivate = variantIds
        }
        const { externalFunc } = this.props
        externalFunc(createPolicyParams)
    }

    createRowElement = (element, rows) => {
        const { handleChecked } = this.state
        const checked = (handleChecked[element.variants.id] ? handleChecked[element.variants.id].checked : false)
        const row = [
            element.variants.title,
            element.variants.price,
            element.variants.shopify_variant_id,
            <PolicyStatus data={element} />,
            (element.policy) ? element.policy.id : '',
            <Checkbox
                checked={checked}
                onChange={(value) => this.handleChange(element.variants.id, value)}
            />
        ]
        rows.push(row)
    }

    initRows = () => {
        const { data } = this.props
        const { selectedValue } = this.state
        let rows = []
        if (selectedValue == '') {
            data.forEach(element => {
                this.createRowElement(element, rows)
            })
        } else if (selectedValue == 'notProteced') {
            data.forEach(element => {
                if (!element.policy || element.policy.status != 'created' && element.policy.status != 'approved') {
                    this.createRowElement(element, rows)
                }
            })
        } else {
            data.forEach(element => {
                if (element.policy && element.policy.status == selectedValue) {
                    this.createRowElement(element, rows)
                }
            })
        }
        return rows
    }

    handleSelect = (newValue) => {
        this.setState({ selectedValue: newValue })
    }

    render() {
        const { showModal } = this.props
        const rows = this.initRows()
        const options = [
            { label: 'Get All', value: '' },
            { label: 'Pending approval', value: 'created' },
            { label: 'Protected', value: 'approved' },
            { label: 'Not proteced', value: 'notProteced' },
        ];
        return (
            <Dialog
                fullScreen
                open={showModal}
                onClose={this.props.toggleModalFuc}
            >
                <div style={{ padding: "20px" }}>
                    <Grid container
                        alignItems="center"
                        spacing={16}
                        style={{ padding: "10px" }}
                    >
                        <Grid item >
                            <Button onClick={this.props.toggleModalFuc} icon={<Icon source="arrowLeft" />} />
                        </Grid>
                        <Grid item>

                        </Grid>
                        <Grid item >
                            <Button onClick={this.handleSubmit(true)}>Cover</Button>
                        </Grid>
                        <Grid item >
                            <Button onClick={this.handleSubmit(false)}>Uncover</Button>
                        </Grid>
                    </Grid>
                    <Grid container
                        alignItems="center"
                        style={{
                            margin: '20px 0px',
                            padding: 10
                        }}>
                        <Select
                            label="Filter by status"
                            options={options}
                            onChange={this.handleSelect}
                            value={this.state.selectedValue}
                        />
                    </Grid>
                    <Grid container spacing={16} style={{ margin: "20px 0px" }}>
                        <div style={{ flex: 1, padding: 10 }}>
                            <Card>
                                <DataTable
                                    columnContentTypes={[
                                        'text',
                                        'text',
                                        'text',
                                        'text',
                                        'text',
                                        'text',
                                    ]}
                                    headings={[
                                        'Title',
                                        'Price',
                                        'Shopify variant ID',
                                        'Policy status',
                                        'Policy ID',
                                        '',
                                    ]}
                                    rows={rows}
                                />
                            </Card>
                        </div>
                    </Grid>
                </div>
            </Dialog>
        )
    }
}

const PolicyStatus = ({ data }) => {
    if (!data.policy) {
        return (
            <Badge>NOT PROTECTED</Badge>
        )
    }
    if (data.policy.status == "created") {
        return (
            <Badge status="info">PENDING APPROVAL</Badge>
        )
    }
    if (data.policy.status == "approved") {
        return (
            <Badge status="success">PROTECTED</Badge>
        )
    }
    return (
        <Badge>NOT PROTECTED</Badge>
    )
}

export default ProductSkus