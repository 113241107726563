import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux'

import MyApp from './containers/App';
import '@shopify/polaris/styles.css';
import './index.css';
import {
  Spinner,
  Button,
  TextContainer
} from "@shopify/polaris";
import { login, resetApiStatus, loginByCookie } from './actions'
import { TOKEN_NAME, API_DONE, DEFAULT_PRIMARY_COLOR } from './constants'
import { getShopFromJWT } from 'utils'
import { API_URL } from 'utils/apiUtils'

class App extends Component {
  state = {
    apiStatus: null,
    loginStatus: null,
    redirectPending: false,
  }

  componentDidMount() {
    const searchString = this.props.location.search.slice(1)
    const searchParams = new URLSearchParams(searchString);
    const { logged } = this.props.app
    const hmac = searchParams.get('hmac')
    if (hmac && hmac !== '') {
      if (logged) {
        this.setState({ loginStatus: 'logged' })
      } else {
        // const shop = getShopFromJWT(TOKEN_NAME)
        // if (shop && shop !== '' && shop === searchParams.get('shop')) {
        //   this.setState({ loginStatus: 'logged' }, () => {
        //     this.props.loginByCookie()
        //   })
        // } else {
        //   const { login, location } = this.props
        //   this.setState({ apiStatus: 'loading' }, () => {
        //     login(location.search)
        //   })
        // }
        const { login, location } = this.props
        this.setState({ apiStatus: 'loading' }, () => {
          login(location.search)
        })
      }
    } else {
      this.setState({
        redirectPending: true
      })
    }
  }
  componentWillReceiveProps(nextProps) {
    if (this.state.apiStatus === 'loading') {
      if (nextProps.api.status !== this.props.api.status && nextProps.api.status === API_DONE) {
        const { resetApiStatus } = this.props
        this.setState({
          loginStatus: 'logged',
          apiStatus: null
        }, function () { resetApiStatus() })
      }
    }
  }

  handleChange = (url) => {
    // if we do window.location, we are still in an iframe. so the redirection
    // will be rejected due to X-Frame-Options DENY
    // so we needs to make a redirection from PARENT window
    // also see here: https://community.shopify.com/c/Shopify-APIs-SDKs/OAuth-API-admin-oauth-authorize-X-Frame-Options-DENY/m-p/348656
    window.top.location.href = url
  };
  handleManuallyRedirect = () => {
    const searchString = this.props.location.search.slice(1)
    const searchParams = new URLSearchParams(searchString);

    window.location = `https://${searchParams.get('shop')}.myshopify.com/admin/apps`
  }
  render() {
    const { shopInfo } = this.props.app
    const { loginStatus, redirectPending } = this.state
    if (!loginStatus) {
      return (
        <div>
          <div style={{ width: '65px', margin: '0 auto', paddingTop: 50 }}>
            <Spinner size="large" color="teal" />

          </div>
          {redirectPending &&
            (
              <div style={{ margin: '0 auto', width: 200 }}>
                <Button
                  style={{ margin: '0 auto', position: "block", width: 200 }}
                  onClick={this.handleManuallyRedirect}
                >
                  Click here, if page does not redirect in minutes.
            </Button>
              </div>
            )
          }
        </div>
      )
    }
    if (shopInfo && shopInfo.shop_info && shopInfo.shop_info.is_valid_charge_status === false) {
      const redirectUrl = `${API_URL}/v1/merchants/auth/servicecharge?shop=${shopInfo.shop_info.shop_name}&insurance=freeReturn`
      return (
        <div style={{ padding: '0px 40px' }}>
          <TextContainer>
            <h6 style={{ paddingTop: 30, paddingBottom: '20px', fontWeight: 'bold', fontSize: '20px', color: DEFAULT_PRIMARY_COLOR }}>You have to approve application charge to continue using our app</h6>
          </TextContainer>
          <div>
            <Button
              style={{ margin: '0 auto', position: "block" }}
              onClick={
                () => {
                  this.handleChange(redirectUrl)
                }
              }
            >
              Click here to approve
            </Button>
          </div>
        </div>
      )
    }
    return (
      <MyApp />
    );
  }
}


function mapStateToProps({ shopSystem: { api, app } }) {
  return { api, app };
}
const mapDispatchToProps = dispatch => bindActionCreators({
  login,
  resetApiStatus,
  loginByCookie
}, dispatch)
export default connect(mapStateToProps, mapDispatchToProps)(App);
