import _ from 'lodash';
import { TOKEN_NAME } from '../constants'
import { getFreeReturnIdFromJWT } from 'utils/index'

export function search(items, key, value) {
    const returList = [];
    items.forEach(element => {
        if (_.includes(element[key], value)) {
            returList.push(Object.assign({}, element))
        }
    });
    return returList
}

export function getFreeReturnObject(insurances) {
    return insurances.filter(i => i.id === getFreeReturnIdFromJWT(TOKEN_NAME))
}

export function haveFreeReturn(insurances) {
    return insurances && insurances.length > 0 && getFreeReturnObject(insurances).length > 0
}