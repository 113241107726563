import { combineReducers } from 'redux'

import returnSystem from './App/freeReturn/returnSystem/modules'
import shopSystem from './App/freeReturn/shopSystem/modules'
import InventoryCashbackShopApp from './App/inventoryCashBack/shopSystem/modules'
import lazadaApp from './App/lazada/returnSystem/modules'

export default combineReducers({
    returnSystem,
    shopSystem,
    InventoryCashbackShopApp,
    lazadaApp
})
