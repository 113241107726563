import _ from 'lodash';
import {
    FILTER_PRODUCT_FREE_RETURN_STATUS, SEARCH_PRODUCT,
    UPDATE_PRODUCT_PLAN, GET_PRODUCTS_DONE, ACTIVE_PRODUCT_INSURANCE,
    BEFORE_ACTIVE_PRODUCT_INSURANCE, BEFORE_DEACTIVE_PRODUCT_INSURANCE,
    UPLOAD_FILE_DONE, TOKEN_NAME
} from '../constants';
import { search } from '../helper/filter'
import { getFreeReturnIdFromJWT } from 'utils'

const items = [
    {
        id: 'Skeleton-123',
        isSkeleton: true
    }
];

const initialState = {
    totalProductList: items,
    afterFilterProductList: items
};
const filterMapping = {
    'free return': 'freeReturn',
    'inventory protection': 'inventoryProtection',
    'secret plan': 'secretPlan'
}

const filterByFreeReturn = (items, filter) => {
    const returList = [];
    if (filter.key === 'covered') {
        const filterKey = filterMapping[filter.value]
        items.forEach(element => {
            if (element[filterKey]) {
                returList.push(Object.assign({}, element))
            }
        });
    } else if (filter.key === 'uncovered') {
        const filterKey = filterMapping[filter.value]
        items.forEach(element => {
            if (!element[filterKey]) {
                returList.push(Object.assign({}, element))
            }
        });
    }
    return returList
}

const updateProductPlan = (original, items, key, value) => {
    return original.map(map => {
        let newObject = Object.assign({}, map)
        if (_.includes(items, map.id)) {
            newObject[key] = value
            return newObject
        }
        return newObject;
    })
}

const activeProducts = (payload, list) => {
    const { insurance, products } = payload;
    const returnArray = list.map(data => {
        const findedObject = _.find(products, { id: data.id })
        if (findedObject) {
            const insuranceObject = {};
            insuranceObject[insurance] = true;
            return Object.assign({}, data, insuranceObject);
        }
        return Object.assign({}, data);
    })
    return returnArray;
}
const deactiveProducts = (payload, list) => {
    const { insurance, products } = payload;
    const returnArray = list.map(data => {
        const findedObject = _.find(products, { id: data.id })
        if (findedObject) {
            const insuranceObject = {};
            insuranceObject[insurance] = false;
            return Object.assign({}, data, insuranceObject);
        }
        return Object.assign({}, data);
    })
    return returnArray;
}

const updateSubscriptionProducts = (updatedList, totalData) => {
    const FREE_RETURN_ID = getFreeReturnIdFromJWT(TOKEN_NAME)
    const returnArray = totalData.map(data => {
        if (updatedList.activate && updatedList.activate.length > 0) {
            const targetList = updatedList.activate
            const isFind = targetList.indexOf(data.id)
            if (isFind >= 0) {
                const insuranceObject = {};
                insuranceObject.insurances = data.insurances || []
                insuranceObject.insurances.push({ id: FREE_RETURN_ID })
                return Object.assign({}, data, insuranceObject);
            }
        }
        if (updatedList.unactivate && updatedList.unactivate.length > 0) {
            const targetList = updatedList.unactivate

            const isFind = targetList.indexOf(data.id)
            if (isFind >= 0) {
                const insuranceObject = {};
                insuranceObject.insurances = data.insurances || []
                const removedObject = insuranceObject.insurances.filter(i => {
                    return i.id !== FREE_RETURN_ID
                })
                insuranceObject.insurances = removedObject
                return Object.assign({}, data, insuranceObject);
            }
        }

        return Object.assign({}, data);
    })
    return returnArray;
}


function product(state = initialState, action) {
    switch (action.type) {
        case FILTER_PRODUCT_FREE_RETURN_STATUS:
            return {
                ...state,
                afterFilterProductList: filterByFreeReturn(state.totalProductList, action.payload.filter[0])
            };
        case SEARCH_PRODUCT:
            return {
                ...state,
                afterFilterProductList: search(state.totalProductList, action.payload.key, action.payload.value)
            };
        case UPDATE_PRODUCT_PLAN:
            const list = updateProductPlan(state.totalProductList, action.payload.items, action.payload.key, action.payload.value);
            return {
                ...state,
                totalProductList: list,
                afterFilterProductList: list
            };
        case GET_PRODUCTS_DONE:
            const products = action.payload.json.data
            return {
                ...state,
                totalProductList: products,
                afterFilterProductList: products
            };
        case BEFORE_ACTIVE_PRODUCT_INSURANCE:
            const afterActiveProducts = activeProducts(action.payload, state.totalProductList);
            return {
                ...state,
                totalProductList: afterActiveProducts,
                afterFilterProductList: afterActiveProducts
            };
        case BEFORE_DEACTIVE_PRODUCT_INSURANCE:
            const afterDeactiveProducts = deactiveProducts(action.payload, state.totalProductList);
            return {
                ...state,
                totalProductList: afterDeactiveProducts,
                afterFilterProductList: afterDeactiveProducts
            };
        case ACTIVE_PRODUCT_INSURANCE:
            const afterUpdatedProducts = updateSubscriptionProducts(action.payload.data, state.totalProductList);
            return {
                ...state,
                totalProductList: afterUpdatedProducts,
                afterFilterProductList: afterUpdatedProducts
            };
        case UPLOAD_FILE_DONE:
            return {
                ...state
            };
        default:
            return state
    }
}

export default product
