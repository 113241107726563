import { withApiStatus } from 'utils/withApiStatus'
import { API_START, API_DONE, API_ERROR } from '../constants'

const WithApiStatus = withApiStatus({
    API_START,
    API_DONE,
    API_ERROR,
    handleError: {
        404: function (dispatchData) {
            return dispatchData
        },
    }
})

export default WithApiStatus

export const WrappedApiStatusDispatch = (promise) => {
    return dispatch => {
        WithApiStatus(dispatch)(promise)
    }
}
