import {
    LOGGING_SUCCESS, LOGOUT_SUCCESS, CHANGE_ORDER_ID_SUCCESS,
    GET_CLAIM_DATA_SUCCESS, DEFAULT_BEFORE_API, DEAULT_AFTER_API
} from '../constants'


const initialState = {
    pending: false,
    logged: false,
    loaded: false,
    claimed: false,
    orderId: '',
    shop: null,
    errorInfo: null
}

export default (state = initialState, action) => {
    switch (action.type) {
        case DEFAULT_BEFORE_API:
            return {
                ...state,
                errorInfo: null,
                pending: true
            }
        case DEAULT_AFTER_API:
            const { error } = action.payload
            return {
                ...state,
                pending: false,
                errorInfo: error || null
            }
        case LOGGING_SUCCESS:
            return {
                ...state,
                loaded: true,
                pending: false,
                claimed: action.payload.claimed,
                shop: action.payload.shop
            };
        case LOGOUT_SUCCESS:
            return {
                ...state,
                orderId: '',
                logged: false
            };
        case CHANGE_ORDER_ID_SUCCESS:
            return {
                ...state,
                orderId: action.payload.orderId
            };
        case GET_CLAIM_DATA_SUCCESS:
            return {
                ...state,
                loaded: true,
            };
        default:
            return state
    }
}