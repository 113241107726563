import React from 'react';
import App from './containers/app'
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import { RETURN_FORM_COLOR_PRIMARY, RETURN_FORM_COLOR_BLACK, RETURN_FORM_COLOR_SECONDARY } from './constants';
import CssBaseline from '@material-ui/core/CssBaseline';

const theme = createMuiTheme({
    palette: {
        primary: {
            main: RETURN_FORM_COLOR_PRIMARY,
        },
        secondary: {
            main: RETURN_FORM_COLOR_SECONDARY,
        },
        background: {
            default: '#ffffff'
        }
    },
    typography: {
        // Use the system font instead of the default Roboto font.
        fontFamily: [
            'Poppins',
            '-apple-system',
            'BlinkMacSystemFont',
            '"Segoe UI"',
            'Roboto',
            '"Helvetica Neue"',
            'Arial',
            'sans-serif',
            '"Apple Color Emoji"',
            '"Segoe UI Emoji"',
            '"Segoe UI Symbol"',
        ].join(','),
        title: {
            fontSize: '16px',
            fontWeight: 600,
            color: RETURN_FORM_COLOR_BLACK
        },
        display1: {
            fontSize: '11px',
            fontWeight: 500,
            color: RETURN_FORM_COLOR_BLACK
        },
        display2: {
            fontSize: '14px',
            fontWeight: 500,
            color: RETURN_FORM_COLOR_BLACK
        },
        display3: {
            fontSize: '14px',
            fontWeight: 600,
            color: RETURN_FORM_COLOR_BLACK
        },
        display4: {
            fontSize: '16px',
            fontWeight: 500,
            color: RETURN_FORM_COLOR_BLACK
        },
    },
});

const ReturnApp = () => (
    <MuiThemeProvider theme={theme}>
        <CssBaseline />
        <App />
    </MuiThemeProvider>
)

export default ReturnApp;
