import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from 'redux'
import {
    Button,
    DataTable,
    Checkbox,
    TextField,
    Icon,
} from "@shopify/polaris";
import {
    Grid
} from '@material-ui/core'
import _ from 'lodash';
import moment from 'moment'

import {
    getProducts,
    updateActivation,
    exportCsv,
    importCsv
} from '../../actions/Product'
import { DEFAULT_PRIMARY_COLOR, DATE_FORMAT_DEFAULT } from '../../constants'
import { haveFreeReturn } from '../../helper/filter'
import ConfirmDialog from '../../components/ConfirmDialog'
import { withRouter } from "react-router-dom";
import FreeReturnIcon from './freereturn.png'

const styles = {
    actionButton: {
        marginLeft: 10,
        marginTop: 5,
        display: 'inline-block'
    }
}



class Product extends Component {
    state = {
        selectedItems: [],
        coveredMap: {},
        active: false,
        unactive: false,
        clickedItem: {},
        searchValue: '',
        actionSelected: 'freeReturn',
        selectAll: false,
        activeNum: 0,
        unActiveNum: 0,
    };


    handleSelectChange = (newValue) => {
        this.setState({ actionSelected: newValue });
    };
    componentDidMount() {
        const { getProducts } = this.props;
        getProducts();
    }
    handleSelectallChange = () => {
        let activeNum = 0, unActiveNum = 0, selectedItems = []
        if (!this.state.selectAll) {
            const items = this.filterWithSearch(this.props.product.totalProductList);
            items.forEach(item => {
                selectedItems.push(item.id)
                const isCoveredByFreeReturn = haveFreeReturn(item.insurances)
                if (isCoveredByFreeReturn) {
                    activeNum += 1
                } else {
                    unActiveNum += 1
                }
            })
        }
        this.setState({ selectAll: !this.state.selectAll, activeNum, unActiveNum, selectedItems })

    }
    handleSearchTextChange = (value) => {
        this.setState({ searchValue: value });
    };
    handleCheckChange = (item, status, isActive) => () => {
        this.handleItemSelectUpdate(item, !status, isActive)
    }
    handleItemSelectUpdate = (item, status, isActive) => {
        const { selectedItems } = this.state
        let { activeNum, unActiveNum } = this.state
        let selectAll = false
        const value = status ? 1 : - 1
        if (status) {
            selectedItems.push(item.id)
            const items = this.filterWithSearch(this.props.product.totalProductList)
            selectAll = selectedItems.length === items.length
        } else {
            selectedItems.splice(selectedItems.indexOf(item.id), 1)
        }
        if (isActive) {
            activeNum += value
        } else {
            unActiveNum += value
        }

        this.setState({ selectedItems, activeNum, unActiveNum, selectAll })
    }
    handleActiveButton = () => {
        this.props.updateActivation('activate', this.state.selectedItems)
        this.setState({
            activeNum: this.state.selectedItems.length,
            unActiveNum: 0
        })
    }
    handleUnactiveButton = () => {
        this.props.updateActivation('unactivate', this.state.selectedItems)
        this.setState({
            unActiveNum: this.state.selectedItems.length,
            activeNum: 0
        })
    }
    handleClickAllActiveButton = () => {
        this.setState({ active: true })
    }
    handleClose = () => {
        this.setState({ active: false, unactive: false })
    }
    handleAllActiveButton = () => {
        const { product: { totalProductList } } = this.props;
        const selectedItems = totalProductList.map(p => (p.id))
        this.props.updateActivation('activate', selectedItems)
        this.setState({
            activeNum: 0,
            unActiveNum: 0,
            selectedItems: [],
            active: false
        })
    }
    handleClickAllUnactiveButton = () => {
        this.setState({ unactive: true })
    }
    handleAllUnactiveButton = () => {
        const { product: { totalProductList } } = this.props;
        const selectedItems = totalProductList.map(p => (p.id))
        this.props.updateActivation('unactivate', selectedItems)
        this.setState({
            activeNum: 0,
            unActiveNum: 0,
            selectedItems: [],
            unactive: false
        })
    }
    filterWithSearch = (data) => {
        return data.filter(d => {
            return this.state.searchValue === '' || _.includes(d.handle, this.state.searchValue)
        })
    }
    genFreeReturnBadge = (isCoveredByFreeReturn) => {
        return isCoveredByFreeReturn ?
            (
                <img src={FreeReturnIcon} style={{ width: 30 }} alt='' />
            )
            :
            (
                <img src={FreeReturnIcon}
                    style={{
                        width: 30, filter:
                            'grayscale(75%)',
                        opacity: 0.3
                    }}
                    alt=''
                />
            )
    }
    renderItem = (item) => {
        if (item.isSkeleton) {
            return ["", "", "", ""]
        }
        const isCoveredByFreeReturn = haveFreeReturn(item.insurances)
        const imagesJson = item.images && item.images.map(m => JSON.parse(m))
        const badge = this.genFreeReturnBadge(isCoveredByFreeReturn)
        const checkecd = this.state.selectedItems.indexOf(item.id) >= 0 || this.state.selectAll
        const imgSrc = (imagesJson.length > 0 && imagesJson[0].Src) || ""
        return ([
            <Checkbox
                checked={checkecd}
                onChange={this.handleCheckChange(item, checkecd, isCoveredByFreeReturn)}
            />,
            (<Grid container direction="row"
                alignItems="center">
                <Grid item style={{ marginRight: 10 }}>
                    <img src={imgSrc} style={{ width: 30, height: 30 }} alt='' />
                </Grid>
                <Grid item>
                    <span>{item.handle}</span>
                </Grid>
            </Grid>),
            <span>{moment(item.created_at).format(DATE_FORMAT_DEFAULT)}</span>,
            badge
        ])
    }

    handleExport = () => {
        const { exportCsv } = this.props
        exportCsv()
    }

    handleImport = () => {
        this.handleClick()
    }
    handleClick = () => {
        this.inputElement.click();
    }
    handleUpload = target => (e) => {
        e.preventDefault();
        const { importCsv, getProducts } = this.props
        let file = e.target.files[0];
        if (file) {
            importCsv({
                file: file
            }).then(r => {
                getProducts();
            })
        }
    }

    render() {
        const { product } = this.props;
        const { selectAll, activeNum, unActiveNum } = this.state
        let dataComponent = null
        if (!product || !product.totalProductList || product.totalProductList.length === 0 || product.totalProductList[0].isSkeleton) {
            dataComponent = (
                <div style={{ padding: 40, backgroundColor: "#f4f6f8", color: "#999999" }}>
                    <div style={{ fontSize: 30, margin: 20 }}>
                        You do not have products at the moment.
                            </div>
                    <div style={{ margin: 20 }}>
                        You can click "Create product" button to create your first product.
                        {' '}<a onClick={() => { window.ShopifyApp.redirect("/products"); }} style={{ color: "#3834c8", cursor: "pointer", textDecoration: "underline" }}>Create product</a>
                    </div>
                </div>
            )
        }
        else {
            const items = this.filterWithSearch(product.totalProductList);
            const rows = items.map(item => {
                return this.renderItem(item)
            })
            dataComponent = (
                <DataTable
                    columnContentTypes={[
                        'text',
                        'text',
                        'text',
                        'text',
                    ]}
                    headings={[
                        '',
                        'Product name',
                        'Created by',
                        'Free return insurance',
                    ]}
                    rows={rows}
                />
            )
        }


        return (
            <div style={{ padding: '0px 40px' }}>
                <Grid container
                    alignItems="center"
                    style={{
                        margin: '5px 0px'
                    }}>
                    <Grid item style={{ marginRight: 20 }}>
                        <span style={{ fontSize: 24, fontWeight: 700, color: DEFAULT_PRIMARY_COLOR }}>Products</span>
                    </Grid>
                    <Grid item style={{ flex: '1 1' }}>
                        <TextField
                            placeholder="Search by product name"
                            value={this.state.searchValue}
                            onChange={this.handleSearchTextChange}
                            suffix={<Icon source="search" />}
                        />
                    </Grid>
                </Grid>
                <Grid
                    container direction="row-reverse"
                    justify="space-between"
                    alignItems="center"
                    style={{
                        margin: '20px 0px'
                    }}
                >
                    <Grid item xs={12} sm={4} container justify="flex-end" direction="row">
                        <React.Fragment>
                            <Grid style={{ display: 'inline-block', color: 'black' }}>
                                <Button plain icon="import" onClick={
                                    () => {
                                        this.handleExport()
                                    }
                                }>Export CSV</Button>
                            </Grid>
                            <Grid style={{ marginLeft: 20, display: 'inline-block', color: 'black' }}>
                                <input
                                    accept=".csv"
                                    multiple
                                    type="file"
                                    hidden
                                    onChange={this.handleUpload()}
                                    ref={input => this.inputElement = input}
                                />
                                <Button plain icon="export" onClick={
                                    () => {
                                        this.handleImport()
                                    }
                                }>Import CSV</Button>
                            </Grid>
                        </React.Fragment>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <div style={styles.actionButton}>
                            <Button size="slim" onClick={this.handleActiveButton} disabled={unActiveNum === 0} primary>Cover</Button>
                        </div>
                        <div style={styles.actionButton}>
                            <Button size="slim" onClick={this.handleUnactiveButton} disabled={activeNum === 0} primary>Remove</Button>
                        </div>
                        <div style={styles.actionButton}>
                            <Button size="slim" onClick={this.handleClickAllActiveButton} primary>Cover all</Button>
                        </div>
                        <div style={styles.actionButton}>
                            <Button size="slim" onClick={this.handleClickAllUnactiveButton} primary>Remove all</Button>
                        </div>
                        <div style={styles.actionButton}>
                            <Button size="slim" plain onClick={() => {
                                window.ShopifyApp.redirect("/products");
                            }} primary>Create products</Button>
                        </div>
                    </Grid>
                    <Grid item xs={12} sm={2}>
                        <Checkbox
                            label="Select all"
                            checked={selectAll}
                            onChange={this.handleSelectallChange}
                        />
                    </Grid>
                </Grid>
                {dataComponent}
                <ConfirmDialog open={this.state.active} handleClose={this.handleClose} handleConfirm={this.handleAllActiveButton}>
                    <h2>Are you sure to cover "free return" in all products?</h2>
                </ConfirmDialog>
                <ConfirmDialog open={this.state.unactive} handleClose={this.handleClose} handleConfirm={this.handleAllUnactiveButton}>
                    <h2>Are you sure to remove "free return" in all products?</h2>
                </ConfirmDialog>

            </div >
        );
    }
}

function mapStateToProps({ shopSystem: { product, plan } }) {
    return { product, plan };
}
const mapDispatchToProps = dispatch => bindActionCreators({
    getProducts,
    updateActivation,
    exportCsv,
    importCsv
}, dispatch)
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Product));

