import React, { Component } from 'react';

class App extends Component {
    render() {
        const searchString = this.props.location.search.slice(1)
        const searchParams = new URLSearchParams(searchString);
        const msg = searchParams.get("msg")
        return (
            <div style={{ width: 500, margin: '0 auto' }}>
                <div><h2>Something Wrong</h2></div>
                <div><h3>{msg}</h3></div>
                <div style={{ marginTop: 30 }}><h3>Please contact pr@axinan.com</h3></div>
            </div>
        );
    }
}

export default App;
