import axFetch from 'utils/fetch'
import { DefaultHeader } from 'utils/apiUtils'


export function createClaim(claims) {
    const fetchOptions = {
        method: 'POST',
        headers: {
            ...DefaultHeader
        },
        credentials: 'include',
        body: JSON.stringify({ claims })
    }
    return axFetch(`/v1/inventorycashback/claim`, fetchOptions)
}

export function listClaims() {
    const fetchOptions = {
        method: 'GET',
        headers: {
            ...DefaultHeader
        },
        credentials: 'include',
    }
    return axFetch(`/v1/inventorycashback/claims`, fetchOptions)
 }

 export function updateClaim(claimId, updateClaimParams) {
    const fetchOptions = {
        method: 'PUT',
        headers: {
            ...DefaultHeader
        },
        credentials: 'include',
    }
    return axFetch(`/v1/inventorycashback/claims/${claimId}`, fetchOptions, updateClaimParams)
 }

export function createClaimShippingLabel(params) {
    const fetchOptions = {
        method: 'POST',
        headers: {
            ...DefaultHeader
        },
        credentials: 'include',
    }
    return axFetch(`/v1/inventorycashback/claim/shipping:create`, fetchOptions, params)
}

export function markClaimsAsShipped(params) {
    const fetchOptions = {
        method: 'POST',
        headers: {
            ...DefaultHeader
        },
        credentials: 'include',
    }
    return axFetch(`/v1/inventorycashback/claim/parcel:create`, fetchOptions, params)
}