import { withApiStatus } from 'utils/withApiStatus'
import { API_START, API_DONE, API_ERROR } from '../constants'
import log from 'utils/debug'

export default withApiStatus({
    API_START,
    API_DONE,
    API_ERROR,
    handleError: {
        404: function (dispatchData) {
            log("Custome Handler 404")
            return dispatchData
        },
        405: function (dispatchData) {
            log("Custome Handler 405")
            log(dispatchData)
            return Object.assign({}, dispatchData, {
                type: API_ERROR,
                payload: {
                    msg: 'Claim request number is over limit'
                }
            })
        }
    }
})
