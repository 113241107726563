import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux'
import { ConnectedRouter } from 'connected-react-router'
import store, { history } from './store'
import RootRouterApp from './RootRouterApp'
import registerServiceWorker from './registerServiceWorker';
import './index.css';

ReactDOM.render(
    (
        <Provider store={store}>
            <ConnectedRouter history={history}>
                <RootRouterApp />
            </ConnectedRouter>
        </Provider >
    ), document.getElementById('root'));
registerServiceWorker();
