import React from 'react';
import { compose, withState } from 'recompose'
import { withStyles } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import log from 'utils/debug'

function arrowGenerator(color) {
    return {
        '&[x-placement*="bottom"] $arrow': {
            top: 0,
            left: 0,
            marginTop: '-0.95em',
            width: '3em',
            height: '1em',
            '&::before': {
                borderWidth: '0 1em 1em 1em',
                borderColor: `transparent transparent ${color} transparent`,
            },
        },
        '&[x-placement*="top"] $arrow': {
            bottom: 0,
            left: 0,
            marginBottom: '-0.95em',
            width: '3em',
            height: '1em',
            '&::before': {
                borderWidth: '1em 1em 0 1em',
                borderColor: `${color} transparent transparent transparent`,
            },
        },
        '&[x-placement*="right"] $arrow': {
            left: 0,
            marginLeft: '-0.95em',
            height: '3em',
            width: '1em',
            '&::before': {
                borderWidth: '1em 1em 1em 0',
                borderColor: `transparent ${color} transparent transparent`,
            },
        },
        '&[x-placement*="left"] $arrow': {
            right: 0,
            marginRight: '-0.95em',
            height: '3em',
            width: '1em',
            '&::before': {
                borderWidth: '1em 0 1em 1em',
                borderColor: `transparent transparent transparent ${color}`,
            },
        },
    };
}
const styles = theme => ({
    button: {
        margin: theme.spacing.unit,
    },
    lightTooltip: {
        backgroundColor: '#3834c8',
        color: theme.palette.common.white,
        boxShadow: theme.shadows[1],
        fontSize: 20,
        padding: 20,
    },
    arrowPopper: arrowGenerator('#3834c8'),
    arrow: {
        position: 'absolute',
        fontSize: 6,
        width: '3em',
        height: '3em',
        '&::before': {
            content: '""',
            margin: 'auto',
            display: 'block',
            width: 0,
            height: 0,
            borderStyle: 'solid',
        },
    },
    bootstrapPopper: arrowGenerator(theme.palette.common.black),
    bootstrapTooltip: {
        backgroundColor: theme.palette.common.black,
    },
    bootstrapPlacementLeft: {
        margin: '0 8px',
    },
    bootstrapPlacementRight: {
        margin: '0 8px',
    },
    bootstrapPlacementTop: {
        margin: '8px 0',
    },
    bootstrapPlacementBottom: {
        margin: '8px 0',
    },
    htmlPopper: arrowGenerator('#dadde9'),
    htmlTooltip: {
        backgroundColor: '#f5f5f9',
        color: 'rgba(0, 0, 0, 0.87)',
        maxWidth: 220,
        fontSize: theme.typography.pxToRem(12),
        border: '1px solid #dadde9',
        '& b': {
            fontWeight: theme.typography.fontWeightMedium,
        },
    },
});

function ArrowToolTip(props) {
    log(props)
    const { arrowRef, handleArrowRef, classes, children, open, handleClcik } = props
    return (
        <Tooltip
            title={
                <div onClick={handleClcik}>
                    <div>
                        <div >
                            Cover your products now
                        </div>
                        <div style={{ fontSize: 14, color: "#dddddd" }}>
                            "View Detail" to select the prodcut variants you would like to cover.
                        </div>
                    </div>
                    <span className={classes.arrow} ref={handleArrowRef} />
                </div>
            }
            open={open}
            classes={{ popper: classes.arrowPopper, tooltip: classes.lightTooltip }}
            PopperProps={{
                popperOptions: {
                    modifiers: {
                        arrow: {
                            enabled: Boolean(arrowRef),
                            element: arrowRef,
                        },
                    },
                },
            }}
        >
            {children}
        </Tooltip>
    )
}
const enhance = compose(
    withStyles(styles),
    withState('arrowRef', 'handleArrowRef', null)
)

export default enhance(ArrowToolTip)