import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import { Button } from '@shopify/polaris'

class AlertDialog extends React.Component {
    handleClose = () => {
        const { handleClose } = this.props;
        handleClose()
    };

    render() {
        const { open, children } = this.props
        return (
            <React.Fragment>
                <Dialog
                    open={open}
                    onClose={this.handleClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogContent>
                        {children}
                    </DialogContent>
                    {this.props.handleClose &&
                        (<DialogActions>
                            <Button onClick={this.handleClose} plain>
                                CLOSE
                            </Button>
                        </DialogActions>
                        )}
                </Dialog>
            </React.Fragment>
        );
    }
}

export default AlertDialog;