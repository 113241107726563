import React from 'react';
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'

import withStyles from '@material-ui/core/styles/withStyles';
import { RETURN_FORM_COLOR_PRIMARY } from '../../constants';
import {
    Typography, Grid, Button,
    Drawer, ButtonBase
} from '@material-ui/core'
import MockPng from './mock.png';

const styles = theme => ({
    content: {
        width: '100%',
        height: '100%'
    },
    iconBox: {
        height: '210px',
        width: '260px',
        backgroundColor: RETURN_FORM_COLOR_PRIMARY,
        margin: '10px auto',
        borderRadius: '25px',
        textAlign: 'center'
    },
    contentText: {
        color: 'white',
        textAlign: 'center'
    },
    image: {
        width: '70%',
        marginTop: '30px'
    },
    imageRotate: {
        transform: 'rotateY(180deg)',
        width: '70%',
        marginTop: '30px'
    },
    fullList: {
        width: 'auto',
        padding: '20px'
    },
    drawerPaper: {
        position: 'absolute',
    },
    title: {
        textAlign: 'center'
    },
    drawerButton: {
        marginTop: '10px',
    }
});


class ReturnMethod extends React.Component {
    state = {
        top: false,
        left: false,
        bottom: false,
        right: false,
    };

    toggleDrawer = (side, open) => () => {
        this.setState({
            [side]: open,
        });
    };
    handleSelectSelfShip = () => {
        this.props.history.push("/freereturn/return/confirm/return_form");
    }
    handleNinJaButton = () => {
        this.props.history.push("/freereturn/return/confirm/ninja");
    }
    render() {
        const { classes, claim } = this.props;
        const isShowNinJaVan = claim.senderInfo && claim.receiverInfo && claim.senderInfo.country === 'SG' && claim.senderInfo.country === claim.receiverInfo.country
        const fullList = (
            <div className={classes.fullList}>
                <Typography variant="display2" className={classes.title}>Select refund method</Typography>
                <Button variant="contained" color="primary" fullWidth className={classes.drawerButton} component={Link} to={'/freereturn/return/confirm/paypal'}>PayPal</Button>
                <Button variant="outlined" color="primary" fullWidth className={classes.drawerButton} component={Link} to={'/freereturn/return/confirm/bank_transfer'}>Bank Transfer</Button>
            </div>
        );
        return (
            <React.Fragment>
                <Grid container spacing={16} justify="space-around" alignItems="center" className={classes.content}>
                    {isShowNinJaVan ?
                        (<Grid item xs={12} sm={6}>
                            <div className={classes.iconBox}>
                                <ButtonBase onClick={this.handleNinJaButton} >
                                    <div style={{ paddingBottom: '40px' }}>
                                        <img src={MockPng} alt="return method" className={classes.image} />
                                        <Typography variant="display2" className={classes.contentText}>Ninja Van</Typography>
                                    </div>
                                </ButtonBase>
                            </div>
                        </Grid>)
                        :
                        (<Grid item xs={12} sm={6}>
                            <div className={classes.iconBox} >
                                <ButtonBase onClick={this.handleSelectSelfShip} >
                                    <div style={{ paddingBottom: '40px' }}>
                                        <img src={MockPng} alt="return method" className={classes.imageRotate} />
                                        <Typography variant="display2" className={classes.contentText}>Ship it by yourself</Typography>
                                    </div>
                                </ButtonBase>
                            </div>
                        </Grid>)
                    }
                </Grid>
                <Drawer
                    anchor="bottom"
                    open={this.state.bottom}
                    onClose={this.toggleDrawer('bottom', false)}
                    className={classes.drawerPaper}
                >
                    <div
                        tabIndex={0}
                        role="button"
                        onClick={this.toggleDrawer('bottom', false)}
                        onKeyDown={this.toggleDrawer('bottom', false)}
                    >
                        {fullList}
                    </div>
                </Drawer>
            </React.Fragment >
        );
    }
}

const mapStateToProps = ({ returnSystem: { claim } }) => ({
    claim
})

export default connect(
    mapStateToProps, null
)(withStyles(styles)(ReturnMethod))
