import React from 'react';
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { Redirect } from 'react-router-dom'

import withStyles from '@material-ui/core/styles/withStyles';
import { OTHER_REASON } from '../../constants';
import {
    Typography, Grid, Button,
    TextField,
    CircularProgress
} from '@material-ui/core'
import DialogInfo from '../../components/DialogInfo'
import { updateRefundMethod } from '../../actions/claim'

const styles = theme => ({
    main: {
        flex: '1 1',
        display: 'flex',
        flexDirection: 'column'
    },
    content: {
        flexGrow: 1,
        overflowY: 'auto'
    },
    container: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    textField: {
        marginLeft: theme.spacing.unit,
        marginRight: theme.spacing.unit,
        paddingTop: theme.spacing.unit * 2,
    },
    uploadTitle: {
        display: 'inline-block',
        verticalAlign: 'middle'
    },
    uploadButton: {
        height: '80px',
        backgroundColor: 'rgb(244, 243, 251)'
    },
    uploadButtonIcon: {
        marginRight: '10px'
    },
    input: {
        display: 'none',
    },
    previewImage: {
        width: '100%'
    },
    buttonProgress: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -12,
        marginLeft: -12,
    },
});


class BankTransferForm extends React.Component {
    state = {
        imgSrc: null,
        file: null,
        displayName: '',
        bankAccount: '',
        address: '',
        bankName: '',
        country: '',
        bankAddress: '',
        bankCode: '',
        swiftCode: '',
        additional: '',

        // Deprecated
        accountName: '',
        branchCode: '',

        buttonLoading: false,
        open: false,
        exampleType: ''
    }
    handleClickShowPassword = () => {

    }
    handleClickOpen = value => () => {
        this.setState({
            open: true,
            exampleType: value
        });
    };

    handleClose = () => {
        this.setState({ open: false });
    };
    handleImageChange = (e) => {
        // Assuming only image
        e.preventDefault();

        let reader = new FileReader();
        let file = e.target.files[0];

        reader.onloadend = () => {
            this.setState({
                file: file,
                imgSrc: reader.result
            });
        }

        reader.readAsDataURL(file)
    }
    handleRemovePhoto = () => {
        this.setState({
            file: null,
            imgSrc: null
        });
    }
    handleTextChange = event => {
        this.setState({
            [event.target.name]: event.target.value,
        });
    };
    decideSubmitAvailable = () => {
        const state = this.state;
        if (state.displayName === '' || state.bankAccount === '' || state.address === '' ||
            state.bankName === '' || state.country === '' || state.bankAddress === '' ||
            state.bankCode === '' || state.swiftCode === ''
        ) {
            return true
        }
        return state.buttonLoading
    }
    handleSubmit = () => {
        const { claim } = this.props
        const dimensions = {
            weight: 0.0,
            volume: 0.0,
            length: 0.0,
            width: 0.0,
            height: 0.0,
            size: "small",
        }
        const timeslot = {
            start_time: "00:00",
            end_time: "00:00",
        }
        const { file, imgSrc, courier, otherCourier, refundCurrency, deliverFee, trackingId } = this.props.tempStatus.returnForm
        const logisticName = courier === OTHER_REASON ? otherCourier : courier
        const data = {
            payway: {
                type: "bankTransfer",
                displayName: this.state.displayName,
                bankAccount: this.state.bankAccount,
                address: this.state.address,
                country: this.state.country,
                bankAddress: this.state.bankAddress,
                bankCode: this.state.bankCode,
                branchCode: this.state.branchCode,
                swiftCode: this.state.swiftCode,
                bankName: this.state.bankName,
                currency: refundCurrency,
                deliverFee,
            },
            image: { file, imgSrc },
            parcel: {
                tracking_id: trackingId,
                return_logistic_name: logisticName,
                dimensions,
                timeslot,
                sender: {
                    ...claim.senderInfo,
                    address1: claim.senderInfo.address,
                    postcode: claim.senderInfo.zip,
                },
                receiver: {
                    ...claim.receiverInfo,
                    address1: claim.receiverInfo.address,
                    postcode: claim.receiverInfo.zip,
                }
            },
            notes: this.state.additional
        }
        this.setState({ buttonLoading: true })
        this.props.updateRefundMethod(this.props.order.data.claim.id, data)
        // this.props.history.push("/return/status");
    }
    genExampleInfoModal = (type) => {
        switch (type) {
            case 'bankCode':
                return (
                    <React.Fragment>
                        <Typography variant="display4">Bank Code Example</Typography>
                    </React.Fragment>
                )
            case 'branchCode':
                return (
                    <React.Fragment>
                        <Typography variant="display4">Branch Code Example</Typography>
                    </React.Fragment>
                )
            case 'swiftCode':
                return (
                    <React.Fragment>
                        <Typography variant="display4">Swift Code Example</Typography>
                    </React.Fragment>
                )
            default:
                return null
        }
    }
    genTextField = ({ name, label, placeholder }) => {
        return (<TextField
            id={`outlined-${name}-input`}
            label={label || ""}
            placeholder={placeholder || ""}
            name={name}
            margin="dense"
            variant="outlined"
            onChange={this.handleTextChange}
            value={this.state[name]}
            fullWidth
        />)
    }
    render() {
        const { classes, order } = this.props;
        const submiteAvailable = this.decideSubmitAvailable();
        if (order.afterClaimSubmitted) {
            return <Redirect to='/lazada/return/status' />
        }
        const modalComponent = this.genExampleInfoModal(this.state.exampleType)
        return (
            <React.Fragment>
                <div className={classes.main}>
                    <div className={classes.content}>
                        <div >
                            <div className={classes.textField}>
                                <Typography variant="display2">
                                    Please make sure all the information entered below is correct.
                                    We will transfer the claim expense to your account based on the
                                    information provided below.
                                </Typography>
                            </div>
                            <form className={classes.container} noValidate autoComplete="off">
                                <Grid container spacing={8} className={classes.textField}>
                                    <Grid item xs={12} sm={12}>
                                        {this.genTextField({
                                            name: "displayName",
                                            label: "Your name as per bank records",
                                            placeholder: "Account name"
                                        })}

                                    </Grid>
                                    <Grid item xs={12} sm={12}>
                                        {this.genTextField({
                                            name: "bankAccount",
                                            label: "Bank Account Number or IBAN",
                                            placeholder: "Account number*"
                                        })}

                                    </Grid>
                                    <Grid item xs={12} sm={12}>
                                        {this.genTextField({
                                            name: "address",
                                            label: "Your address",
                                        })}

                                    </Grid>
                                    <Grid item xs={12} sm={12}>
                                        {this.genTextField({
                                            name: "bankName",
                                            label: "Name of Beneficiary Bank",
                                            placeholder: "Bank Name"
                                        })}

                                    </Grid>
                                    <Grid item xs={12} sm={12}>
                                        {this.genTextField({
                                            name: "bankAddress",
                                            label: "Address of Beneficiary Bank",
                                            placeholder: "Bank Address"
                                        })}

                                    </Grid>
                                    <Grid item xs={12} sm={12}>
                                        {this.genTextField({
                                            name: "country",
                                            label: "Country",
                                        })}

                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        {this.genTextField({
                                            name: "bankCode",
                                            label: "Bank Code",
                                        })}

                                    </Grid>

                                    {/* <Grid item xs={12} sm={12}>
                                        <TextField
                                            id="outlined-bank-code-input"
                                            label="Name of Beneficiary Bank"
                                            placeholder="Bank Name"
                                            name="bankName"
                                            margin="dense"
                                            variant="outlined"
                                            value={this.state.bankName}
                                            onChange={this.handleTextChange}
                                            fullWidth
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <TextField
                                            id="outlined-bank-code-input"
                                            label="Bank Code"
                                            name="bankCode"
                                            margin="dense"
                                            variant="outlined"
                                            value={this.state.bankCode}
                                            onChange={this.handleTextChange}
                                            fullWidth
                                            InputProps={{
                                                endAdornment: (
                                                    <InputAdornment position="end">
                                                        <IconButton
                                                            aria-label="Toggle password visibility"
                                                            onClick={this.handleClickOpen('bankCode')}
                                                        >
                                                            {'?'}
                                                        </IconButton>
                                                    </InputAdornment>
                                                ),
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <TextField
                                            id="outlined-branch-code-input"
                                            label="Branch Code"
                                            name="branchCode"
                                            margin="dense"
                                            variant="outlined"
                                            value={this.state.branchCode}
                                            onChange={this.handleTextChange}
                                            fullWidth
                                            InputProps={{
                                                endAdornment: (
                                                    <InputAdornment position="end">
                                                        <IconButton
                                                            aria-label="Toggle password visibility"
                                                            onClick={this.handleClickOpen('branchCode')}
                                                        >
                                                            {'?'}
                                                        </IconButton>
                                                    </InputAdornment>
                                                ),
                                            }}
                                        />
                                    </Grid> */}
                                    <Grid item xs={12} sm={6}>
                                        {this.genTextField({
                                            name: "swiftCode",
                                            label: "SWIFT Address/ Code",
                                        })}

                                    </Grid>
                                    <Grid item xs={12} sm={12}>
                                        {this.genTextField({
                                            name: "additional",
                                            label: "Additional Information",
                                        })}
                                    </Grid>
                                </Grid>

                            </form>
                        </div>
                    </div>
                    <div style={{ padding: 12, boxShadow: '0px -3px 10px #dddddd', zIndex: 10 }}>
                        <Button fullWidth variant="extendedFab" color="primary" disabled={submiteAvailable} onClick={this.handleSubmit}>Submit</Button>
                        {this.state.buttonLoading && <CircularProgress size={24} className={classes.buttonProgress} />}
                    </div>
                    <DialogInfo open={this.state.open}
                        onClose={this.handleClose} >
                        {modalComponent}
                    </DialogInfo>
                </div>
            </React.Fragment >
        );
    }
}

const mapStateToProps = ({ lazadaApp: { order, tempStatus, claim } }) => ({
    order,
    tempStatus,
    claim,
})
const mapDispatchToProps = dispatch => bindActionCreators({
    updateRefundMethod
}, dispatch)

export default connect(
    mapStateToProps, mapDispatchToProps
)(withStyles(styles)(BankTransferForm))
