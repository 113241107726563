import React from 'react';
import { AppProvider } from '@shopify/polaris';
import log from 'utils/debug'
import { getShopFromJWT } from 'utils'

export const ShopifyConfigContext = React.createContext();

export default (SHOPIFY_KEY) => (WrappedComponent) => (
    withHoc(SHOPIFY_KEY, WrappedComponent)
)



export function withHoc(SHOPIFY_KEY, WrappedComponent) {
    return (
        class extends React.Component {
            state = {
                shopifyAppConfig: {},
                readyConfig: false,
            }
            componentDidMount() {
                // NOTE: from local environment with ngrok, as the cookie is issued by api server (with domain a.ngrok.io)
                // and the frontend server (with domain b.ngrok.io) can't access the cookies
                // (however, with credential is set, frontend is able to send cookie to api server)
                // so we have to fill cookies here for local testing
                // not sure whether ngrok's host-header can help on this matter
                const searchString = this.props.location.search.slice(1)
                const searchParams = new URLSearchParams(searchString);
                const cookieName = process.env.REACT_APP_MCT_COOKIE || ""
                log("cookiename:", cookieName)
                log("cookie:", getShopFromJWT(cookieName))

                window.apiKey = SHOPIFY_KEY
                const shop = searchParams.get('shop') || getShopFromJWT(cookieName)
                window.shopOrigin = `https://${shop}`

                const shopifyAppConfig = {
                    apiKey: window.apiKey,
                    shopOrigin: window.shopOrigin,
                }
                log(shopifyAppConfig)
                // shopifyAppConfig.forceRedirect = false;
                window.ShopifyApp.init(shopifyAppConfig);
                this.setState({ shopifyAppConfig, readyConfig: true })
            }
            render() {
                const { shopifyAppConfig: { apiKey, shopOrigin }, readyConfig } = this.state;
                log(apiKey, shopOrigin)
                if (!readyConfig && (!apiKey || !shopOrigin || shopOrigin === "https://null")) {
                    return (
                        <div />
                    )
                }
                return (
                    <AppProvider shopOrigin={shopOrigin} apiKey={apiKey}>
                        <ShopifyConfigContext.Provider value={{ apiKey, shopOrigin }}>
                            <WrappedComponent {...this.props} {...this.state} />
                        </ShopifyConfigContext.Provider>
                    </AppProvider>
                )
            }
        }
    )
}
