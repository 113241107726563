import React from 'react';
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'

import withStyles from '@material-ui/core/styles/withStyles';
import { RETURN_FORM_COLOR_PRIMARY, RETURN_FORM_COLOR_GREY } from '../../constants';
import {
    Typography, Grid, Button,
    CircularProgress
} from '@material-ui/core'
import { updateRefundMethod, getLogisticByClaim } from '../../actions/claim'
import AdornedButton from '../../components/AdornedButton'
import MyMapComponent from '../../components/MyMapComponent'
import { API_URL } from 'utils/apiUtils'

const styles = theme => ({
    appBar: {
        position: 'relative',
    },
    layout: {
        width: 'auto',
        marginLeft: theme.spacing.unit * 2,
        marginRight: theme.spacing.unit * 2,
        [theme.breakpoints.up(600 + theme.spacing.unit * 2 * 2)]: {
            width: 600,
            marginLeft: 'auto',
            marginRight: 'auto',
        },
    },
    paper: {
        marginTop: theme.spacing.unit * 3,
        marginBottom: theme.spacing.unit * 3,
        padding: theme.spacing.unit * 2,
        [theme.breakpoints.up(600 + theme.spacing.unit * 3 * 2)]: {
            marginTop: theme.spacing.unit * 6,
            marginBottom: theme.spacing.unit * 6,
            padding: theme.spacing.unit * 3,
        },
    },
    stepper: {
        padding: `${theme.spacing.unit * 3}px 0 ${theme.spacing.unit * 5}px`,
    },
    buttons: {
        display: 'flex',
        justifyContent: 'flex-end',
    },
    button: {
        marginTop: theme.spacing.unit * 3,
        marginLeft: theme.spacing.unit,
    },
    formControl: {
        margin: theme.spacing.unit,
        minWidth: 120,
    },
    main: {
        height: '100%',

        display: 'flex',
        flexDirection: 'column'
    },
    titleBlock: {
        paddingTop: '24px',
    },
    titleSpanBlock: {
    },
    titleSpan: {
        color: RETURN_FORM_COLOR_PRIMARY,
        textAlign: 'center'
    },
    grayTitleSpan: {
        color: RETURN_FORM_COLOR_GREY,
        marginBottom: '6px'
        // lineHeight: 2.2
    },
    textSpan: {
        lineHeight: 1.4
    },
    contentBlock: {
        flexGrow: 1,
        overflowY: 'auto',
        marginBottom: 20,
        paddingBottom: 20
    },
    testBlock: {
        backgroundColor: 'red',
        height: '80px'
    },
    refundItemBlock: {
        marginTop: '12px',
        marginLeft: '20px',
    },
    itemList: {
        padding: '10px 0px',
        marginTop: '12px',
        marginLeft: '20px',
        marginRight: '20px',
        textAlign: 'center',
    },
    footer: {
        // position: 'absolute',
        // bottom: '20px',
        width: '100%',
        textAlign: 'center',
    },
    inlineText: {
        display: 'inline-block',
        color: RETURN_FORM_COLOR_PRIMARY
    },
    footerTextSpan: {
        color: RETURN_FORM_COLOR_GREY
    },
    buttonBlock: {
        position: 'relative',
        margin: '24px'
    },
    uploadButtonIcon: {
        marginRight: '10px'
    },
    input: {
        display: 'none',
    },
    progress: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -12,
        marginLeft: -12,
    }
});


class ClaimProcess extends React.Component {
    state = {
        imgSrc: null,
        file: null,
        showProgress: false,
        interval: null,
        dps: [],
        targeDps: null
    }
    componentDidMount() {
        // TODO: if ninja van fire a api to get logistic data
        if (this.props.order.data.claim.payway.type === 'ninjavan') {
            this.props.getLogisticByClaim(this.props.order.data.claim.id)
            fetch(`${API_URL}/v1/public/ninjavan/sg/pudos`)
                .then(r => r.json())
                .then(r => {
                    const dataJson = JSON.parse(r.data)
                    this.setState({ dps: dataJson.dps })
                })
        }

    }
    componentWillReceiveProps(nextProps) {
        // if(nextProps.claim.parcel.shipping.way_bill_url )
        if (this.props.order.data.claim.payway.type === 'ninjavan') {
            const thisClaim = this.props.claim
            const nextClaim = nextProps.claim
            if ((!thisClaim.parcel && nextClaim.parcel) || (nextClaim.parcel.id !== thisClaim.parcel.id) || (nextClaim.parcel.shipping.way_bill_url !== thisClaim.parcel.shipping.way_bill_url)) {
                if (nextProps.claim.parcel.shipping.way_bill_url === '' && !this.state.interval) {
                    const { getLogisticByClaim } = this.props
                    const f = function (id) {
                        return function () {
                            getLogisticByClaim(id)
                        }
                    }(this.props.order.data.claim.id)
                    const interval = setInterval(f, 10000);
                    this.setState({ interval })
                } else {
                    const interval = this.state.interval
                    if (!!interval) {
                        this.setState({ interval: null }, () => {
                            clearInterval(interval)
                        })
                    }
                }
            }
        }
    }
    handleImageChange = (e) => {
        // Assuming only image
        e.preventDefault();

        let reader = new FileReader();
        let file = e.target.files[0];

        reader.onloadend = () => {
            this.setState({
                file: file,
                imgSrc: reader.result
            }, () => {
                this.updateAttachment();
            });
        }

        reader.readAsDataURL(file)
    }
    updateAttachment = () => {
        const claim = this.props.order.data.claim
        const { payway } = claim;
        const data = {
            payway,
            image: { file: this.state.file, imgSrc: this.state.imgSrc }
        }
        this.props.updateRefundMethod(this.props.order.data.claim.id, data)
        this.setState({ showProgress: true })
    }
    genShowOnlyLast4digitBankAccount(s) {
        if (s.length > 4) {
            const last4 = s.slice(-4)
            return last4.padStart(s.length, '*')
        }
        return s
    }
    onClickPDF = (url) => () => {
        window.open(url, '_blank');
    }
    onMarkerClick = index => () => {
        this.setState({
            targeDps: this.state.dps[index]
        })
    }
    genAccountInfo(claim, classes, isFinished) {
        const { payway } = claim;

        if (payway.type === 'bankTransfer') {
            return (
                <React.Fragment>
                    <Typography variant="display1" className={classes.grayTitleSpan}>Refund to</Typography>
                    {/* TODO: Ask Charles but not yet get feedback */}
                    <Typography variant="display1" >{payway.displayName}</Typography>
                    <Typography variant="display1" >{this.genShowOnlyLast4digitBankAccount(payway.bankAccount)}</Typography>
                </React.Fragment>
            )
        }
        if (payway.type === 'paypal') {
            return (
                <React.Fragment>
                    <Typography variant="display1" className={classes.grayTitleSpan}>Refund to</Typography>
                    <Typography variant="display1" >Paypal account</Typography>
                    <Typography variant="display1" >{payway.paypalAccount}</Typography>
                </React.Fragment>
            )
        }
        if (payway.type === 'ninjavan') {
            const { claim: { parcel } } = this.props;
            const noPickUp = parcel && !parcel.is_pickup
            let way_bill_url = ""
            if (!!parcel && parcel.shipping.way_bill_url) {
                way_bill_url = parcel.shipping.way_bill_url
            }
            if (isFinished) {
                return (
                    <React.Fragment>
                        <Typography variant="display1" >Ninja van</Typography>
                    </React.Fragment>
                )
            }
            return (
                <React.Fragment>
                    <Typography variant="display1" >Ninja van</Typography>
                    {!way_bill_url ?
                        <Typography variant="display1" >wait for creating shipping label</Typography>
                        : <Typography variant="display1" >To begin the return process, you will need to paste this label on your parcel. Click here to view and print your product’s shipping label.</Typography>
                    }
                    <div style={{ position: 'relative' }}>

                        <AdornedButton
                            style={{ maxWidth: 200, marginTop: 20, marginBottom: 10 }}
                            loading={!way_bill_url}
                            disabled={!way_bill_url}
                            variant='extendedFab'
                            color='primary'
                            fullWidth
                            onClick={this.onClickPDF(way_bill_url)}
                        >
                            Print
                        </AdornedButton>
                        {noPickUp && <Typography variant="display2" >You can drop off your parcels at the following places</Typography>}
                    </div>
                    {
                        this.state.targeDps && (
                            <div style={{ padding: 5, margin: 10 }}>
                                <Typography variant="display1" >{this.state.targeDps.type}</Typography>
                                <Typography variant="display1" >{this.state.targeDps.name}</Typography>
                                <Typography variant="display1" >{this.state.targeDps.address_1}</Typography>
                                <Typography variant="display1" >{this.state.targeDps.address_2}</Typography>
                            </div>
                        )
                    }
                    {noPickUp && (
                        <div style={{ padding: 5, margin: 10 }}>
                            <MyMapComponent isMarkerShown markerList={this.state.dps} onMarkerClick={this.onMarkerClick} />
                        </div>
                    )
                    }

                </React.Fragment>
            )
        }
        return null
    }
    render() {
        const { classes, order, isReject, isFinished } = this.props;
        const { data } = order
        const { claim } = data
        const returnInfoComponent = this.genAccountInfo(claim, classes, isFinished)
        return (
            <React.Fragment>
                <main className={classes.main}>
                    <Grid
                        container
                        direction="column"
                        justify="space-between"
                        alignItems="stretch">
                        <Grid item className={classes.titleBlock}>
                            <div className={classes.titleSpanBlock}>
                                <Typography variant="title" className={classes.titleSpan}>Return ID: {data.claim.id}</Typography>
                            </div>
                        </Grid>

                        <Grid item className={classes.contentBlock}>
                            <div className={classes.itemList}>
                                {returnInfoComponent}
                            </div>
                            {isReject ?
                                (
                                    <div className={classes.buttonBlock}>
                                        <React.Fragment>
                                            <input
                                                accept="image/*;capture=camera"
                                                className={classes.input}
                                                id="outlined-button-file"
                                                multiple
                                                type="file"
                                                onChange={this.handleImageChange}
                                            />
                                            <label htmlFor="outlined-button-file">
                                                <Button fullWidth variant="extendedFab" color="primary" component="span" className={classes.uploadButton} disabled={this.state.showProgress}>
                                                    Upload
                                            </Button>
                                                {this.state.showProgress ? <CircularProgress className={classes.progress} size={24} /> : null}
                                            </label>
                                        </React.Fragment >
                                    </div>
                                )
                                : null}

                        </Grid>
                        <Grid item className={classes.footer}>
                            <Typography variant="display2" className={classes.footerTextSpan}>
                                If you have any question,
                            <Typography variant="display2" className={classes.footerTextSpan} component="span">
                                    please contact
                                {' '}
                                    <a href={'mailto:support@axinan.com'}>
                                        <Typography variant="display2" className={classes.inlineText} component="span">
                                            support@axinan.com
                                    </Typography>
                                    </a>
                                </Typography>
                            </Typography>

                        </Grid>
                    </Grid>
                </main>
            </React.Fragment>
        );
    }
}

const mapDispatchToProps = dispatch => bindActionCreators({
    updateRefundMethod,
    getLogisticByClaim
}, dispatch)

const mapStateToProps = ({ lazada: { order, claim } }) => ({
    order,
    claim
})

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(styles)(ClaimProcess))