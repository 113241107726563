import Cookies from 'js-cookie';
import log from 'utils/debug'
export function parseJWT(token) {
    if (token) {
        var base64Url = token.split('.')[1];
        var base64 = base64Url.replace('-', '+').replace('_', '/');
        return JSON.parse(window.atob(base64));
    }
    return null
};

export function getFreeReturnIdFromJWT(tokenName) {
    const accessToken = Cookies.get(tokenName)
    const configData = parseJWT(accessToken)
    if (configData) {
        return configData.app_data.freereturn
    }
    return ''
}
export function getShopFromJWT(tokenName) {
    log("tokenname:", tokenName)
    const accessToken = Cookies.get(tokenName)
    log("token:", accessToken)

    const configData = parseJWT(accessToken)
    log(configData)
    if (configData) {
        return configData.app_data.shop
    }
    return ''
}

export function getScopeFromJWT(tokenName) {
    const accessToken = Cookies.get(tokenName)
    const configData = parseJWT(accessToken)
    log('config data:', configData)
    if (configData) {
        return configData.app_data.scopes.split(",").map(function (item) {
            return item.trim();
        });
    }
    return []
}
export function checkSetupStatusFromJWT(tokenName) {
    const accessToken = Cookies.get(tokenName)
    if (accessToken) {
        const configData = parseJWT(accessToken)
        if (configData && configData.app_data) {
            const app_data = configData.app_data
            return app_data.isSetupTheme === 'true' || app_data.isSetupTheme || false
        }
    }
    return false
}
export function genGeneralAddress(a) {
    if (!a) {
        return ""
    }
    let address = a.address || a.address1 + a.address2 || ""
    let zip = a.zip || a.zip_code || ""
    let city = a.City || a.city || ""
    let country = a.Country || a.country || ""
    address += !!zip ? ", " + zip : ""
    address += !!city ? ", " + city : ""
    address += !!country ? ", " + country : ""
    return address
}

export function sendMail(address) {
    window.top.location.href = 'mailto:' + address;
}
