import React from 'react';
import { Switch, Route } from 'react-router-dom'

import AuthorizedRoute from './AuthorizedRoute'
import Login from '../Login'
import HeaderLayout from './HeaderLayout'
import ItemConfirm from '../ItemConfirm'
import ReturnMethod from '../ReturnMethod'
import BankTransferForm from '../BankTransferForm'
import PaypalForm from '../PaypalForm'
import DefaultLayout from './DefaultLayout'
import ReturnForm from '../ReturnForm'
import NinJa from '../ReturnForm/ninja'
import ClaimList from '../CliamList'
import StatusPage from '../StatusPage'


const RequireAuth = () => (
    <Switch>
        <HeaderLayout headerTitle={'Paypal'} path={`/freereturn/return/confirm/paypal`} component={PaypalForm} />
        <HeaderLayout headerTitle={'Bank transfer'} path={`/freereturn/return/confirm/bank_transfer`} component={BankTransferForm} />
        <HeaderLayout headerTitle={'Select return method'} path={`/freereturn/return/confirm/return_method`} component={ReturnMethod} />
        <HeaderLayout headerTitle={'NinJa van service'} path={`/freereturn/return/confirm/ninja`} component={NinJa} />
        <HeaderLayout headerTitle={'Self-return service'} path={`/freereturn/return/confirm/return_form`} component={ReturnForm} />
        <DefaultLayout path={`/freereturn/return/confirm`} component={ItemConfirm} />
        <HeaderLayout hideBack showContact headerTitle={'Return Status'} strict path={`/freereturn/return/status`} component={StatusPage} />
        <HeaderLayout hideBack showContact headerTitle={'Choose return items'} strict path={`/freereturn/return/claim`} component={ClaimList} />
    </Switch>
)

const App = () => {
    return (
        <Switch>
            <HeaderLayout exact headerTitle={'Self-return service'} path={`/freereturn/return/return_form`} component={ReturnForm} />
            <Route exact path={`/freereturn/return/login/`} component={Login} />
            <Route exact path={`/freereturn/return/login/:shop`} component={Login} />
            <AuthorizedRoute strict path={`/freereturn/return`} component={RequireAuth} />
        </Switch>
    )
}

export default App;