import React from 'react';
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'

import withStyles from '@material-ui/core/styles/withStyles';
import { RETURN_FORM_COLOR_PRIMARY, RETURN_FORM_COLOR_GREY, RETURN_FORM_COLOR_SECONDARY } from '../../constants';
import {
    Typography, Button,
} from '@material-ui/core'
import ReturnItem from '../../components/ReturnItem';

const styles = theme => ({
    appBar: {
        position: 'relative',
    },
    layout: {
        width: 'auto',
        marginLeft: theme.spacing.unit * 2,
        marginRight: theme.spacing.unit * 2,
        [theme.breakpoints.up(600 + theme.spacing.unit * 2 * 2)]: {
            width: 600,
            marginLeft: 'auto',
            marginRight: 'auto',
        },
    },
    paper: {
        marginTop: theme.spacing.unit * 3,
        marginBottom: theme.spacing.unit * 3,
        padding: theme.spacing.unit * 2,
        [theme.breakpoints.up(600 + theme.spacing.unit * 3 * 2)]: {
            marginTop: theme.spacing.unit * 6,
            marginBottom: theme.spacing.unit * 6,
            padding: theme.spacing.unit * 3,
        },
    },
    stepper: {
        padding: `${theme.spacing.unit * 3}px 0 ${theme.spacing.unit * 5}px`,
    },
    buttons: {
        display: 'flex',
        justifyContent: 'flex-end',
    },
    button: {
        marginTop: theme.spacing.unit * 3,
        marginLeft: theme.spacing.unit,
    },
    formControl: {
        margin: theme.spacing.unit,
        minWidth: 120,
    },
    main: {
        height: '100%',
        display: 'flex',
        flexDirection: 'column'
    },
    titleBlock: {
        paddingTop: '24px',
    },
    titleSpanBlock: {
    },
    titleSpan: {
        color: RETURN_FORM_COLOR_PRIMARY,
        textAlign: 'center'
    },
    grayTitleSpan: {
        color: RETURN_FORM_COLOR_GREY,
        marginBottom: '6px'
        // lineHeight: 2.2
    },
    textSpan: {
        lineHeight: 1.4
    },
    contentBlock: {
        flexGrow: 1,
        overflowY: 'auto'
    },
    testBlock: {
        backgroundColor: 'red',
        height: '80px'
    },
    refundItemBlock: {
        marginTop: '12px',
        marginLeft: '20px',
    },
    itemList: {
        marginTop: '12px',
        marginLeft: '20px',
        marginRight: '20px',
    },
    errorSpan: {
        color: RETURN_FORM_COLOR_SECONDARY,
        textAlign: 'center'
    },
    reasonSpan: {
        color: RETURN_FORM_COLOR_PRIMARY,
        textAlign: 'center'
    }
});


class WaitingApproval extends React.Component {
    componentDidMount() {
    }
    handleRetryButton = () => {
        this.props.history.push("/freereturn/return/claim");
    }
    handleRetryButton = () => {
        this.props.history.push("/freereturn/return/claim");
    }
    render() {
        const { classes, order, isReject } = this.props;
        const { data } = order
        const { claim } = data
        return (
            <React.Fragment>
                <main className={classes.main}>
                    <div className={classes.titleBlock}>
                        <div className={classes.titleSpanBlock}>
                            <Typography variant="title" className={classes.titleSpan}>Order ID: {data.order.order_number}</Typography>
                        </div>
                    </div>
                    {isReject ? /* TODO: Retry UI pending */
                        (
                            <div style={{ padding: 24, margin: '30px 0px' }}>
                                <div style={{ marginBottom: '30px' }}>
                                    <Typography variant="title" className={classes.errorSpan}>Your request has been rejected.</Typography>
                                </div>
                                <div style={{ marginBottom: '5px' }}>
                                    <Typography variant="title" className={classes.titleSpan}>Reject reason:</Typography>
                                </div>
                                <div style={{ marginBottom: '40px' }}>
                                    <Typography variant="title" className={classes.reasonSpan}>{claim.reject}</Typography>
                                </div>
                                <Button fullWidth onClick={this.handleRetryButton} variant="extendedFab" color="secondary" style={{ color: 'white' }}>
                                    RETRY
                                </Button>
                            </div>
                        )
                        : null}
                    <div className={classes.contentBlock}>
                        <div className={classes.itemList}>
                            {claim.items ?
                                claim.items.map((item, index) => {
                                    const isEnd = index + 1 === data.items.length
                                    return (<ReturnItem key={item.name} item={item} isEnd={isEnd} />)
                                }) : null}
                        </div>
                    </div>


                </main>
            </React.Fragment>
        );
    }
}

const mapDispatchToProps = dispatch => bindActionCreators({
}, dispatch)

const mapStateToProps = ({ returnSystem: { order } }) => ({
    order
})

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(styles)(withRouter(WaitingApproval)))