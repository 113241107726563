import { GET_CLAIM_BY_ID_DONE, UPDATE_CLAIM_DONE } from '../../constants';
import axFetch from 'utils/fetch'
import { DefaultHeader } from 'utils/apiUtils'
import WithAPIStatus from '../withAPIStatus'

export function getClaim(id) {
    const fetchOptions = {
        method: 'GET',
        headers: {
            ...DefaultHeader
        },
        credentials: 'include',
    }
    return dispatch => {
        return WithAPIStatus(dispatch)(
            axFetch(`/v1/insurances/freereturn/claims/${id}`, fetchOptions)
                .then(json => dispatch(handleGetClaimByIdDoneAction(json)))
        )
    };
}

function handleGetClaimByIdDoneAction(json) {
    return {
        type: GET_CLAIM_BY_ID_DONE,
        payload: {
            data: json
        },
    };
}

export function updateClaim(action, id, reason) {
    const fetchOptions = {
        method: 'POST',
        headers: {
            ...DefaultHeader
        },
        credentials: 'include',
    }
    if (reason) {
        fetchOptions['body'] = JSON.stringify({ reason })
    }
    return dispatch => {
        return WithAPIStatus(dispatch)(
            axFetch(`/v1/claims/${id}:${action}`, fetchOptions)
                .then(json => dispatch(handleUpdateClaimDoneAction(json)))
        );
    };
}

function handleUpdateClaimDoneAction(json) {
    return {
        type: UPDATE_CLAIM_DONE,
        payload: {
            data: json
        }
    }
}