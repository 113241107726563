import React from 'react'
import classNames from 'classnames'
import { Typography, Grid } from '@material-ui/core';
import { RETURN_FORM_COLOR_GREY } from '../../constants';
import withStyles from '@material-ui/core/styles/withStyles';

const styles = theme => ({
    nameTitle: {
        lineHeight: 1.7
    },
    subTitle: {
        color: RETURN_FORM_COLOR_GREY,
        lineHeight: 1.5,
    },
    image: {
        width: '100%'
    },
    container: {
        borderBottom: '1px solid #dddddd',
        paddingTop: '12px',
        paddingBottom: '12px',
    },
    containerNoBorderBottom: {
        borderBottom: 'none',
    },
    numberSpan: {
        textAlign: 'right'
    }
})
class ReturnItem extends React.PureComponent {
    genOptions = (options) => {
        if (!options) {
            return []
        }
        return options.map(o => {
            const json = JSON.parse(o)
            return json["Name"]
        })
    }
    render() {
        const { item, classes, isEnd, itemDetail } = this.props;
        const imageJson = item.product_images && item.product_images.length > 0 ? JSON.parse(item.product_images[0]) : { Src: "" }
        const variantTitle = item.variant_title.split("/")
        const options = this.genOptions(item.options)
        const variantTitleArray = variantTitle.map((v, i) => {
            return !!options[i] ? options[i] + ": " + v : v
        })
        if (itemDetail) {
            variantTitleArray.push("Price: " + itemDetail.price.toFixed(2))
        }
        const containerClass = isEnd ? classNames([classes.container, classes.containerNoBorderBottom]) : classNames([classes.container])
        return (
            <Grid container className={containerClass}>
                <Grid item xs={2} sm={2}>
                    {imageJson &&
                        <img src={imageJson.Src} alt="refund item" className={classes.image} />
                    }
                </Grid>
                <Grid item xs={8} sm={8}>
                    <div style={{ marginLeft: '15px' }}>
                        <Typography className={classes.nameTitle} variant="display2">{item.title}</Typography>
                        <Typography className={classes.subTitle} variant="display1">{variantTitleArray.join(" | ")}</Typography>
                    </div>
                </Grid>
                <Grid item xs={2} sm={2}>
                    <Typography variant="display2" className={classes.numberSpan}>{'X'}{item.quantity}</Typography>
                </Grid>

            </Grid>
        )
    }
}

export default withStyles(styles)(ReturnItem);