const prefix = 'IVENTORY_CASH_BACK_SHOP_SYSTEM_'

// Basic Constants
export const DATE_FORMAT_DEFAULT = "DD MMM YYYY"
export const DEFAULT_PRIMARY_COLOR = '#4d53c4'

// Redux Type
export const API_START = prefix + "API_START"
export const API_DONE = prefix + "API_DONE"
export const API_RESET_STATUS = prefix + "API_RESET_STATUS"
export const API_ERROR = prefix + "API_ERROR"
export const LOGIN_ACTION_DONE = prefix + 'LOGIN_ACTION_DONE'

