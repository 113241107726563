import React, { Component } from 'react';
import { Button } from '@shopify/polaris';
import { withRouter } from 'react-router-dom'

function TabButton({ text, onClick, selected, index }) {
    return (
        <div style={{ margin: 5, display: 'inline-block', width: 150 }}>
            <Button size="slim" onClick={onClick} primary={selected === index} fullWidth>{text}</Button>
        </div>
    )
}

function handleButtonClick(history, path) {
    return () => {
        history.push(path)
    }
}

function TabLayout({ selected, children, history }) {
    return (
        <div style={{ backgroundColor: 'white', height: '100%' }}>
            <div style={{ textAlign: 'right', padding: 10, backgroundColor: '#eeeeee' }}>
                <TabButton text={"Products"} index={1} selected={selected} onClick={handleButtonClick(history, "/inventorycashback/products")} />
                <TabButton text={"Policies"} index={2} selected={selected} onClick={handleButtonClick(history, "/inventorycashback/policies")} />
                <TabButton text={"Claims"} index={3} selected={selected} onClick={handleButtonClick(history, "/inventorycashback/claims")} />
                <TabButton text={"Support"} index={4} selected={selected} onClick={handleButtonClick(history, "/inventorycashback/faq")} />
                <TabButton text={"Tutorial"} index={5} selected={selected} onClick={handleButtonClick(history, "/inventorycashback/tutorial")} />
            </div>
            {children}
        </div>
    )
}
const TabLayoutWrapped = withRouter(TabLayout)

export default (selected) => {
    return (WrappedComponent) => {
        return (props) => {
            return (
                <TabLayoutWrapped selected={selected}>
                    <WrappedComponent {...props} />
                </TabLayoutWrapped>
            )
        }
    }
};
