import axFetch from 'utils/fetch'
import { DefaultHeader } from 'utils/apiUtils'

const policyModel = {
    "cover_period": 0,
    "goods": {
        "amount": "string",
        "currency": "string",
        "price": "string"
    },
    "id": "string",
    "payout": {
        "amount": 0,
        "currency": "string"
    },
    "protect_end": "2019-01-08T06:27:36.284Z",
    "protect_from": "2019-01-08T06:27:36.285Z",
    "status": "string"
}
const policiesModel = {
    "policy": [
        policyModel
    ]
}

function _uuid() {
    var d = Date.now();
    if (typeof performance !== 'undefined' && typeof performance.now === 'function') {
        d += performance.now(); //use high-precision timer if available
    }
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
        var r = (d + Math.random() * 16) % 16 | 0;
        d = Math.floor(d / 16);
        return (c === 'x' ? r : (r & 0x3 | 0x8)).toString(16);
    });
}

function genMockData(number) {
    const policies = []
    for (let i = 0; i < number; i++) {
        const policy = Object.assign({}, policyModel, { id: _uuid() })
        policies.push(policy)
    }
    return Object.assign({}, policiesModel, { policy: policies })
}


export function getPolicy() {
    const fetchOptions = {
        method: 'GET',
        headers: {
            ...DefaultHeader
        },
        credentials: 'include',
    }
    return axFetch(`/v1/inventorycashback/policy`, fetchOptions)
    // return genMockData(5)
}

export function toggleAutoExtend(policyId) {
    const fetchOptions = {
        method: 'PUT',
        headers: {
            ...DefaultHeader
        },
        credentials: 'include',
        body: JSON.stringify({ status: "toggleAutoExtend" })
    }
    return axFetch(`/v1/inventorycashback/policies/${policyId}`, fetchOptions)
}

export function createPolicy(params) {
    const fetchOptions = {
        method: 'POST',
        headers: {
            ...DefaultHeader
        },
        credentials: 'include',
    }
    return axFetch(`/v1/inventorycashback/policy`, fetchOptions, params)

}