import {
    GET_PRODUCTS_DONE, ACTIVE_PRODUCT_INSURANCE, UPLOAD_FILE_DONE
} from '../../constants';
import axFetch from 'utils/fetch'
import { DefaultHeader } from 'utils/apiUtils'
import WithAPIStatus from '../withAPIStatus'
import { API_URL } from 'utils/apiUtils'


export function getProducts() {
    const fetchOptions = {
        method: 'GET',
        headers: {
            ...DefaultHeader
        },
        credentials: 'include',
    }
    return dispatch => {
        return WithAPIStatus(dispatch)(
            axFetch('/v1/insurances/freereturn/products', fetchOptions)
                .then(json => dispatch(getProductsCompoleteAction(json)))
        )
    };
}
function getProductsCompoleteAction(json) {
    return {
        type: GET_PRODUCTS_DONE,
        payload: {
            json
        },
    };
}


export function updateActivation(action, products) {
    const params = {}
    params[action] = products
    const fetchOptions = {
        method: 'POST',
        headers: {
            ...DefaultHeader
        },
        credentials: 'include',
        body: JSON.stringify(params)
    }
    return dispatch => {
        return WithAPIStatus(dispatch)(
            axFetch('/v1/insurances/freereturn/subscriptions:update', fetchOptions)
                .then(json => dispatch(updateActivationComplete(params)))
        )
    };

}

function updateActivationComplete(params) {
    return {
        type: ACTIVE_PRODUCT_INSURANCE,
        payload: {
            data: {
                ...params
            }
        }
    }
}

function download(filename, text) {
    var pom = document.createElement('a');
    pom.setAttribute('href', 'data:text/csv;charset=utf-8,' + encodeURIComponent(text));
    pom.setAttribute('target', "_blank")
    pom.setAttribute('download', filename);

    if (document.createEvent) {
        var event = document.createEvent('MouseEvents');
        event.initEvent('click', true, true);
        pom.dispatchEvent(event);
    }
    else {
        pom.click();
    }
}

export function exportCsv() {
    const fetchOptions = {
        method: 'GET',
        headers: {
            ...DefaultHeader
        },
        Accept: "text/csv",
        credentials: 'include',
    }
    const _url = new URL(API_URL + '/v1/insurances/freereturn/products/export');

    return dispatch => {
        return WithAPIStatus(dispatch)(
            fetch(_url.href, fetchOptions)
                .then((response) => response.text())
                .then((responseText) => download(`product.csv`, responseText))
        )
    };
}

export function importCsv(file) {
    let formData = new FormData();
    formData.append('file', file.file)
    const fetchOptions = {
        method: 'POST',
        credentials: 'include',
        body: formData
    }
    return dispatch => {
        return WithAPIStatus(dispatch)(
            axFetch('/v1/insurances/freereturn/products/import', fetchOptions)
                .then(json => dispatch(finishUpload(json)))
        )
    };
}


function finishUpload(params) {
    return {
        type: UPLOAD_FILE_DONE,
        payload: {
            data: {
                ...params
            }
        }
    }
}
