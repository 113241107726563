import { LOGIN_ACTION_DONE } from '../constants'

const initialState = {
    logged: false,
};

function app(state = initialState, action) {
    switch (action.type) {
        case LOGIN_ACTION_DONE:
            return {
                ...state,
                logged: true,
                ...action.payload
            };
        default:
            return state
    }
}

export default app