import React from 'react';
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { Redirect } from 'react-router-dom'
import _ from 'lodash'

import withStyles from '@material-ui/core/styles/withStyles';
import { OTHER_REASON } from '../../constants';
import {
    Typography, Grid, Button,
    TextField,
    CircularProgress
} from '@material-ui/core'
import { updateRefundMethod } from '../../actions/claim'
import log from 'utils/debug'

const styles = theme => ({
    main: {
        flex: '1 1',
        display: 'flex',
        flexDirection: 'column'
    },
    content: {
        flexGrow: 1,
        overflowY: 'auto'
    },
    container: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    textField: {
        marginLeft: theme.spacing.unit,
        marginRight: theme.spacing.unit,
        paddingTop: theme.spacing.unit * 2,
    },
    uploadTitle: {
        display: 'inline-block',
        verticalAlign: 'middle'
    },
    uploadButton: {
        height: '80px',
        backgroundColor: 'rgb(244, 243, 251)'
    },
    uploadButtonIcon: {
        marginRight: '10px'
    },
    buttonProgress: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -12,
        marginLeft: -12,
    },
});


class PaypalForm extends React.Component {
    state = {
        imgSrc: null,
        file: null,
        submitAvailable: false,
        email: '',
        emailConfirm: '',
        buttonLoading: false,
        emailError: false,
        emailConfirmError: false,
        emailErrorText: '',
        emailConfirmErrorText: '',
    }
    handleClickShowPassword = () => {

    }
    handleTextChange = event => {
        this.setState({
            [event.target.name]: event.target.value,
            emailError: false,
            emailConfirmError: false,
            emailErrorText: '',
            emailConfirmErrorText: '',
        });
    };
    handleTextOnBlur = keyName => event => {
        const value = event.target.value;
        if (value !== '' && !_.includes(value, '@')) {
            this.setState({
                [keyName]: true,
                [keyName + 'Text']: 'Invalid email address'
            });
        }
        log(event.target.value)
    }
    handleRemovePhoto = () => {
        this.setState({
            file: null,
            imgSrc: null
        });
    }
    handleImageChange = (e) => {
        // Assuming only image
        e.preventDefault();

        let reader = new FileReader();
        let file = e.target.files[0];

        reader.onloadend = () => {
            this.setState({
                file: file,
                imgSrc: reader.result
            });
        }

        reader.readAsDataURL(file)
    }
    decideSubmitAvailable = () => {
        const state = this.state;
        if (state.email === '' || state.emailConfirm === '') {
            return true
        }
        return state.buttonLoading
    }
    handleSubmit = () => {
        if (this.state.email === this.state.emailConfirm && !this.state.emailError && !this.state.emailConfirmError) {
            const { claim } = this.props
            const dimensions = {
                weight: 0.0,
                volume: 0.0,
                length: 0.0,
                width: 0.0,
                height: 0.0,
                size: "small",
            }
            const timeslot = {
                start_time: "00:00",
                end_time: "00:00",
            }
            const { file, imgSrc, courier, otherCourier, refundCurrency, deliverFee, trackingId } = this.props.tempStatus.returnForm
            const logisticName = courier === OTHER_REASON ? otherCourier : courier
            const data = {
                payway: {
                    type: "paypal",
                    paypalAccount: this.state.email,
                    currency: refundCurrency,
                    deliverFee,
                },
                image: { file, imgSrc },
                parcel: {
                    tracking_id: trackingId,
                    return_logistic_name: logisticName,
                    dimensions,
                    timeslot,
                    sender: {
                        ...claim.senderInfo,
                        address1: claim.senderInfo.address,
                        postcode: claim.senderInfo.zip,
                    },
                    receiver: {
                        ...claim.receiverInfo,
                        address1: claim.receiverInfo.address,
                        postcode: claim.receiverInfo.zip,
                    }
                }
            }
            this.setState({ buttonLoading: true })
            this.props.updateRefundMethod(this.props.order.data.claim.id, data)
        } else {
            this.setState({ emailError: true, emailConfirmError: true, emailConfirmErrorText: 'Oops, does not match' })
        }
    }
    render() {
        const { classes, order } = this.props;
        const submiteAvailable = this.decideSubmitAvailable();
        if (order.afterClaimSubmitted) {
            return <Redirect to='/lazada/return/status' />
        }
        return (
            <React.Fragment>
                <div className={classes.main}>
                    <div className={classes.content}>
                        <form className={classes.container} noValidate autoComplete="off">
                            <Grid container spacing={8} className={classes.textField}>
                                <Grid item xs={12} sm={12}>
                                    <Typography variant="display2" color="primary">
                                        Enter your paypal account
                                    </Typography>
                                    <TextField
                                        id="outlined-email-address-input"
                                        placeholder="Email address"
                                        name="email"
                                        margin="dense"
                                        variant="outlined"
                                        onChange={this.handleTextChange}
                                        onBlur={this.handleTextOnBlur('emailError')}
                                        value={this.state.email}
                                        error={this.state.emailError}
                                        helperText={this.state.emailErrorText}
                                        fullWidth
                                    />
                                </Grid>
                                <Grid item xs={12} sm={12}>
                                    <Typography variant="display2" color="primary">
                                        Confirm your paypal account
                                    </Typography>
                                    <TextField
                                        id="outlined-email-address-confirm-input"
                                        placeholder="Email address"
                                        name="emailConfirm"
                                        margin="dense"
                                        variant="outlined"
                                        onChange={this.handleTextChange}
                                        onBlur={this.handleTextOnBlur('emailConfirmError')}
                                        value={this.state.emailConfirm}
                                        error={this.state.emailConfirmError}
                                        helperText={this.state.emailConfirmErrorText}
                                        fullWidth
                                    />
                                </Grid>
                            </Grid>

                        </form>
                    </div>
                    <div style={{ padding: 12, boxShadow: '0px -3px 10px #dddddd', zIndex: 10 }}>
                        <Button fullWidth variant="extendedFab" color="primary" disabled={submiteAvailable} onClick={this.handleSubmit}>Submit</Button>
                        {this.state.buttonLoading && <CircularProgress size={24} className={classes.buttonProgress} />}
                    </div>
                </div>
            </React.Fragment >
        );
    }
}

const mapStateToProps = ({ lazadaApp: { order, tempStatus, claim } }) => ({
    order,
    tempStatus,
    claim
})
const mapDispatchToProps = dispatch => bindActionCreators({
    updateRefundMethod
}, dispatch)

export default connect(
    mapStateToProps, mapDispatchToProps
)(withStyles(styles)(PaypalForm))
