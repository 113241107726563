import React from 'react'
import { Dialog, Button, Paper } from '@material-ui/core';
import withStyles from '@material-ui/core/styles/withStyles';
import CloseIcon from '@material-ui/icons/Close'
const styles = theme => ({
    dialog: {

    },
    container: {
        padding: 20
    },
    button: {
        margin: '16px auto',
        backgroundColor: 'transparent',
        boxShadow: 'none',
        border: '1px solid',
        color: 'white',
    },
    paperScrollPaper: {
        backgroundColor: 'transparent',
        boxShadow: 'none'
    }

})

class SimpleDialog extends React.Component {
    handleClose = () => {
        this.props.onClose(this.props.selectedValue);
    };

    render() {
        const { classes, onClose, selectedValue, ...other } = this.props;

        return (
            <Dialog classes={{ paperScrollPaper: classes.paperScrollPaper }} onClose={this.handleClose}
                aria-labelledby="simple-dialog-title"
                {...other}>
                <Paper className={classes.container}>
                    {this.props.children}
                </Paper>
                <Button variant="fab" mini aria-label="Add" className={classes.button} onClick={this.handleClose}>
                    <CloseIcon />
                </Button>
            </Dialog>
        );
    }
}

export default withStyles(styles)(SimpleDialog);