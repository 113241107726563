import React from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import reactReferer from 'react-referer';

import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import Typography from '@material-ui/core/Typography';
import withStyles from '@material-ui/core/styles/withStyles';
import { CircularProgress, TextField } from '@material-ui/core'
import InputAdornment from '@material-ui/core/InputAdornment';
import StoreIcon from '@material-ui/icons/Store';

import { RETURN_FORM_COLOR_ERROR, API_ERROR, API_START } from '../../constants'
import { resetApiStatus } from '../../actions'
import { logginAction } from '../../actions/order'
import EmailorPhoneInput from '../../components/EmailorPhoneInputComponent'
import log from 'utils/debug'

const styles = theme => ({
    layout: {
        width: 'auto',
        display: 'block', // Fix IE11 issue.
        marginLeft: theme.spacing.unit * 3,
        marginRight: theme.spacing.unit * 3,
        [theme.breakpoints.up(400 + theme.spacing.unit * 3 * 2)]: {
            width: 400,
            marginLeft: 'auto',
            marginRight: 'auto',
        },
    },
    paper: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        paddingTop: `${theme.spacing.unit * 9 + 4}px`,
    },
    titleDiv: {
        marginTop: theme.spacing.unit * 2,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: `${theme.spacing.unit * 2}px ${theme.spacing.unit * 3}px ${theme.spacing.unit * 3}px`,
    },
    avatar: {
        margin: theme.spacing.unit,
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        width: '100%', // Fix IE11 issue.
        marginTop: theme.spacing.unit * 3,
    },
    formshop: {
        color: "#4f4fcb",
    },
    submit: {
        marginTop: theme.spacing.unit * 6,
    },
    progressMain: {
        height: '100%',
        width: 'auto',
        [theme.breakpoints.up(600 + theme.spacing.unit * 2 * 2)]: {
            width: 600,
            marginLeft: 'auto',
            marginRight: 'auto',
        },
        backgroundColor: 'white',
    },
    progress: {
    },
    progressBlock: {
        paddingTop: 100,
        margin: '0 auto',
        width: '80px'
    },
    error: {
        color: RETURN_FORM_COLOR_ERROR
    },
});




class LoginPage extends React.Component {
    state = {
        orderId: '',
        user: '',
        shop: '',
        staticShop: '', // staticshop get shop name from either path param (i.e return/login/axinan-tw-test-djskfslj.myshopify.com or referrer)
        errorMsg: '',
    };
    handleLoginButton = () => {
        const { orderId, user, shop, staticShop } = this.state
        if (orderId !== '' && user !== '') {
            const { logginAction } = this.props;
            logginAction({ orderId, user, shop: 'returnyo-demo-store.myshopify.com' });
        }
    }
    componentDidMount() {
        log('document.referrer:', reactReferer.referer())
        const referrer = reactReferer.referer()

        const { match } = this.props;
        const { shop } = match.params
        if (shop) {
            this.setState({ staticShop: shop })
        } else if (referrer) {
            var regex = /[a-zA-Z0-9-]+.myshopify.com/
            const got = regex.exec(referrer)
            if (got) {
                log('parsed referrer:', got)
                this.setState({ staticShop: got[0] })
            }
        }
    }
    componentWillReceiveProps(nextProps) {
        if (nextProps.loaded) {
            log('loaded')
            if (nextProps.claimed) {
                this.props.history.push('/lazada/return/status')
            } else {
                this.props.history.push('/lazada/return/claim')
            }
        }
        if (nextProps.api.status !== this.props.api.status && nextProps.api.status === API_ERROR) {
            const errorMsg = nextProps.api.errorMsg === "Request Not Found" ?
                "Invalid user email or phone, or invalid order id." : nextProps.api.errorMsg
            this.setState({ errorMsg }, () => {
                this.props.resetApiStatus()
            })
        }
    }
    componentWillUnmount() {
        this.props.resetApiStatus()
    }
    handleNoOp = prop => event => { }
    handleChange = prop => event => {
        this.setState({ [prop]: event.target.value });
    };

    renderShopTextField() {
        const { classes } = this.props;
        const { shop, staticShop } = this.state
        let displayShop = shop
        let handleChange = this.handleChange
        if (staticShop) {
            displayShop = staticShop
            handleChange = this.handleNoOp
        }

        return (
            <TextField
                id="shop"
                label="Shop Name"
                InputProps={{
                    className: classes.formshop,
                    startAdornment: (
                        <InputAdornment position="start">
                            <StoreIcon />
                        </InputAdornment>
                    ),
                }}
                name="shop"
                placeholder="Shop Name"
                margin="dense"
                variant="outlined"
                value={displayShop}
                onChange={handleChange('shop')}
                fullWidth
            />
        )
    }

    render() {
        const { classes, api } = this.props;
        const { errorMsg } = this.state
        return (
            <React.Fragment>
                <CssBaseline />
                <main className={classes.layout}>
                    <div className={classes.paper}>
                        <Typography variant="title">Shipping Yo</Typography>
                        <Typography variant="display1" style={{ marginTop: 8 }}>Please enter your order details to begin</Typography>
                        {api.status === API_START ?
                            (<div className={classes.progressBlock}><CircularProgress className={classes.progress} size={80} /></div>)
                            : (
                                <div className={classes.form}>
                                    <EmailorPhoneInput
                                        value={this.state.user}
                                        onChange={this.handleChange('user')}
                                    />
                                    <TextField
                                        id="OrderId"
                                        label="Order ID"
                                        name="OrderId"
                                        placeholder="Order ID"
                                        margin="dense"
                                        variant="outlined"
                                        value={this.state.orderId}
                                        onChange={this.handleChange('orderId')}
                                        fullWidth
                                    />

                                    <Button
                                        type="submit"
                                        fullWidth
                                        variant="extendedFab"
                                        color="primary"
                                        className={classes.submit}
                                        onClick={this.handleLoginButton}
                                    >
                                        Find your order
                                        </Button>
                                </div>
                            )
                        }
                        {errorMsg && errorMsg !== '' ?
                            (<div style={{ marginTop: 40, textAlign: 'center' }}>
                                <Typography variant="display3" className={classes.error}>{errorMsg}</Typography>
                            </div>)
                            : null}
                    </div>
                </main>
            </React.Fragment>
        );

    }
}

const mapStateToProps = ({ lazadaApp: { loggedUserState, api } }) => ({
    pending: loggedUserState.pending,
    logged: loggedUserState.logged,
    loaded: loggedUserState.loaded,
    claimed: loggedUserState.claimed,
    errorInfo: loggedUserState.errorInfo,
    api
})
const mapDispatchToProps = dispatch => bindActionCreators({
    logginAction,
    resetApiStatus
}, dispatch)


export default connect(
    mapStateToProps, mapDispatchToProps
)(withStyles(styles)(LoginPage))
