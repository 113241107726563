import React, { Component } from 'react';
import {
  Card,
  Layout,
  Button
} from "@shopify/polaris";
import {
  Grid
} from '@material-ui/core'
import { DEFAULT_PRIMARY_COLOR, DATE_FORMAT_DEFAULT } from '../../constants'
import { sendMail } from 'utils'

class Faq extends Component {
  render() {
    return (
      <div style={{ padding: '0px 40px' }}>
        <Grid container
          justify='space-between'
          alignItems="center"
          style={{
            margin: '5px 0px'
          }}>
          <Grid item style={{ marginRight: 20 }}>
            <span style={{ fontSize: 24, fontWeight: 700, color: DEFAULT_PRIMARY_COLOR }}>FAQ</span>
          </Grid>
        </Grid>
        <Layout>
          <Layout.Section>
            <Card title="How Igloo Returns works?">
              <div style={{ padding: '5px 40px' }}>
                <p>Return shipping fee is an additional cost for customers that stops customers from trying something new. With Igloo Returns, your customers can get reimbursed for return shipping fee for the products they purchase. Igloo Returns premium is calculated dynamically and does not exceed 1% of the product value. <br /><br /> Each return request is shown on the <b>Return Orders</b> tab and requires the store owner’s permission to be approved. Once approved, Igloo Returns will refund money back to customers according to the shipping fee of the original order or help customers to return products via our logistic partners.</p>
              </div>
            </Card>
            <Card title="How is the service fee charged?">
              <div style={{ padding: '5px 40px' }}>
                <p>As your customers buy goods that are protected by Igloo Returns, the store owner collects the premium. Igloo Returns will charge a service fee to the store owner on the amount of premium collected. The amount of service charge should not exceed USD 100 and this is charged on a monthly basis. The charge details can be seen in <b>Invoice</b> Tab.</p>
              </div>
            </Card>
            <Card title="How to make your products covered by Igloo Returns?">
              <div style={{ padding: '5px 40px' }}>
                <p>Igloo Returns do not cover products by default. A store owner needs to select products that he/she would like to be covered by Igloo Returns - this can be done in two ways
</p>
                <p>1. Go to the <b>Products</b> tab → Select products you want to cover → Click the <b>Cover</b> button.</p>
                <p>2. You can also export CSV file to modify your covered products list and re-import it to update the coverage status.</p>
              </div>
            </Card>
            <Card title="How to view and approve/reject return orders?">
              <div style={{ padding: '5px 40px' }}>
                <p>Igloo Returns do not process the return order request unless the store owner has approved it. Store owner can simply go to <b>Return Orders</b> Tab to approve a return order.</p>
              </div>
            </Card>
            <Card title="How can I send you some suggestions or feedback?">
              <div style={{ padding: '5px 40px' }}>
                <p>We are always there to help you! Please send us an email to <a onClick={() => { sendMail("support-yo@axinan.com") }} style={{ color: 'blue', cursor: 'pointer', textDecoration: "underline" }}>support-yo@axinan.com</a> and we will try our best to get back to you soon. </p>
              </div>
            </Card>
            <Card> </Card>
          </Layout.Section>
        </Layout>
      </div>
    )
  }
}
export default Faq
