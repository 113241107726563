import React, { useContext } from 'react'
import {
    Button
} from "@shopify/polaris";
import {
    Grid, ButtonBase
} from "@material-ui/core"
import { DEFAULT_PRIMARY_COLOR } from '../../constants'
import { ShopifyConfigContext } from 'common/hoc/withShopifyConfig'
import log from 'utils/debug'

const ButtonStyles = {
    base: {
        width: "100%",
        padding: 10,
        textAlign: "left",
        fontSize: 14,
    },
    active: {
        backgroundColor: "#88889a",
        color: 'white',
        fontWeight: 700,
    },
    triangle: {
        borderColor: "transparent transparent transparent #88889a",
        borderStyle: "solid",
        borderWidth: "7px 0px 7px 10px",
        height: "0px",
        width: "0px",
    }
}
function AnchorButton({ children, active, onClick }) {
    const s = Object.assign({}, ButtonStyles.base, active ? ButtonStyles.active : {})
    return (
        <ButtonBase style={{ width: "100%" }} onClick={onClick}>
            <div style={s}>
                {children}
            </div>
            {active &&
                <div style={ButtonStyles.triangle} />
            }
        </ButtonBase>
    )
}


class Tutorial extends React.Component {
    state = {
        active: 0
    }
    handleButton = (url) => () => {
        log(url)
        window.open(`${url}/admin/themes`)
    }
    handleTouchStepButton = (anchor, active) => () => {
        this.setState({ active })
        this.iframe.contentWindow.postMessage({ anchor }, "*")
    }
    render() {
        const { active } = this.state
        return (
            <ShopifyConfigContext.Consumer>
                {value => (
                    <div style={{ padding: '0px 40px', height: '100%', minHeight: '500px', display: 'flex', flexDirection: "column" }}>
                        <div style={{ margin: 20 }}>
                            <span style={{ fontSize: 20, fontWeight: 700, color: DEFAULT_PRIMARY_COLOR }}>
                                Please follow tutorial guide below here.
                            </span>
                        </div>
                        <hr style={{ border: 0, borderTop: '1px solid #ddd' }} />
                        <Grid container style={{ marginTop: 5, marginBottom: 5, flex: 1 }} spacing={8}>
                            <Grid item container direction="column" sm={2} xs={2} spacing={8}>
                                <Grid item>
                                    <AnchorButton onClick={this.handleTouchStepButton("welcome", 0)} active={active === 0}>Intro</AnchorButton>
                                </Grid>
                                <Grid item>
                                    <AnchorButton onClick={this.handleTouchStepButton("howsetup", 1)} active={active === 1}>How to Use</AnchorButton>
                                </Grid>
                                <Grid item>
                                    <AnchorButton onClick={this.handleTouchStepButton("uninstallation", 2)} active={active === 2}>Uninstallation</AnchorButton>
                                </Grid>
                                <Grid item>
                                    <AnchorButton onClick={this.handleTouchStepButton("contact", 3)} active={active === 3}>Contact us</AnchorButton>
                                </Grid>
                            </Grid>
                            <Grid item sm={10} xs={10}>
                                <iframe
                                    src="https://static.yopify.axinan.com/inventoryyo/guide.html#welcome"
                                    height={'95%'}
                                    width={'100%'}
                                    frameBorder={0}
                                    ref={(e) => { this.iframe = e }} />
                            </Grid>
                        </Grid>
                    </div>
                )}
            </ShopifyConfigContext.Consumer>
        )
    }
}

export default Tutorial
