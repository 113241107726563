import axFetch from 'utils/fetch'
import { DefaultHeader } from 'utils/apiUtils'

export function ReceiveParcel(params) {
    const fetchOptions = {
        method: 'POST',
        headers: {
            ...DefaultHeader
        },
        credentials: 'include',
    }
    return axFetch(`/v1/inventorycashback/claim/parcel:receive`, fetchOptions, params)

}