import React from 'react';

import {
    Grid, ButtonBase
} from '@material-ui/core'

function genOptions(options) {
    if (options) {
        return options.map(o => {
            const json = JSON.parse(o)
            return json["Name"]
        })
    }
    return null
}

export default ({ item, lineItem, onClickImage }) => {
    const imgSrc = (item.product_images && item.product_images.length > 0 && JSON.parse(item.product_images[0]).Src) || ""
    // const returnItemImageJson = JSON.parse(item.images[0])
    const variantTitle = item.variant_title.split("/")
    const options = genOptions(item.options)
    const variantTitleArray = variantTitle.map((v, i) => {
        return !!options[i] ? options[i] + ": " + v : v
    })
    return (
        <Grid container direction="row"
            justify="flex-start"
            alignItems="center"
            style={{ padding: '10px 25px', borderBottom: '1px solid #f4f6f8' }}>
            <Grid item xs={12} sm={4}
                container
                spacing={8}
                direction="row"
                justify="flex-start"
                alignItems="center"
            >
                <Grid item xs={6} sm={2}>
                    <img src={imgSrc} alt="refund product" style={{ width: '100%' }} />
                </Grid>
                <Grid item xs={6} sm={10}>
                    <div style={{ marginLeft: '15px' }}>
                        <div >{item.title}</div>
                        <div >{variantTitleArray.join(" | ")}</div>
                    </div>
                </Grid>
            </Grid>
            <Grid item xs={4} sm={1}>${lineItem.price.toFixed(2)}</Grid>
            <Grid item xs={4} sm={1}>${lineItem.premium.toFixed(2)}</Grid>
            <Grid item xs={4} sm={2}>{item.quantity}</Grid>
            <Grid item xs={6} sm={2}>{item.reason}</Grid>
            <Grid item xs={6} sm={2} style={{ position: 'relative' }}>
                <ButtonBase onClick={onClickImage(item.images)} style={{ position: 'relative' }}>
                    <div style={{ position: 'relative', minHeight: 100, width: '100%' }}>
                        {item.images.length > 2 && <img src={item.images[2]} alt="refund line item" style={{ maxHeight: 100, width: 80, left: 40, top: 10, position: 'absolute', boxShadow: '2px 2px 3px #cccccc' }} />}
                        {item.images.length > 1 && <img src={item.images[1]} alt="refund line item" style={{ maxHeight: 100, width: 90, left: 20, top: 5, position: 'absolute', boxShadow: '2px 2px 3px #cccccc' }} />}
                        <img src={item.images[0]} alt="refund line item" style={{ maxHeight: 100, width: 100, top: 0, position: 'absolute', boxShadow: '2px 2px 3px #cccccc' }} />
                    </div>
                </ButtonBase>
            </Grid>
        </Grid>
    )
}
