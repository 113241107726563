import { GET_INVOICE_DONE } from '../../constants';
import axFetch from 'utils/fetch'
import { DefaultHeader } from 'utils/apiUtils'
import WithAPIStatus from '../withAPIStatus'

export function getInvoice(month, year) {
    const fetchOptions = {
        method: 'GET',
        headers: {
            ...DefaultHeader
        },
        credentials: 'include',
    }
    return dispatch => {
        return WithAPIStatus(dispatch)(
            axFetch(`/v1/insurances/freereturn/invoices?month=${month}&year=${year}`, fetchOptions)
                .then(json => dispatch(geInvoiceCompoleteAction(json)))
        )
    };
}
function geInvoiceCompoleteAction(json) {
    return {
        type: GET_INVOICE_DONE,
        payload: {
            json
        },
    };
}
