import React from 'react';
import { Switch, Route } from 'react-router-dom'

import ReceivedForm from './containers/ReceivedForm'

import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';

const theme = createMuiTheme({
    typography: {
        // Use the system font instead of the default Roboto font.
        fontFamily: [
            'Poppins',
            '-apple-system',
            'BlinkMacSystemFont',
            '"Segoe UI"',
            'Roboto',
            '"Helvetica Neue"',
            'Arial',
            'sans-serif',
            '"Apple Color Emoji"',
            '"Segoe UI Emoji"',
            '"Segoe UI Symbol"',
        ].join(','),
        title: {
            fontSize: '16px',
            fontWeight: 600,
        },
        display1: {
            fontSize: '11px',
            fontWeight: 500,
        },
        display2: {
            fontSize: '14px',
            fontWeight: 500,
        },
        display3: {
            fontSize: '14px',
            fontWeight: 600,
        },
        display4: {
            fontSize: '16px',
            fontWeight: 500,
        },
    },
});



const App = () => {
    return (
        <MuiThemeProvider theme={theme}>
            <CssBaseline />
            <Switch>
                <Route exact path="/warehouse/inventorycashback" component={ReceivedForm} />
            </Switch>
        </MuiThemeProvider>
    )
}

export default App;
