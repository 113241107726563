import React from 'react';
import { Route } from 'react-router-dom'
import classNames from 'classnames';

import CssBaseline from '@material-ui/core/CssBaseline';
import { withStyles } from '@material-ui/core/styles';




const styles = theme => ({
    main: {
        height: '100%',
        width: 'auto',
        [theme.breakpoints.up(600 + theme.spacing.unit * 2 * 2)]: {
            width: 600,
            marginLeft: 'auto',
            marginRight: 'auto',
        },
        backgroundColor: 'white',
    }

});


class DefaultLayout extends React.Component {
    render() {
        const { component: Component, classes, theme, ...rest } = this.props;
        return (
            <Route {...rest} render={matchProps => (
                <React.Fragment>
                    <CssBaseline />
                    <main className={classNames(classes.main)}>
                        <Component {...matchProps} />
                    </main>
                </ React.Fragment>
            )} />
        )
    }
}


export default withStyles(styles, { withTheme: true })(DefaultLayout)