import React, { Component } from 'react'
import Cookies from 'js-cookie';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux'
import {
    DataTable,
    Button,
    Thumbnail,
    Card
} from "@shopify/polaris";
import {
    TextField
} from '@material-ui/core';
import { WrappedApiStatusDispatch } from '../../actions/withAPIStatus'
import { listProducts } from '../../actions/product'
import { createPolicy } from '../../actions/policy'
import Tip from '../../components/ArrowToolTip'
import ProductSkus from '../../components/ProductSkus'
import EmptyContent from '../../components/EmptyDisplay'


class Products extends Component {
    state = {
        productDataList: [],
        productDataMap: {},
        modalInfo: {
            showModal: false,
            modalData: []
        },
        currentProductId: "",
        defaultOpenTip: true,
        searchParam: ""
    }

    componentDidMount() {
        this.props.WrappedApiStatusDispatch(
            listProducts()
                .then(json => {
                    this.initState(json.products)
                }).catch(err => {
                })
        )
    }

    initState = (dataList) => {
        this.setState({ productDataList: dataList })
        let productDataMap = {}
        dataList.forEach(element => {
            productDataMap[element.product_id] = element.variants
        })
        this.setState({ productDataMap: productDataMap })
    }

    createPolicy = (params) => {
        this.props.WrappedApiStatusDispatch(
            createPolicy(params)
                .then(json => {
                    this.closeModal()
                    this.props.WrappedApiStatusDispatch(
                        listProducts()
                            .then(json => {
                                this.initState(json.products)
                            }).catch(err => {
                            })
                    )
                }).catch(err => {
                })
        )
    }
    handleTipClick = () => {
        Cookies.set("yopify_icb_tip_show", true)
        this.setState({ defaultOpenTip: false })
    }

    initRows = (productDataList) => {
        const { searchParam } = this.state
        let rows = []
        productDataList.forEach((element, index) => {
            if (searchParam !== "") {
                if (element.handle.includes(searchParam)) {
                    this.createRows(element, index, rows)
                }
            } else {
                this.createRows(element, index, rows)
            }
        });
        return rows
    }

    createRows = (element, index, rows) => {
        const imageSrc = element.images && element.images.length > 0 && JSON.parse(element.images[0]).Src || ""
            const button = (
                <Button
                    onClick={
                        () => {
                            if (!Cookies.get("yopify_icb_tip_show")) {
                                Cookies.set("yopify_icb_tip_show", true)
                            }
                            this.handleClick(element.product_id)
                        }
                    }
                >
                    View Detail
                </Button >
            )
            const buttonComponent = (index === 0 && !Cookies.get("yopify_icb_tip_show")) ?

                (
                    <Tip open={this.state.defaultOpenTip} handleClcik={this.handleTipClick}>
                        {button}
                    </Tip>
                )
                : button
            const row = [
                <Thumbnail source={imageSrc} size="large" />,
                element.handle,
                element.shopify_product_id,
                element.variants.length,
                buttonComponent
            ]
            rows.push(row)
    }

    handleClick = (productId) => {
        const { productDataMap, modalInfo } = this.state
        let { currentProductId } = this.state
        const data = productDataMap[productId]
        modalInfo.showModal = true
        modalInfo.modalData = data
        currentProductId = productId
        this.setState({ modalInfo: modalInfo, currentProductId: currentProductId })
    }

    closeModal = () => {
        const { modalInfo } = this.state
        modalInfo.showModal = false
        modalInfo.modalData = []
        this.setState({ modalInfo: modalInfo, currentProductId: "" })
    }

    handleChange = name => event => {
        const changeValue = event.target.value;
        this.setState({ [name]: changeValue });
    }

    render() {
        const { productDataList, modalInfo, currentProductId } = this.state
        const rows = this.initRows(productDataList)
        let dataComponent = null
        if (!productDataList || productDataList.length === 0 || productDataList[0].isSkeleton) {
            dataComponent = (
                <EmptyContent
                    title="You do not have any products created at the moment."
                    subTitle={<div>Note: Please create a product before proceeding to create a policy for covering the prodct.{' '}<a onClick={() => { window.ShopifyApp.redirect("/products"); }} style={{ color: "#3834c8", cursor: "pointer", textDecoration: "underline" }}>Create product</a></div>}
                />
            )
        }
        else {
            dataComponent = (
                <div style={{ padding: 10 }}>
                    <Card>
                        <DataTable
                            columnContentTypes={[
                                'text',
                                'text',
                                'text',
                                'text',
                                'text',
                            ]}
                            headings={[
                                'Image',
                                'Product name',
                                'Shopify product ID',
                                'Vraiants number',
                                '',
                            ]}
                            rows={rows}
                        />
                    </Card>
                </div>
            )
        }
        return (
            <React.Fragment>
                <div style={{ padding: 10 }}>
                    
                    <TextField
                        label="Search product"
                        inputProps={{
                            style: {fontSize: 25} 
                          }}
                        InputLabelProps={{
                            style: {fontSize: 16} 
                        }}
                        value={this.state.searchProduct}
                        onChange={this.handleChange('searchParam')}
                        margin="normal"
                        />
                </div>
                {!modalInfo.showModal && dataComponent}
                {modalInfo.showModal &&
                    <ProductSkus
                        data={modalInfo.modalData}
                        currentProductId={currentProductId}
                        showModal={modalInfo.showModal}
                        toggleModalFuc={this.closeModal}
                        externalFunc={this.createPolicy} />
                }
            </React.Fragment>
        )
    }
}

const mapDispatchToProps = dispatch => bindActionCreators({
    WrappedApiStatusDispatch
}, dispatch)
export default connect(null, mapDispatchToProps)(Products)