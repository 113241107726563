import _ from 'lodash';
import { SORT_ORDER, GET_ORDER_DONE, DATE_FORMAT_DEFAULT } from '../constants';
import moment from 'moment'


const columns = {
    id: "ID",
    name: "Order",
    customerString: "Customer",
    detail: "Including insurance list",
    createdAt: "Created",
    total_price: "Total Price",
    premium: "Total Service Fee",
    isHeader: true
}

const parseOrder = (data) => {
    let result = []
    if (data && data.length > 0) {
        result = data.map(element => {
            const timeString = moment(element.created_at).format(DATE_FORMAT_DEFAULT);
            let customer = element.customer ? JSON.parse(element.customer) : {}
            return (
                Object.assign({}, element,
                    {
                        customerString: `${customer.LastName || ""} ${customer.FirstName || ""}`,
                        createdAt: timeString
                    }));
        })
    }
    return result;
}


const sort = (data, rule) => {
    if (rule === 'ID_DESC') {
        return _
            .sortBy(data, ["id"])
            .reverse()
    }
    if (rule === 'ID_ASC') {
        return _.sortBy(data, ["id"])
    }
    return data
}

const initialState = {
    dataList: [{
        id: 'Skeleton-123',
        isSkeleton: true
    }],
    columns: columns,
}

function order(state = initialState, action) {
    switch (action.type) {
        case SORT_ORDER:
            return {
                ...state,
                dataList: sort(state.dataList, action.payload.rule)
            };
        case GET_ORDER_DONE:
            const orders = parseOrder(action.payload.json.data);
            //const orders = action.payload.json.data
            return {
                ...state,
                dataList: orders,
            };
        default:
            return state
    }
}

export default order
