import React from 'react';
import Order from '../../containers/OrderList';
import Product from '../../containers/ProductList';
import Dashboard from '../../containers/Dashboard';
import Invoice from '../../containers/Invoice';
import Faq from '../../containers/Faq';
import Tutorial from '../../containers/TuTorial'

function ContentSelector(props) {
    const { selected } = props
    if (selected === 1) {
        return <Product />
    } else if (selected === 2) {
        return <Dashboard />
    } else if (selected === 3) {
        return <Invoice />
    } else if (selected === 4) {
        return <Faq />
    } else if (selected === 5) {
        return <Tutorial />
    }
    return <Order />
}

export default ContentSelector;
