import React from 'react'
import reactReferer from 'react-referer';

import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import Typography from '@material-ui/core/Typography';
import withStyles from '@material-ui/core/styles/withStyles';
import { CircularProgress, TextField } from '@material-ui/core'
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import InputAdornment from '@material-ui/core/InputAdornment';
import StoreIcon from '@material-ui/icons/Store';
import { ReceiveParcel } from '../../actions'

import log from 'utils/debug'

const styles = theme => ({
    layout: {
        width: 'auto',
        display: 'block', // Fix IE11 issue.
        marginLeft: theme.spacing.unit * 3,
        marginRight: theme.spacing.unit * 3,
        [theme.breakpoints.up(400 + theme.spacing.unit * 3 * 2)]: {
            width: 400,
            marginLeft: 'auto',
            marginRight: 'auto',
        },
    },
    paper: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        paddingTop: `${theme.spacing.unit * 9 + 4}px`,
    },
    titleDiv: {
        marginTop: theme.spacing.unit * 2,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: `${theme.spacing.unit * 2}px ${theme.spacing.unit * 3}px ${theme.spacing.unit * 3}px`,
    },
    avatar: {
        margin: theme.spacing.unit,
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        width: '100%', // Fix IE11 issue.
        marginTop: theme.spacing.unit * 3,
    },
    formshop: {
        color: "#4f4fcb",
    },
    submit: {
        marginTop: theme.spacing.unit * 6,
    },
    progressMain: {
        height: '100%',
        width: 'auto',
        [theme.breakpoints.up(600 + theme.spacing.unit * 2 * 2)]: {
            width: 600,
            marginLeft: 'auto',
            marginRight: 'auto',
        },
        backgroundColor: 'white',
    },
    progress: {
    },
    progressBlock: {
        paddingTop: 100,
        margin: '0 auto',
        width: '80px'
    },

});


function LoadingModal({ active }) {
    return (
        <Dialog
            open={active}
        >
            <DialogContent>
                <CircularProgress />
            </DialogContent>
        </Dialog>
    )
}

class Page extends React.Component {
    state = {
        number: "",
        active: false
    };

    componentDidMount() {
    }
    handleChange = event => {
        this.setState({ [event.target.name]: event.target.value });
    };
    handleSearch = () => {

    }
    handleMark = () => {
        this.setState({ active: true }, this.handleAction)
    }
    handleAction = () => {
        ReceiveParcel({ shipping_number: this.state.number }).then(json => {
            log(json)
            this.setState({ number: "", active: false })
        })
    }
    render() {
        const { classes } = this.props
        const { number, active } = this.state
        return (
            <React.Fragment>
                <CssBaseline />
                <main className={classes.layout}>
                    <div className={classes.paper}>
                        <Typography variant="title">Inventory Cash Back Warehouse System</Typography>
                        <Typography variant="display1" style={{ marginTop: 8 }}>Key shipping number to mark parcel as received</Typography>
                        <TextField
                            id="number"
                            label="Shipping Number"
                            name="number"
                            margin="dense"
                            variant="outlined"
                            value={number}
                            onChange={this.handleChange}
                            fullWidth
                        />
                        {/* <Button
                            type="submit"
                            fullWidth
                            variant="outlined"
                            className={classes.submit}
                            onClick={this.handleSearch}
                        >
                            Search
                        </Button> */}
                        <Button
                            type="submit"
                            fullWidth
                            variant="outlined"
                            color="primary"
                            className={classes.submit}
                            onClick={this.handleMark}
                        >
                            Mark Received
                        </Button>
                        <LoadingModal active={active} />
                    </div>
                </main>
            </React.Fragment>
        );

    }
}



export default withStyles(styles)(Page)
