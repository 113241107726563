import Cookies from 'js-cookie'

import { API_START, API_DONE, API_ERROR, TOKEN_NAME } from '../constants'
import { withApiStatus } from 'utils/withApiStatus'



export default withApiStatus({
    API_START,
    API_DONE,
    API_ERROR,
    handleError: {
        401: function (dispatchData) {
            Cookies.remove(TOKEN_NAME)
            return dispatchData
        },
    }
})
