import React from 'react'
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux'
import _ from 'lodash'
import moment from 'moment'
import { compose, withState, withHandlers, withProps } from 'recompose'
import {
    DataTable,
    Card,
    Button,
    ActionList,
    Popover,
    Checkbox,
} from "@shopify/polaris";
import {
    Switch, Grid, Tooltip,
    IconButton
} from '@material-ui/core';
import { WrappedApiStatusDispatch } from '../../actions/withAPIStatus'
import { getPolicy, toggleAutoExtend } from '../../actions/policy'
import { createClaim } from '../../actions/claim'
import SubmitDialog from '../../components/PolicySubmitDialog'
import ReadyListDialog from '../../components/PolicyReadyListDialog'
import PolicyStatus from '../../components/PolicyStatus'
import { DATE_FORMAT_DEFAULT } from '../../constants'
import { MapUtilsUpdate } from 'utils/map'
import DeleteIcon from '@material-ui/icons/Delete';
import QuestionIcon from 'common/components/questionSVGIcon'
import EmptyContent from '../../components/EmptyDisplay'

const enhanceGlobal = compose(
    withHandlers({
        onChange: ({ onChange, quantityList }) => (key, max, input) => {
            const value = parseInt(input)
            if (!Number.isInteger(value) || value < 0) {
                quantityList[key] = "0"
            } else if (value > max) {
                quantityList[key] = max + ""
            } else {
                quantityList[key] = value + ""
            }
            onChange(quantityList)
        },
        onSubmit: ({ createAction, quantityList }) => () => {
            createAction(quantityList)
        },
    }),
)
const dateFormater = (date) => {
    return moment(date).format(DATE_FORMAT_DEFAULT)
}


class Policies extends React.Component {
    state = {
        active: false,
        policies: [],
        filteredPolicies: [],
        selected: {},
        submitDialogOpen: false,
        readyDialogOpen: false,
        statusFilter: "",
    }
    componentDidMount() {
        this.props.WrappedApiStatusDispatch(
            getPolicy()
                .then(res => {
                    const { policy: policies } = res
                    this.setState({ policies, filteredPolicies: policies })
                })
        )
    }
    handleCheckBox = (policy) => (value) => {
        const { selected } = this.state;
        if (value) {
            selected[policy.id] = policy
        } else {
            delete selected[policy.id]
        }
        this.setState({ selected })
    }
    handleSubmitClose = () => {
        this.setState({
            submitDialogOpen: false
        })
    }
    handleSubmitOpen = () => {
        this.setState({
            submitDialogOpen: true
        })
    }
    handleReadyListClose = () => {
        this.setState({
            selected: {},
            readyDialogOpen: false
        })
    }
    handleReadyListOpen = () => {
        this.setState({
            readyDialogOpen: true
        })
    }
    genRows = (policies) => {
        return policies.map(policy => {
            const isApproved = policy.status === "approved"
            const amount = parseFloat(policy.goods.amount || 0).toFixed(2)
            return ([
                policy.id,
                policy.product_handle,
                policy.variant_title,
                (policy.status == 'created') ? '-' : amount,
                (policy.status == 'created') ? '-' : dateFormater(policy.protect_from),
                (policy.status == 'created') ? '-' : dateFormater(policy.protect_end),
                <PolicyStatus status={policy.status} />,
                <Switch
                    checked={policy.auto_extend}
                    color="primary"
                    disabled={!isApproved}
                    onClick={this.handleToggleAutoExtend(policy.id)}
                />
            ])
        })

    }
    genReadyRows = (policies) => {
        return policies.filter(policy => policy.status === "ready").map(policy => {
            const isSelected = this.state.selected && this.state.selected[policy.id]
            return ([
                <Checkbox checked={isSelected} onChange={this.handleCheckBox(policy)} />,
                policy.id,
                policy.product_handle,
                policy.variant_title,
                dateFormater(policy.protect_from),
                dateFormater(policy.protect_end),
                policy.status,
            ])
        })
    }
    handleSortByStatus = (status) => {
        this.setState({ statusFilter: status });
    };
    filter = () => {
        const { policies, statusFilter: status } = this.state;
        const newPolicies = policies.filter(row => {
            return status === '' || row.status === status
        })
        return newPolicies
    }
    handleToggleAutoExtend = (id) => () => {
        const { policies } = this.state
        if (policies) {
            const newPolicies = policies.map(p => {
                if (p.id === id) {
                    const newPolicy = Object.assign({}, p, { auto_extend: !p.auto_extend })
                    return newPolicy
                }
                return p
            })
            this.setState({ policies: newPolicies }, () => {
                toggleAutoExtend(id).then()
            })
        }
    }
    togglePopover = () => {
        this.setState(({ active }) => {
            return { active: !active };
        });
    };
    getEnhance = () => {
        let quantityList = {}
        const { selected } = this.state
        if (selected) {
            Object.keys(selected).forEach(key => {
                quantityList[key] = 0
            })
        }
        return compose(
            withState('quantityList', 'onChange', quantityList),
            enhanceGlobal
        )
    }
    createAction = (quantityList) => {
        const { selected, policies } = this.state
        const claims = Object.keys(selected).map(key => {
            return {
                policy_id: key,
                inventorycashback: {
                    return_quantity: quantityList[key]
                }
            }
        })
        const afterCreate = () => {
            const newPolicies = policies.map(p =>
                MapUtilsUpdate(p, selected, (o, i) => (i[o.id]), { status: "claimed" })
            )
            this.setState({ policies: newPolicies })
        }
        const createAction = () => {
            this.props.WrappedApiStatusDispatch(
                createClaim(claims).then(afterCreate)
            )
        }
        this.setState({ submitDialogOpen: false, readyDialogOpen: false, selected: {} }, createAction)
    }

    render() {
        const { policies } = this.state;
        if (!policies || policies.length === 0) {
            return (
                <EmptyContent
                    title="You do not have any policies at the moment."
                    subTitle="Note: Please create a product before proceeding to create a policy for covering the prodct."
                />
            )
        }
        const rows = this.genRows(this.filter())
        const readyRows = this.genReadyRows(this.state.policies)
        const hasReadyRows = readyRows.length > 0
        const activator = (
            <Button onClick={this.togglePopover}>Filter by status</Button>
        );
        const EnhanceDialog = this.getEnhance()(SubmitDialog)
        return (
            <div style={{ padding: 20 }}>
                <Grid container spacing={8} style={{ margin: "10px 0" }} alignItems="center">
                    <Grid item>
                        <Button
                            size="slim"
                            primary
                            onClick={this.handleReadyListOpen}
                            disabled={!hasReadyRows}
                        >
                            Create claim
                        </Button>
                    </Grid>
                    <Grid item>
                        <Tooltip title="you can create claim only if you have policies with ready to claim status">
                            <IconButton aria-label="Delete">
                                <QuestionIcon />
                            </IconButton>
                        </Tooltip>
                    </Grid>

                </Grid>
                <Grid container
                    alignItems="center"
                    style={{
                        margin: '15px 0px'
                    }}>
                    <Popover
                        active={this.state.active}
                        activator={activator}
                        onClose={this.togglePopover}
                    >
                        <ActionList
                            items={[
                                {
                                    content: 'Get all',
                                    onAction: () => {
                                        this.handleSortByStatus('');
                                    },
                                },
                                {
                                    content: 'Pending approval',
                                    onAction: () => {
                                        this.handleSortByStatus('created');
                                    },
                                },
                                {
                                    content: 'Proteced',
                                    onAction: () => {
                                        this.handleSortByStatus('approved');
                                    },
                                },
                                {
                                    content: 'Ready to claim',
                                    onAction: () => {
                                        this.handleSortByStatus('ready');
                                    },
                                },
                                {
                                    content: 'Policy claimed',
                                    onAction: () => {
                                        this.handleSortByStatus('claimed');
                                    },
                                }
                            ]}
                        />
                    </Popover>
                </Grid>
                <Card style={{ padding: 10 }}>
                    <DataTable
                        columnContentTypes={[
                            'text',
                            'text',
                            'text',
                            'text',
                            'text',
                            'text',
                            'text',
                            'text',
                        ]}
                        headings={[
                            'ID',
                            'Product',
                            'Variant title',
                            'Sale amount',
                            'From',
                            'End',
                            'Status',
                            'Auto extend',
                        ]}
                        rows={rows}
                    />
                </Card>
                {hasReadyRows &&
                    <ReadyListDialog
                        open={this.state.readyDialogOpen}
                        onClose={this.handleReadyListClose}
                        onNext={this.handleSubmitOpen}
                        data={readyRows}
                        selected={this.state.selected}
                    />
                }
                <EnhanceDialog
                    open={this.state.submitDialogOpen}
                    onClose={this.handleSubmitClose}
                    data={this.state.selected}
                    createAction={this.createAction}
                />

            </div>
        )
    }
}


const mapDispatchToProps = dispatch => bindActionCreators({
    WrappedApiStatusDispatch
}, dispatch)
export default connect(null, mapDispatchToProps)(Policies)