import React, { Component } from 'react'
import {
    Button,
    TextField,
    Icon,
    DataTable,
    Checkbox,
    Heading
} from "@shopify/polaris";
import {
    Grid, Dialog
} from '@material-ui/core';
import { updateClaim, createClaimShippingLabel, markClaimsAsShipped } from '../../actions/claim'
import ClaimStatus from '../ClaimStatus'
import ShippedDialog from '../MarkShippedDialog'

import b64decode from 'utils/base64'
import log from 'utils/debug'

class ClaimComp extends Component {
    state = {
        active: false,
        claimData: {},
        checkboxStatus: {},
        claimIds: []
    }

    componentDidMount() {
        const { data } = this.props
        let claimData = {}
        let checkboxStatus = {}
        for (let i = 0; i < data.length; i++) {
            const claimId = data[i].claim_id
            const { goods, payout } = data[i].policy
            claimData[claimId] = {
                goodsAmount: goods.amount,
                payoutAmount: payout.amount
            }
            checkboxStatus[claimId] = false
        }
        this.setState({ claimData: claimData, checkboxStatus: checkboxStatus })
    }

    refreshState = () => {
        this.setState({
            checkboxStatus: {},
            claimIds: []
        })
    }

    handleChecked = (claimId, value) => {
        let { checkboxStatus } = this.state
        checkboxStatus[claimId] = value
        this.setState({ checkboxStatus: checkboxStatus })
    }

    initRow = () => {
        const { data } = this.props
        const { claimData, checkboxStatus } = this.state
        let rows = []
        let pendingRows = []
        let approvedRows = []
        if (Object.keys(claimData).length > 0) {
            data.forEach(value => {
                if (value) {
                    if (value.status == 'created' || value.status == 'rejected') {
                        const { price } = value.variant
                        const { payout, goods } = value.policy
                        const total = ((goods.amount * Number(price)) / 2.5) + payout.amount
                        const max = (total*2.5)/price
                        const elem = [
                            value.claim_id,
                            value.policy.id,
                            <ClaimStatus status={value.status} />,
                            <TextField
                                key={`goodsAmount_${value.claim_id}`}
                                type="number"
                                value={claimData[value.claim_id].goodsAmount}
                                onChange={this.handleChange('goodsAmount', value, total, max)}
                            />,
                            <TextField
                                key={`payoutAmount_${value.claim_id}`}
                                type="number"
                                disabled={true}
                                value={claimData[value.claim_id].payoutAmount}
                            />,
                            <Button
                                onClick={
                                    () => { this.handleUpdateClaim(value.claim_id) }
                                }
                            >
                                Edit
                            </Button>
                        ]
                        pendingRows.push(elem)
                    }
                    if (value.status == 'approved' || value.status == 'shipped' || value.status == 'closed') {
                        const elem = [
                            <Checkbox
                                checked={checkboxStatus[value.claim_id]}
                                disabled={(value.status == 'approved') ? false : true}
                                onChange={
                                    (v) => {
                                        this.handleChecked(value.claim_id, v)
                                    }
                                }
                            />,
                            value.claim_id,
                            value.policy.id,
                            <ClaimStatus status={value.status} />,
                            value.policy.goods.amount,
                            value.policy.payout.amount,
                        ]
                        approvedRows.push(elem)
                    }

                }
            })
        }
        rows.push(pendingRows)
        rows.push(approvedRows)
        return rows
    }

    handleChange = (field, claim, total , max) => {
        return (value) => {
            let { claimData } = this.state
            if (field == 'goodsAmount') {
                const { price } = claim.variant
                if(value < 0) {
                    const newPayoutAmount = total - ((max * Number(price)) / 2.5)
                    claimData[claim.claim_id].payoutAmount = newPayoutAmount.toString()
                    claimData[claim.claim_id].goodsAmount = max
                } else {
                    const newPayoutAmount = total - ((value * Number(price)) / 2.5)
                    claimData[claim.claim_id].payoutAmount = newPayoutAmount.toString()
                    claimData[claim.claim_id].goodsAmount = value
                }
                this.setState({ claimData: claimData });
            }
        }
    }

    handleUpdateClaim = (claimId) => {
        const { claimData } = this.state
        const claim = claimData[claimId]
        const updateClaimParams = {
            inventorycashback: {
                return_quantity: claim.goodsAmount,
                refund_cash: claim.payoutAmount
            }
        }
        updateClaim(claimId, updateClaimParams)
            .then(json => {
                this.refreshState()
                this.props.toggleModalFuc()
                this.props.externalFunc()
                    .then(data => {
                    })
                    .catch(err => {
                    })
            }).catch(err => {
            })
    }

    printShippingLabel = () => {
        const { checkboxStatus } = this.state
        let claims = []
        for (var key in checkboxStatus) {
            if (checkboxStatus[key] == true) {
                claims.push(key)
            }
        }
        const params = {
            claim_ids: claims
        }
        createClaimShippingLabel(params)
        .then((json) => {
            this.refreshState()
            const blob = new Blob([b64decode(json.blob)], {type: "application/pdf"});
            const URL = window.URL || window.webkitURL;
            const url = URL.createObjectURL(blob);
            let link = document.createElement("a");
            link.href = url
            link.download = "shipping_label.pdf";
            link.click()
            URL.revokeObjectURL(url)
        })
        .catch(err => {
            log('print label err ', err)
        })
    }

    markAsShipped = () => {
        this.toggleActive()
        const { checkboxStatus } = this.state
        let claimIds = []
        for (var key in checkboxStatus) {
            if (checkboxStatus[key] == true) {
                claimIds.push(key)
            }
        }
        this.setState({ claimIds: claimIds })
    }

    markAsShippedAction = (keyMap, staticData) => {
        let params = keyMap
        params["claim_ids"] = staticData.claimIds
        markClaimsAsShipped(params)
            .then(json => {
                this.toggleActive()
                this.refreshState()
                this.props.toggleModalFuc()
                this.props.externalFunc()
                    .then(data => {
                    })
                    .catch(err => {
                    })
            }).catch(err => {
            })

    }

    toggleActive = () => {
        this.setState(({ active }) => ({ active: !active }));
    }

    render() {
        const { status, showModal } = this.props
        const rows = this.initRow()
        if (status == 'created') {
            return (
                <Dialog
                    fullScreen
                    open={showModal}
                    onClose={this.props.toggleModalFuc}
                >
                    <div style={{ padding: "20px" }}>
                        <Grid container
                            alignItems="center"
                            spacing={16}
                            style={{ padding: "10px" }}
                        >
                            <Grid item >
                                <Button onClick={this.props.toggleModalFuc} icon={<Icon source="arrowLeft" />} />
                            </Grid>
                            <Grid item >
                                <span>Update claim</span>
                            </Grid>
                        </Grid>
                    </div>
                    <div style={{ padding: "20px" }}>
                        <Heading>Pending claims</Heading>
                        <Grid container spacing={16} style={{ margin: "20px 0px" }}>
                            <div style={{ flex: 1, padding: 10 }}>
                                <DataTable
                                    columnContentTypes={[
                                        'text',
                                        'text',
                                        'text',
                                        'text',
                                        'text',
                                        'text',
                                    ]}
                                    headings={[
                                        'Claim ID',
                                        'Policy ID',
                                        'Claim status',
                                        'Goods amount',
                                        'Service fee amount',
                                        '',
                                    ]}
                                    rows={rows[0]}
                                />
                            </div>
                        </Grid>
                    </div>
                    <div style={{ padding: "20px" }}>
                        <Heading>Ready to ship claims</Heading>
                        <Button
                            onClick={this.printShippingLabel}
                        >
                            Print shipping label
                        </Button>
                        <Button
                            onClick={this.markAsShipped}
                        >
                            Mark as shipped
                        </Button>
                        <Grid container spacing={16} style={{ margin: "20px 0px" }}>
                            <div style={{ flex: 1, padding: 10 }}>
                                <DataTable
                                    columnContentTypes={[
                                        'text',
                                        'text',
                                        'text',
                                        'text',
                                        'text',
                                        'text',
                                    ]}
                                    headings={[
                                        '',
                                        'Claim ID',
                                        'Policy ID',
                                        'Claim status',
                                        'Goods amount',
                                        'Service fee amount',
                                    ]}
                                    rows={rows[1]}
                                />
                            </div>

                        </Grid>
                    </div>
                    <ShippedDialog
                        valueMap={
                            [
                                {
                                    key: "return_logistic_name",
                                    label: "Courier",
                                },
                                {
                                    key: "tracking_id",
                                    label: "Tracking ID",
                                },
                                {
                                    key: "shipping_number",
                                    label: "Shipping number",
                                }
                            ]
                        }
                        staticData={{ claimIds: this.state.claimIds }}
                        open={this.state.active}
                        onClose={this.toggleActive}
                        submitAction={this.markAsShippedAction}
                    />
                </Dialog>
            )
        } else {
            return (
                <div></div>
            )
        }


    }
}

export default ClaimComp
