import React, { Component } from 'react';
import { connect } from 'react-redux';
import Cookies from 'js-cookie';
import { bindActionCreators } from 'redux'
import {
  DataTable,
  Card,
  Heading,
  Subheading,
  Pagination,
  Stack,
  Avatar,
  Checkbox
} from "@shopify/polaris";
import {
  Grid
} from '@material-ui/core'
import { DEFAULT_PRIMARY_COLOR } from '../../constants'
import { getInvoice } from '../../actions/Invoice'
import { genGeneralAddress } from 'utils'

const privacyCookieName = "ax_p_c"


class Invoice extends Component {
  state = {
    month: null,
    year: null,
    hasNext: true,
    hasPrevious: true,
    privacyChecked: false
  }
  componentDidMount() {
    const now = new Date();
    const year = now.getFullYear();
    const month = now.getMonth() + 1;
    const isExistPrivacy = Cookies.get(privacyCookieName)
    this.setState({
      'month': month,
      'year': year,
      'hasNext': false,
      privacyChecked: !!isExistPrivacy
    });
    const { getInvoice } = this.props;
    getInvoice(month, year);
  }


  handleinInitialRows = (rows) => {
    return rows.map((row) => {
      return [row.createdAt, '', row.order_id, '', row.premium_price];
    });
  }

  handleChange = (direction) => {
    const now = new Date();
    let monthTemp = this.state.month
    let yearTemp = this.state.year
    let hasNextTemp = this.state.hasNext
    const { getInvoice } = this.props;
    if (direction) {
      if (monthTemp === 12) {
        monthTemp = 1
        yearTemp = yearTemp + 1
      } else {
        monthTemp = monthTemp + 1
      }
    } else {
      if (monthTemp === 1) {
        monthTemp = 12
        yearTemp = yearTemp - 1
      } else {
        monthTemp = monthTemp - 1
      }
    }
    if (monthTemp === (now.getMonth() + 1) && yearTemp === now.getFullYear()) {
      hasNextTemp = false
    } else {
      hasNextTemp = true
    }
    getInvoice(monthTemp, yearTemp);
    this.setState({
      'month': monthTemp,
      'year': yearTemp,
      'hasNext': hasNextTemp,
    });
  }

  convertMonthToStr = (month) => {
    if (month === 1) {
      return "JANUARY"
    } else if (month === 2) {
      return "FEBRUARY"
    } else if (month === 3) {
      return "MARCH"
    } else if (month === 4) {
      return "APRIL"
    } else if (month === 5) {
      return "MAY"
    } else if (month === 6) {
      return "JUNE"
    } else if (month === 7) {
      return "JULY"
    } else if (month === 8) {
      return "AUGUST"
    } else if (month === 9) {
      return "SEPTEMBER"
    } else if (month === 10) {
      return "OCTOBER"
    } else if (month === 11) {
      return "NOVEMBER"
    } else if (month === 12) {
      return "DECEMBER"
    }

  }
  handlePrivacyCheckedChange = value => {
    this.setState({ privacyChecked: value }, () => {
      if (value) {
        Cookies.set(privacyCookieName, "true")
      } else {
        Cookies.remove(privacyCookieName)
      }
    })
  }
  render() {
    const { hasNext, hasPrevious, month, year, privacyChecked } = this.state;
    const { invoice } = this.props;
    const initialRows = this.handleinInitialRows(invoice.dataList);
    const totalComp = (
      <div style={{ float: 'left' }}>{invoice.totalPrice}</div>
    )
    return (
      <div style={{ padding: '0px 40px' }}>
        <Grid container
          justify="flex-end"
          alignItems="center"
          style={{
            margin: '5px 0px'
          }}>
          <Grid item style={{ marginRight: 20 }}>
            <Checkbox
              checked={privacyChecked}
              onChange={this.handlePrivacyCheckedChange}
              label="I do not wish to receive any marketing or advertising materials from Axinan Pte. Ltd. Or Igloo Returns"
            />
          </Grid>
        </Grid>
        <Grid container
          alignItems="center"
          style={{
            margin: '5px 0px'
          }}>
          <Grid item style={{ marginRight: 20 }}>
            <span style={{ fontSize: 24, fontWeight: 700, color: DEFAULT_PRIMARY_COLOR }}>Invoice</span>
          </Grid>
        </Grid>
        <Stack distribution="fillEvenly">
          <Avatar customer name="Farrah" source="//cdn.shopify.com/s/files/1/0036/4177/0057/products/Ty9YJTgY_400x400_f5200f96-b4ec-458c-b1db-f01b56375b4e_200x200.jpg?v=1543304269" size="large" />
          <div>
            <Heading>Bill from</Heading>
            <Subheading>Axinan Pte. Ltd</Subheading>
            #07-13, Havelock II, 2 Havelock Road, Singapore, 059763
                    </div>
          <div>
            <Heading>Bill to</Heading>
            {genGeneralAddress(invoice.shopAddress)}
          </div>
        </Stack>
        <Grid container style={{
          margin: '5px 0px'
        }}>
          <Grid item>
            <Heading>{invoice.invoiceNumber || ""}</Heading>
          </Grid>
        </Grid>
        <Grid container
          alignItems="center"
          style={{
            margin: '5px 0px'
          }}>
          <Pagination
            hasPrevious={hasPrevious}
            onPrevious={() => {
              this.handleChange(false)
            }}
            hasNext={hasNext}
            onNext={() => {
              this.handleChange(true)
            }}
          />
          <Grid item style={{ marginRight: 20 }}>
            <span style={{ fontSize: 18, fontWeight: 500, color: '#A0A0A0', paddingLeft: '30px' }}>{this.convertMonthToStr(month)} {year}</span>
          </Grid>
        </Grid>
        <Card>
          <DataTable
            columnContentTypes={[
              'text',
              '',
              'text',
              '',
              'text',
            ]}
            headings={[
              'Date',
              '',
              'Order ID',
              '',
              'Service Fee',
            ]}
            rows={initialRows}
            totals={['', '', , '', '', totalComp]}
          />
        </Card>
      </div>
    );
  }
}

function mapStateToProps({ shopSystem: { invoice } }) {
  return { invoice };
}
const mapDispatchToProps = dispatch => bindActionCreators({
  getInvoice
}, dispatch)
export default connect(mapStateToProps, mapDispatchToProps)(Invoice);
