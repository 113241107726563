import axFetch from 'utils/fetch'
import { DefaultHeader } from 'utils/apiUtils'
import { API_RESET_STATUS, LOGIN_ACTION_DONE, LOGIN_BY_COOKIE } from '../constants'
import WithAPIStatus from './withAPIStatus'

import * as Invoice from './Invoice'
import * as Product from './Product'
import * as Order from './Order'
import * as Claim from './Claim'

export default {
    Invoice,
    Product,
    Order,
    Claim
}


export function resetApiStatus() {
    return dispatch => {
        dispatch({ type: API_RESET_STATUS })
    };
}


export function login(params) {
    const fetchOptions = {
        method: 'GET',
        headers: {
            ...DefaultHeader
        },
    }
    return dispatch => {
        return WithAPIStatus(dispatch)(
            axFetch(`/v1/merchants/auth/hmaclogin${params}&insurance=freeReturn`, fetchOptions)
                .then(json => dispatch(handleLoginDoneAction(json)))
        );
    };
}

export function loginByCookie() {
    return dispatch => {
        dispatch({
            type: LOGIN_BY_COOKIE
        })
    };
}

function handleLoginDoneAction(json) {
    return {
        type: LOGIN_ACTION_DONE,
        payload: {
            json
        }
    }
}
