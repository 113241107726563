import { API_RESET_STATUS, API_START, API_DONE, API_ERROR } from '../constants';

const initialState = {
    status: null,
    errorMsg: '',
}

function api(state = initialState, action) {
    switch (action.type) {
        case API_START:
            return {
                ...state,
                status: API_START,
            };
        case API_DONE:
            return {
                ...state,
                status: API_DONE,
            };
        case API_RESET_STATUS:
            return {
                ...state,
                status: null,
                errorMsg: ''
            };
        case API_ERROR:
            return {
                ...state,
                status: API_ERROR,
                errorMsg: action.payload.msg
            }
        default:
            return state
    }
}

export default api
