import { statusHandle } from './apiUtils'
import { API_URL } from './apiUtils'

export default function (url, options, params) {
    const _url = new URL(API_URL + url);
    const fetchOptions = {
        credentials: 'include',
        ...options,
    }
    if (params) {
        switch (options.method) {
            case "GET":
                Object.keys(params).forEach(key => _url.searchParams.append(key, params[key]));
                break
            case "POST":
                fetchOptions['body'] = JSON.stringify(params)
                break
            default:
                fetchOptions['body'] = JSON.stringify(params)
                break
        }
    }
    return fetch(_url.href, fetchOptions).then(response => statusHandle(response))
}
