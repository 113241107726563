const prefix = 'SHOP_SYSTEM_'

export const DEFAULT_PRIMARY_COLOR = '#4d53c4'
export const TOKEN_NAME = process.env.REACT_APP_MCT_COOKIE || "ax_shopify_mct_dev"
export const BASE_PATH = "/shop"
export const DATE_FORMAT_DEFAULT = "DD MMM YYYY"

export const LOGIN_ACTION_DONE = prefix + 'LOGIN_ACTION_DONE'


export const UPDATE_PAGE_PATH = prefix + 'UPDATE_PAGE_PATH'

export const FILTER_PRODUCT_FREE_RETURN_STATUS = prefix + "FILTER_PRODUCT_FREE_RETURN_STATUS"

export const SEARCH_PRODUCT = prefix + "SEARCH_PRODUCT"

export const UPDATE_PRODUCT_PLAN = prefix + "UPDATE_PRODUCT_PLAN"
export const ACTIVE_PRODUCT_INSURANCE = prefix + "ACTIVE_PRODUCT_INSURANCE"
export const BEFORE_ACTIVE_PRODUCT_INSURANCE = prefix + "BEFORE_ACTIVE_PRODUCT_INSURANCE"
export const DEACTIVE_PRODUCT_INSURANCE = prefix + "DEACTIVE_PRODUCT_INSURANCE"
export const BEFORE_DEACTIVE_PRODUCT_INSURANCE = prefix + "BEFORE_DEACTIVE_PRODUCT_INSURANCE"

export const SORT_ORDER = prefix + "SORT_ORDER"
export const GET_ORDER_DONE = prefix + "GET_ORDER_DONE"

export const UPDATE_SUBSCRIPTION = prefix + "UPDATE_SUBSCRIPTION"
export const GET_INSRUANCES_DONE = prefix + "GET_INSRUANCES_DONE"
export const BEFORE_UPDATE_SUBSCRIPTION = prefix + "BEFORE_UPDATE_SUBSCRIPTION"

export const GET_PRODUCTS_DONE = prefix + "GET_PRODUCTS_DONE"

export const GET_INVOICE_DONE = prefix + "GET_INVOICE_DONE"
export const GET_CLAIM_BY_ID_DONE = prefix + "GET_CLAIM_BY_ID_DONE"
export const UPDATE_CLAIM_DONE = prefix + "UPDATE_CLAIM_DONE"
export const API_START = prefix + "API_START"
export const API_DONE = prefix + "API_DONE"
export const API_RESET_STATUS = prefix + "API_RESET_STATUS"
export const API_ERROR = prefix + "API_ERROR"

export const UPLOAD_FILE_DONE = prefix + "UPLOAD_FILE_DONE"
export const LOGIN_BY_COOKIE = prefix + "LOGIN_BY_COOKIE"
