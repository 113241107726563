import React from 'react';
import Cookies from 'js-cookie';
import { withRouter } from 'react-router-dom'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { Spinner } from '@shopify/polaris'
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';

import { API_START, API_ERROR, TOKEN_NAME } from '../constants'
import AlertDialog from '../components/AlertDialog'
import { resetApiStatus } from '../actions'

function LoadingModal({ active }) {
    return (
        <Dialog
            open={active}
        >
            <DialogContent>
                <Spinner size="large" color="teal" />
            </DialogContent>
        </Dialog>
    )
}

const stateToProps = ({ shopSystem: { api } }) => ({
    api
})
const mapDispatchToProps = dispatch => bindActionCreators({
    resetApiStatus
}, dispatch)

export default (WrappedComponent) => (
    connect(stateToProps, mapDispatchToProps)(withRouter(
        class extends React.Component {
            handleCloseErrorDialog = () => {
                this.props.resetApiStatus()
            }
            handleClose401ErrorDialog = () => {
                this.props.resetApiStatus()
                Cookies.remove(TOKEN_NAME)
                window.location.reload()
            }
            render() {
                const { api } = this.props
                const showModal = api.status === API_START
                const isAPIError = api.status === API_ERROR
                const errorMsg = api.errorMsg || "Unknown Error"
                const handleClose = errorMsg === "Unauthorized" ? this.handleClose401ErrorDialog : this.handleCloseErrorDialog
                return (
                    <React.Fragment>
                        <LoadingModal active={showModal} />
                        <AlertDialog open={isAPIError} handleClose={handleClose}>
                            <div style={{ padding: 10 }}>{errorMsg}</div>
                        </AlertDialog>
                        <WrappedComponent {...this.props} />
                    </React.Fragment>
                )
            }
        }
    ))
)
