import React, { Component } from 'react'
import TextField from '@material-ui/core/TextField';
import _ from 'lodash';
import './style.css'
import PhoneInput from 'react-phone-number-input'
import log from 'utils/debug'

function isEmail(value) {
    return !value || _.includes(value, "@") || !value.match(/\+*[0-9]+/g)
}

class component extends Component {
    PhoneInputOnChange = (value) => {
        log("PhoneInputOnChange:", value)
        const event = { target: { value } }
        this.props.onChange(event)
    }

    render() {
        const { value, onChange } = this.props
        const element = isEmail(value) ?
            (
                <TextField
                    label="Email Address or Phone number"
                    placeholder="Email Address or Phone number"
                    id="simple-start-adornment"
                    margin="dense"
                    variant="outlined"
                    fullWidth
                    value={value}
                    onChange={onChange}
                />
            )
            : (
                <TextField
                    label="Email Address or Phone number"
                    placeholder="Email Address or Phone number"
                    id="simple-start-adornment"
                    margin="dense"
                    variant="outlined"
                    fullWidth
                    value={value}
                    onChange={onChange}
                    InputProps={{
                        startAdornment: (
                            <PhoneInput
                                country="SG"
                                placeholder="Enter phone number"
                                value={value}
                                onChange={this.PhoneInputOnChange} />
                        )
                    }}
                />
            )
        return (
            <React.Fragment>
                {element}
            </React.Fragment>
        )
    }
}

export default component
