import React from 'react';

import withStyles from '@material-ui/core/styles/withStyles';
import {
    Button, Grid, Typography,
} from '@material-ui/core'
// import CameraIcon from '@material-ui/icons/CameraAlt'
import CameraIcon from '../../assets/ic_addphoto.png'
import log from 'utils/debug'

const styles = theme => ({
    uploadButton: {
        height: '80px',
        width: '80px',
        backgroundColor: 'rgb(244, 243, 251)'
    },
    uploadButtonIcon: {
        width: 20
    },
    input: {
        display: 'none',
    },
    previewImage: {
        height: '80px',
        marginLeft: '4px',
        display: 'inline-block'
    },
    warning: {
        color: 'red',
    },
    imageGallery: {
        height: '80px',
        overflowX: 'auto',
        overflowY: 'hidden',
        whiteSpace: 'nowrap'
    }
});


class UploadPhoto extends React.Component {
    state = {
        submitAvailable: false
    }
    handleClickShowPassword = () => {

    }
    render() {
        const { classes, handleImageChange, imgList, keyName, imgSrc, photoRequiredMessage } = this.props;
        log("UploadPhotoList:", imgList, keyName)
        return (
            <React.Fragment>
                {photoRequiredMessage &&
                    (
                        <Typography variant="button" className={classes.warning}> {photoRequiredMessage} </Typography>
                    )
                }
                <Grid container spacing={8}>
                    <input
                        accept="image/*;capture=camera"
                        className={classes.input}
                        id={"outlined-button-file-" + keyName}
                        multiple
                        type="file"
                        value={imgSrc || ""} // NOTE: this is for change the component's value, so onChange will be fired each time when you have selected one
                        onChange={handleImageChange}
                    />
                    <Grid item xs={4} sm={2}>
                        <label htmlFor={"outlined-button-file-" + keyName}>
                            <Button variant="outlined" color="primary" component="span" className={classes.uploadButton}>
                                <img src={CameraIcon} className={classes.uploadButtonIcon} alt='' />
                            </Button>
                        </label>
                    </Grid>
                    <Grid item xs={8} sm={10} className={classes.imageGallery}>
                        {imgList && imgList.length > 0 ?
                            (imgList.map((imgObject, index) => {
                                return <img key={imgObject.imgSrc + index} src={imgObject.imgSrc} alt='receipt preview' className={classes.previewImage} />
                            }))
                            : null
                        }
                    </Grid>
                </Grid>
            </React.Fragment >
        );
    }
}


export default withStyles(styles)(UploadPhoto)
