import React from 'react'
import { Typography } from '@material-ui/core';
import withStyles from '@material-ui/core/styles/withStyles';
import classNames from 'classnames';
import { RETURN_FORM_COLOR_SECONDARY, RETURN_FORM_COLOR_GREY } from '../../constants'

const styles = theme => ({
    mainBlock: {
        height: '140px'
    },
    dateBlock: {
        textAlign: 'right'
    },
    dateText: {
        color: RETURN_FORM_COLOR_GREY,
    },
    centerLine: {
        // height: '100%',
        width: '100%',
        position: 'absolute',
        borderBottom: '1px dashed ' + RETURN_FORM_COLOR_SECONDARY,
    },
    centerLineTail: {
        width: '0%',
    },
    centerLineSolid: {
        borderBottom: '1px solid ' + RETURN_FORM_COLOR_SECONDARY,
    },
    iconBlock: {
        margin: '0px auto',
        height: '100%',
        position: 'relative',
        display: 'flex',
        flex: '1 1 25px',
    },
    iconBlockTail: {
        flex: '0 0 20px',
    },
    buttonOuterBlock: {
        position: 'absolute',
        marginLeft: '-40px',
        marginTop: '-30px',
        backgroundColor: 'rgba(255, 211, 219, 0.3)',
        width: '80px',
        height: '80px',
        borderRadius: '40px'
    },
    buttonBlock: {
        backgroundColor: 'rgb(255, 211, 219)',
        width: '60px',
        height: '60px',
        borderRadius: '30px',
        position: 'absolute',
    },
    buttonClearBlock: {
        backgroundColor: 'transparent'
    },
    button: {
        position: 'relative',
        left: '10px',
        top: '10px',
    },
    emptyIconBlock: {
        width: '40px',
        height: '40px',
        border: '2px solid ' + RETURN_FORM_COLOR_SECONDARY,
        backgroundColor: 'white',
        borderRadius: '20px',
        position: 'relative',
        left: '10px',
        top: '10px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    emptyIconBlockProcessing: {
        backgroundColor: RETURN_FORM_COLOR_SECONDARY,
    },
    textDefault: {
        color: RETURN_FORM_COLOR_GREY,
        opacity: 0.8,
    },
    textProcessing: {
        color: RETURN_FORM_COLOR_SECONDARY,
    },
    textBlock: {
        position: 'relative',
        top: '20px',
        textAlign: 'center'
    },
    statusTextActived: {
        color: RETURN_FORM_COLOR_SECONDARY,
        opacity: 1,
    },
    numberSpan: {
        color: RETURN_FORM_COLOR_SECONDARY,
    },
    numberSpanProcessing: {
        color: 'white',
    },

})
class StatusComponent extends React.PureComponent {
    render() {
        const { item, isProcessing, isFulfilled, isTail, classes, itemIndex } = this.props;
        const lineClassArray = [classes.centerLine];
        const buttonOuterArray = [classes.buttonOuterBlock, classes.buttonClearBlock];
        const buttonInnerArray = [classes.buttonBlock, classes.buttonClearBlock];
        const iconBlockArray = [classes.iconBlock];
        const emptyIconBlockArray = [classes.emptyIconBlock]
        const numberSpanArray = [classes.numberSpan]
        const statusTextArray = [classes.textDefault]
        if (isTail) {
            lineClassArray.push(classes.centerLineTail)
            iconBlockArray.push(classes.iconBlockTail)
        }
        // if (!isProcessing) {
        // buttonOuterArray.push(classes.buttonClearBlock)
        // buttonInnerArray.push(classes.buttonClearBlock)
        // }
        if (isProcessing || isFulfilled) {
            lineClassArray.push(classes.centerLineSolid)
            // statusTextArray.push(classes.statusTextActived)
        }
        if (isProcessing) {
            numberSpanArray.push(classes.numberSpanProcessing)
            emptyIconBlockArray.push(classes.emptyIconBlockProcessing)
            statusTextArray.push(classes.textProcessing)
        }


        const lineClass = classNames(lineClassArray)
        const buttonOuterClass = classNames(buttonOuterArray)
        const buttonInnerClass = classNames(buttonInnerArray)
        const iconBlockClass = classNames(iconBlockArray)
        const numberSpanClass = classNames(numberSpanArray)
        const emptyIconBlockClass = classNames(emptyIconBlockArray)
        const statusTextClass = classNames(statusTextArray)

        const iconCompoent = <div className={emptyIconBlockClass}><Typography variant="display1" className={numberSpanClass}>{itemIndex}</Typography></div>

        // isProcessing || isFulfilled ?
        //     (<Button variant="fab" disabled aria-label="Delete" className={classes.button}
        //         style={{ backgroundColor: RETURN_FORM_COLOR_SECONDARY, color: 'white' }} mini>
        //         <DoneIcon fontSize='small' />
        //     </Button>) :
        //     (
        //         <div className={classes.emptyIconBlock}></div>
        //     )
        return (
            <div className={iconBlockClass}>
                <div className={lineClass}></div>
                <div className={buttonOuterClass}>
                    <div className={buttonInnerClass}>
                        {iconCompoent}
                        <div className={classes.textBlock}>
                            <Typography variant="display1" className={statusTextClass}>{item.text}</Typography>
                        </div>
                    </div>
                </div>
            </div>
        )

    }
}

export default withStyles(styles)(StatusComponent);


// return (
//     <Grid container className={classes.mainBlock}>
//         <Grid item xs={4} sm={4} className={classes.dateBlock}>
//             <Typography variant="display1" className={classes.dateText}>{item.date}</Typography>
//         </Grid>
//         <Grid item xs={4} sm={4}>
//             <div className={classes.iconBlock}>
//                 <div className={lineClass}></div>
//                 <div className={buttonOuterClass}>
//                     <div className={buttonInnerClass}>
//                         {iconCompoent}
//                     </div>
//                 </div>
//             </div>
//         </Grid>
//         <Grid item xs={4} sm={4}>
//             <Typography variant="title" className={statusTextClass}>{item.text}</Typography>
//         </Grid>

//     </Grid>
// )