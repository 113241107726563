import React from 'react'
import { Typography } from '@material-ui/core';
import withStyles from '@material-ui/core/styles/withStyles';

import StatusComponent from './statusComponent'

const styles = theme => ({
    main: {
        padding: '24px 24px 24px 24px'
    },
    container: {
        display: 'flex',
        height: '40px',
        left: '20px',
        position: 'relative',
        padding: '0 20px',
    },
    statusBlock: {
        textAlign: 'center'
    }
})

class StatusList extends React.PureComponent {
    render() {
        const data = [
            {
                detail: 'Deliver your return items now!',
                text: 'Claim submitted',
                status: 'processing'
            },
            {
                detail: 'Please upload your receipt again',
                text: 'Claim Processing',
                status: 'none'
            },
            {
                text: 'Claim finished',
                status: 'none'
            }
        ]
        const { helperText, classes, statusIndex, isShippedByCustomer } = this.props;
        if (!isShippedByCustomer) {
            data[2] = {
                text: 'Claim finished',
                status: 'none'
            }
        }
        const components = data.map((map, index) => {
            const isFulfilled = index < statusIndex
            const isProcessing = index === statusIndex
            const isTail = (index + 1) === data.length
            return <StatusComponent isFulfilled={isFulfilled}
                isProcessing={isProcessing} isTail={isTail} item={map} key={index + 1} itemIndex={index + 1} />
        })
        return (
            <div className={classes.main}>
                <div className={classes.container}>
                    {
                        components
                    }
                </div>
                {helperText ?
                    (<div className={classes.statusBlock}>
                        <Typography variant="display4">{helperText}</Typography>
                    </div>)
                    : null
                }
            </div>
        )
    }
}

export default withStyles(styles)(StatusList);