
import React from 'react'
import {
    Button,
    TextField,
    Icon,
} from "@shopify/polaris";
import {
    Grid, Dialog, Slide, Tooltip
} from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import IconButton from '@material-ui/core/IconButton'

function Transition(props) {
    return <Slide direction="right" {...props} />;
}

const SubmitDialog = ({ open, onClose, data, quantityList, onChange, onSubmit }) => (
    <Dialog
        fullScreen
        open={open}
        onClose={onClose}
        TransitionComponent={Transition}
    >
        <div style={{ padding: "20px" }}>
            <Grid container
                justify="space-between"
                alignItems="center"
                spacing={16} style={{ margin: "20px 0 0 20px" }}>
                <Grid item sm={4}>
                    <Button onClick={onClose} icon={<Icon source="arrowLeft" />} />
                </Grid>
                <Grid item sm={1}></Grid>
                <Grid item >
                    <div style={{ padding: 10, border: "1px solid #e0e0e0" }}>
                        Refund fee is calculated by sale amount.
                    </div>
                </Grid>
                <Grid item sm={1}></Grid>
            </Grid>
            <Grid container spacing={16} style={{ margin: "20px 0px" }}>
                {data && Object.keys(data).map(key => {
                    const p = data[key]
                    const quantity = quantityList[key]
                    const serviceFee = (parseInt(p.goods.amount) / 10) * 2.5
                    const maxQuanatity = Math.floor(serviceFee / parseFloat(p.goods.price))
                    const fee = (serviceFee - parseInt(quantity) * parseFloat(p.goods.price)) / 2.5
                    return (
                        <SubmitItem
                            key={`SubmitItem-key-${key}`}
                            p={p}
                            max={maxQuanatity || 0}
                            fee={fee || 0}
                            quantity={quantity}
                            onChange={(value) => onChange(key, maxQuanatity, value)}
                        />
                    )
                })}

            </Grid>
            <Grid container
                direction="row"
                justify="flex-end"
                spacing={16}
                style={{ padding: "20px 40px 20px 20px" }}
            >
                <Grid item>
                    <Button onClick={onSubmit} primary>Submit</Button>
                </Grid>
            </Grid>
        </div>
    </Dialog>
)


const SubmitItem = ({ p, quantity, onChange, max, fee }) => (
    <Grid
        container
        direction="row"
        justify="space-around"
        alignItems="center"
        item
        sm={12}
        spacing={16}
        style={{ padding: 10, border: "1px solid #cccccc" }}
    >
        <Grid item sm={5} >
            {p.id} / {p.product_handle} / {p.variant_title} / {p.payout.currency}
        </Grid>
        <Grid item sm={1} >
        </Grid>
        <Grid item sm={2} >
            <TextField
                label={`Refund quantity (max:${max})`}
                type="number"
                min={0}
                max={max}
                value={quantity}
                onChange={onChange}
            />
        </Grid>
        <Grid item sm={2} >
            <TextField
                label="Refund fee"
                disabled
                value={fee}
            />
        </Grid>
        <Grid item sm={1} >
        </Grid>
    </Grid>
)

export default SubmitDialog