import { LOGGING_SUCCESS, LOGOUT_SUCCESS } from '../../constants'
import axFetch from 'utils/fetch'
import WithAPIStatus from '../withAPIStatus'
import log from 'utils/debug'

export function logginAction(request) {
    const fetchOptions = {
        method: 'GET',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        },
        credentials: 'include',
    }
    const params = {
        ...request,
        // orderId: "1029",
        // user: "vincent.wen@axinan.com"
    }
    return dispatch => {
        return WithAPIStatus(dispatch)(
            axFetch('/v1/public/order', fetchOptions, params)
                .then(json => dispatch(handleLoginComplete(json, request.shop)))
        );
    };
}

function handleLoginComplete(json, shop) {
    log(json)

    if (json.claim) {

    }
    return {
        type: LOGGING_SUCCESS,
        payload: {
            claimed: json.claim != null,
            data: json,
            shop: shop
        },
    }
}

export function logoutAction() {
    return { type: LOGOUT_SUCCESS }
}