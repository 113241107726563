import React from 'react';
import { Route } from 'react-router-dom'
import { connect } from 'react-redux'
import classNames from 'classnames';

import CssBaseline from '@material-ui/core/CssBaseline';
import { withStyles } from '@material-ui/core/styles';
import Icon from '@material-ui/icons/KeyboardBackspace'
import ContactMailIcon from '@material-ui/icons/HeadsetMic'
import { Typography, IconButton } from '@material-ui/core'


const styles = theme => ({
  main: {
    height: '100%',
    width: 'auto',
    [theme.breakpoints.up(600 + theme.spacing.unit * 2 * 2)]: {
      width: 600,
      marginLeft: 'auto',
      marginRight: 'auto',
    },
    backgroundColor: 'white',
    display: 'flex',
    flexDirection: 'column',
    position: 'relative'
  },
  header: {
    borderBottom: '1px solid #dddddd',
    padding: 10
  },
  content: {
    flexGrow: 1,
    display: 'flex',
  },
  icon: {
    verticalAlign: 'middle'
  },
  title: {
    display: 'inline-block',
    marginLeft: '10px',
    marginTop: 16,
  },
  contactMailIcon: {
    verticalAlign: 'middle',
    float: 'right',
    // padding: 0,
    marginRight: 10,
  }
});


class HeaderLayout extends React.Component {
  state = {
    isShowBack: false,
    backAction: null
  }
  handleClick = () => {
    if (this.state.backAction) {
      this.state.backAction()
    } else {
      window.history.back();
    }
  }
  handleSendEmail = () => {
    const { shop } = this.props.order.data
    if (shop) {
      const { email } = shop
      window.location.href = `mailto:${email}`
    } else {
      alert('Sorry, this shop did not provide contact email for Igloo Returns!')
    }
    // window.open('mailto:test@example.com');
  }
  handleSettingBackAction = (isShow, handleAction) => {
    this.setState({
      isShowBack: isShow,
      backAction: handleAction
    })
  }
  genHideToggle = (hideBack) => {
    if (hideBack) {
      if (this.state.isShowBack) {
        return false
      }
      return true
    }
    return false
  }
  render() {
    const { component: Component, classes, theme, hideBack, showContact, headerTitle, ...rest } = this.props;
    const hideToggle = this.genHideToggle(hideBack)
    return (
      <Route {...rest} render={matchProps => (
        <React.Fragment>
          <CssBaseline />
          <main className={classNames(classes.main)}>
            <div className={classNames(classes.header)}>
              {showContact ?
                (<IconButton className={classNames(classes.contactMailIcon)} onClick={this.handleSendEmail}>
                  <ContactMailIcon />
                </IconButton>)
                : null
              }
              {hideToggle ?
                null
                : (<IconButton className={classNames(classes.icon)} onClick={this.handleClick}>
                  <Icon />
                </IconButton>)
              }
              <Typography variant="display2" className={classNames(classes.title)}>{headerTitle}</Typography>

            </div>
            <div className={classNames(classes.content)}>
              <Component handleSettingBackAction={this.handleSettingBackAction} {...matchProps} />
            </div>
          </main>
        </ React.Fragment>
      )} />
    )
  }
}


const mapStateToProps = ({ returnSystem: { order } }) => ({
  order
})


export default connect(
  mapStateToProps, null
)(withStyles(styles, { withTheme: true })(HeaderLayout))