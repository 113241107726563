import { DefaultHeader, setupReturnYoPromise, resetReturnYoPromise } from 'utils/apiUtils';
import { API_DONE } from '../../constants'
import WithAPIStatus from '../withAPIStatus';
import axFetch from 'utils/fetch'
import log from 'utils/debug'

export function setupReturnYo(cb) {
    const fetchOptions = {
        method: 'PUT',
        headers: {
            ...DefaultHeader
        },
        credentials: 'include',
    }
    const errorHandleError = {
        403: function (dispatchData) {
            console.log("CatchError 403", dispatchData)
            cb(403)
            return { type: API_DONE }
        }
    }
    return dispatch => {
        return WithAPIStatus(dispatch)(
            setupReturnYoPromise().then(res => {
                log('setup return yo promise', res)
                cb(200)
            }),
            errorHandleError
        );
    };
}

export function resetReturnYo(cb) {
    const fetchOptions = {
        method: 'PUT',
        headers: {
            ...DefaultHeader
        },
        credentials: 'include',
    }
    const errorHandleError = {
        403: function (dispatchData) {
            console.log("CatchError 403", dispatchData)
            cb(403)
            return { type: API_DONE }
        }
    }
    return dispatch => {
        return WithAPIStatus(dispatch)(
            resetReturnYoPromise().then(res => {
                log('reset return yo promise', res)
                cb(200)
            }),
            errorHandleError
        );
    };
}

