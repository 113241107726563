import {
    GET_CLAIM_DATA_SUCCESS, CREATE_CLAIM_SUCCESS, UPDATE_CLAIM_PAYWAY_SUCCESS,
    GET_LOGISTIC_BY_CLAIM_SUCCESS,
    UPDATE_SENDER_RECEIVER_INFO
} from '../../constants'
import axFetch from 'utils/fetch'
import { uploadImagePromise } from 'utils/apiUtils'
import log from 'utils/debug'
import WithAPIStatus from '../withAPIStatus'
import { logginAction } from '../order'

export function getClaimData(queryToken) {
    const query = parseToken(queryToken)
    const fetchOptions = {
        method: 'GET',
    }
    const params = {
        expiry: query["expiry"],
        hmac: query["hmac"]
    }
    return dispatch => {
        return WithAPIStatus(dispatch)(
            axFetch(`/v1/public/claims/${query['claimId']}`, fetchOptions, params)
                .then(json => dispatch(handleGetClaimDataCompleteAction(json)))
        )
    };
}


function parseToken(queryToken) {
    const params = {};
    for (var i = 0, tokenLength = queryToken.length; i < tokenLength; i++) {
        const paramObject = queryToken[i].split('=')
        params[paramObject[0]] = queryToken[i].slice(paramObject[0].length + 1)
    }
    return params
}


function handleGetClaimDataCompleteAction(json) {
    log(json)
    return { type: GET_CLAIM_DATA_SUCCESS, payload: { data: json } }
}

export function approveClaim(claimId, orderId, user, shop) {
    console.log('params: ', claimId, orderId, user, shop)
    const fetchOptions = {
        method: 'POST',
    }
    const params = {
        claim_id: claimId
    }
    return dispatch => {
        return WithAPIStatus(dispatch)(
            axFetch(`/v1/lazada/claims/${claimId}:approve`, fetchOptions, params)
                .then(json => {
                    console.log('approve json: ', json)
                })
        );
    }

}

export function postClaim(claimRequet, orderId, shop) {
    log(claimRequet)
    const paramsObject = Object.assign({}, claimRequet)
    const uploadImageArray = [];
    Object.keys(paramsObject).forEach(key => {
        const { imgList } = paramsObject[key]
        if (imgList && imgList.length > 0) {
            imgList.forEach(img => {
                uploadImageArray.push(uploadImagePromise(img.file, img.imgSrc, key))
            })
            paramsObject[key].images = []
        }
    })

    return dispatch => {
        return WithAPIStatus(dispatch)(
            Promise.all(uploadImageArray)
                .then(values => {
                    values.forEach(value => {
                        paramsObject[value.key].images.push(value.data.url)
                    })
                    log(paramsObject)
                    const items = Object.keys(paramsObject).map(key => {
                        return {
                            itemId: paramsObject[key].itemId,
                            productId: paramsObject[key].productId,
                            reason: paramsObject[key].reason,
                            quantity: paramsObject[key].quantity,
                            images: paramsObject[key].images,
                        }
                    })
                    const params = { items, shop }
                    const fetchOptions = {
                        method: 'POST'
                    }
                    log("params:", JSON.stringify(params))
                    return axFetch(`/v1/orders/${orderId}/claim`, fetchOptions, params)
                })
                .then(json => dispatch(handleCreateClaimComplete(json)))
        );
    }
}


function handleCreateClaimComplete(json) {
    log(json)
    return {
        type: CREATE_CLAIM_SUCCESS,
        payload: { data: json }
    }
}


export function updateRefundMethod(claimId, data) {
    const { image, payway, parcel, notes } = data
    log(image)
    return dispatch => {
        return WithAPIStatus(dispatch)(
            uploadImagePromise(image.file, image.imgSrc, "").then(uploadResponse => {
                const params = {
                    attachments: [
                        {
                            imageUrl: uploadResponse.data.url
                        }
                    ]
                }
                if (payway) {
                    params["payway"] = payway
                }
                if (notes) {
                    params["notes"] = notes
                }
                const fetchOptions = {
                    method: 'POST',
                }
                const parcelParams = {
                    ...parcel
                }
                return axFetch(`/v1/claims/${claimId}/newparcel`, fetchOptions, parcelParams)
                    .then(json => {
                        return updateClaimByPayWay(claimId, params)
                    })
                    .then(json => dispatch(handleUpdateClaimPaywayComplete(json)))

            })
        );

    }
}

function updateClaimByPayWay(claimId, params) {
    const fetchOptions = {
        method: 'PUT'
    }
    return axFetch(`/v1/claims/${claimId}`, fetchOptions, params)
}

function handleUpdateClaimPaywayComplete(json) {
    return {
        type: UPDATE_CLAIM_PAYWAY_SUCCESS,
        payload: { data: json }
    }
}


export function createLogisticRefund(claimId, data, name) {
    const fetchOptions = {
        method: 'POST',
    }
    const params = {
        return_logistic_name: name,
        ...data
    }
    return dispatch => {
        return WithAPIStatus(dispatch)(
            axFetch(`/v1/claims/${claimId}/newparcel`, fetchOptions, params)
                .then(json => {
                    return updateClaimByPayWay(claimId, { payway: { type: name } })
                })
                .then(json => dispatch(handleUpdateClaimPaywayComplete(json)))
        );

    }
}

export function getLogisticByClaim(claimId) {
    const fetchOptions = {
        method: 'GET',
    }
    return dispatch => {
        return WithAPIStatus(dispatch)(
            axFetch(`/v1/claims/${claimId}/parcel`, fetchOptions)
                .then(json => dispatch(handleGetLogisticComplete(json)))
        );

    }
}
function handleGetLogisticComplete(json) {
    log("handleGetLogisticComplete")
    log(json)
    return {
        type: GET_LOGISTIC_BY_CLAIM_SUCCESS,
        payload: { data: json }
    }
}

export function updateSenderReceiverInfo(senderInfo, receiverInfo) {
    return {
        type: UPDATE_SENDER_RECEIVER_INFO,
        payload: {
            data: {
                senderInfo,
                receiverInfo,
            }
        }
    }
}