import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';

class ConfirmDialog extends React.Component {
    handleClose = () => {
        const { handleClose } = this.props;
        handleClose()
    };
    handleConfirm = () => {
        const { handleConfirm } = this.props;
        handleConfirm()
    }
    render() {
        const { open, children, yesText, noText } = this.props
        return (
            <React.Fragment>
                <Dialog
                    open={open}
                    onClose={this.handleClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogContent>
                        {children}
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.handleClose} >
                            {noText || "NO"}
                        </Button>
                        <Button onClick={this.handleConfirm} >
                            {yesText || "YES"}
                        </Button>
                    </DialogActions>
                </Dialog>
            </React.Fragment>
        );
    }
}

export default ConfirmDialog;