import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux'
import {
    withRouter
} from 'react-router-dom'
import {
    DataTable,
    Card,
    Button,
    ActionList,
    Popover,
    Form,
    FormLayout,
    TextField,
    Icon
} from "@shopify/polaris";
import {
    Grid
} from '@material-ui/core'
import ReturnStatusBadge from '../../components/ReturnStatusBadge'
import { DEFAULT_PRIMARY_COLOR } from '../../constants'
import { getOrders } from '../../actions/Order'
import { withTabContext } from '../../components/Tabs'
import log from 'utils/debug'

class Order extends Component {
    state = {
        initiallySortedRows: null,
        sortedRows: null,
        active: false,
        formValue: '',
    };

    componentDidMount() {
        const { getOrders } = this.props;
        getOrders();
    }

    sortByOrder = (rows, index, direction) => {
        return [...rows].sort((rowA, rowB) => {
            const amountA = parseFloat(rowA[index]);
            const amountB = parseFloat(rowB[index]);

            return direction === 'descending' ? amountB - amountA : amountA - amountB;
        });
    };

    handleSortChange = (sortValue) => {
        const { sortByRule } = this.props;
        this.setState({
            sortValue
        }, () => {
            sortByRule(this.state.sortValue);
        });
    }
    handleSortByOrder = (rows) => (index, direction) => {
        this.setState({ sortedRows: this.sortByOrder(rows, index, direction) });
    };

    handleSortByStatus = (status) => {
        const { order } = this.props;
        if (status === '') {
            this.setState({ sortedRows: null });
        } else if (status === 'approved') {
            this.setState({
                sortedRows: order.dataList.filter(row => {
                    return row.status === 'approved' || row.status === 'refund pending' || row.status === 'refund reject' || row.status === 'finished'
                }).map(t => this.transformToRows(t))
            });
        }
        else {
            this.setState({ sortedRows: this.sortByStatus(order.dataList, status) });
        }
    };

    handleinitiallySortedRows = (rows) => {
        return rows.map((row) => this.transformToRows(row))
    }
    handleViewButton = (returnId) => () => {
        this.props.history.push(`/freereturn/shop/return/${returnId}`)
    }
    transformToRows = (row) => {
        const viewButton = (
            <Button onClick={this.handleViewButton(row.return_id)}>View</Button>
        );
        let badge = <ReturnStatusBadge status={row.status} />

        let temp = [row.return_id, row.order_number, row.customerString, row.createdAt, badge, viewButton]
        return temp
    }

    sortByStatus = (rows, status) => {
        return rows.filter(row => row.status === status).map(t => this.transformToRows(t))
    }

    togglePopover = () => {
        this.setState(({ active }) => {
            return { active: !active };
        });
    };

    handleChange = () => {
        return (value) => {
            this.setState({ 'formValue': value });
        }
    };

    searchByForm = (rows, formValue) => {
        let ret = []
        rows.forEach((row) => {
            if (row.return_id === formValue || row.order_number === formValue) {
                ret.push(this.transformToRows(row))
            }
        })
        return ret
    }

    handleSubmit = (event) => {
        const { order } = this.props;
        let formValue = this.state.formValue
        formValue = formValue.trim()
        if (formValue === '') {
            this.setState({ sortedRows: null });
        } else {
            this.setState({ sortedRows: this.searchByForm(order.dataList, formValue) });
        }
    };

    render() {
        log(this.props)
        const { order } = this.props;
        const { formValue } = this.state;
        const activator = (
            <Button onClick={this.togglePopover}>Filter by return status</Button>
        );
        const { sortedRows } = this.state;
        const initiallySortedRows = this.handleinitiallySortedRows(order.dataList)
        const rows = sortedRows ? sortedRows : initiallySortedRows;
        let dataComponent = null
        if (!order.dataList || order.dataList.length === 0 || order.dataList[0].isSkeleton) {
            dataComponent = (
                <div style={{ padding: 40, backgroundColor: "#f4f6f8", color: "#999999" }}>
                    <div style={{ fontSize: 30, margin: 20 }}>
                        You do not have return orders at the moment.
                            </div>
                    <div style={{ margin: 20 }}>
                        Note: Please select your first products to offer free return insurance if you have not done so.
                        {' '}<a onClick={() => this.props.tabContext.toggleShowHint()} style={{ color: "#3834c8", cursor: "pointer", textDecoration: "underline" }}>Get Started!</a>
                    </div>
                </div>
            )
        }
        else {
            dataComponent = (
                <Card>
                    <DataTable
                        columnContentTypes={[
                            'text',
                            'text',
                            'text',
                            'text',
                            'text',
                            'text',
                        ]}
                        headings={[
                            'Return ID',
                            'Order ID',
                            'Name',
                            'Purchase date',
                            'Return Status',
                            '',
                        ]}
                        rows={rows}
                        sortable={[true, false, false, false, false, false]}
                        defaultSortDirection="descending"
                        initialSortColumnIndex={0}
                        onSort={this.handleSortByOrder(rows)}
                    />

                </Card>
            )
        }
        return (
            <div style={{ padding: '0px 40px' }}>
                <Grid container
                    alignItems="center"
                    style={{
                        margin: '5px 0px'
                    }}>
                    <Grid item style={{ marginRight: 20 }}>
                        <span style={{ fontSize: 24, fontWeight: 700, color: DEFAULT_PRIMARY_COLOR }}>Return Orders</span>
                    </Grid>
                    <Grid item style={{ flex: '1 1' }}>
                        <Form onSubmit={this.handleSubmit}>
                            <FormLayout>
                                <TextField
                                    placeholder="Search by order ID or return ID, please press enter to search"
                                    value={formValue}
                                    suffix={<Icon source="search" />}
                                    onChange={this.handleChange()}
                                />
                            </FormLayout>
                        </Form>
                    </Grid>
                </Grid>
                <Grid container
                    alignItems="center"
                    style={{
                        margin: '15px 0px'
                    }}>
                    <Popover
                        active={this.state.active}
                        activator={activator}
                        onClose={this.togglePopover}
                    >
                        <ActionList
                            items={[
                                {
                                    content: 'Get all',
                                    onAction: () => {
                                        this.handleSortByStatus('');
                                    },
                                },
                                {
                                    content: 'Pending approval',
                                    onAction: () => {
                                        this.handleSortByStatus('created');
                                    },
                                },
                                {
                                    content: 'Approved',
                                    onAction: () => {
                                        this.handleSortByStatus('approved');
                                    },
                                },
                                {
                                    content: 'Rejected',
                                    onAction: () => {
                                        this.handleSortByStatus('rejected');
                                    },
                                }
                            ]}
                        />
                    </Popover>
                </Grid>
                {dataComponent}
            </div>
        );
    }
}

function mapStateToProps({ shopSystem: { order } }) {
    return { order };
}
const mapDispatchToProps = dispatch => bindActionCreators({
    getOrders
}, dispatch)
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(withTabContext(Order)));
