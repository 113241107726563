
import React from 'react'
import {
    Button,
    Icon,
    Card,
    DataTable
} from "@shopify/polaris";
import {
    Grid, Dialog, Slide
} from '@material-ui/core';

function Transition(props) {
    return <Slide direction="right" {...props} />;
}

const ReadyListDialog = ({ data, open, onClose, selected, onNext }) => (
    <Dialog
        fullScreen
        open={open}
        onClose={onClose}
        TransitionComponent={Transition}
    >
        <div style={{ padding: "20px" }}>
            <Grid
                container
                direction="row"
                justify="space-between"
                alignItems="center"
                spacing={16}
                style={{ padding: 10 }}
            >
                <Grid item sm={3}>
                    <Button onClick={onClose} icon={<Icon source="arrowLeft" />} />
                </Grid>
                <Grid item>
                    <Button
                        primary
                        onClick={onNext}
                        disabled={Object.keys(selected).length === 0}
                        icon={<Icon source="checkmark" />}
                    />
                </Grid>
            </Grid>
            <Card style={{ padding: 10 }}>
                <DataTable
                    columnContentTypes={[
                        'text',
                        'text',
                        'text',
                        'text',
                        'text',
                        'text',
                        'text',
                    ]}
                    headings={[
                        '',
                        'ID',
                        'Product',
                        'Title',
                        'From',
                        'End',
                        'Status',
                    ]}
                    rows={data}
                />
            </Card>
        </div>
    </Dialog>
)

export default ReadyListDialog