import React from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { Redirect } from 'react-router-dom'

import { withStyles } from '@material-ui/core/styles';
import {
    Typography, CircularProgress, Button,
} from '@material-ui/core'
import log from 'utils/debug'
import { RETURN_FORM_COLOR_PRIMARY, RETURN_FORM_COLOR_ERROR, API_ERROR } from '../../constants'
import ClaimItemList from '../../components/ClaimItemList'
import { postClaim, approveClaim } from '../../actions/claim'
import { logginAction } from '../../actions/order'
import { resetApiStatus } from '../../actions'
import { Object } from 'core-js';

const styles = theme => ({
    main: {
        flex: 1,
        padding: '0 24px'
    },
    orderTitle: {
        padding: '24px 0 8px',
        textAlign: 'center',
    },
    orderTitleSpan: {
        color: RETURN_FORM_COLOR_PRIMARY
    },
    warning: {
        color: 'red',
        textAlign: 'center',
    },
    listBlock: {
        margin: '16px 0'
    },
    submit: {
        paddingBottom: '24px',
        position: 'relative'
    },
    buttonProgress: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -6,
        marginLeft: -6,
    },
    error: {
        color: RETURN_FORM_COLOR_ERROR
    },
});

const minNumPhotosRequired = 2
const photoRequiredMessage = `Upload photos (min ${minNumPhotosRequired} photos per quanitiy)`

class CliamList extends React.Component {
    state = {
        claimRequest: {},
        photoRequiredMessages: {},
        selectMode: true,
        itemsSelected: {},
        buttonLoading: false,
        isClaimDataReady: false,
        error: null,
    }
    static getDerivedStateFromProps(nextProps, prevState) {
        if (!prevState.error && nextProps.api.status === API_ERROR) {
            nextProps.resetApiStatus()
            return {
                error: nextProps.api.errorMsg,
                buttonLoading: false,
            }
        }
    }
    updateClaimState = (id, claimState) => {
        const { claimRequest } = this.state
        if (claimState.quantity > 0) {
            claimRequest[id] = claimState
        } else {
            delete claimRequest[id]
        }
        log('claimreqeust:', claimRequest)
        let photoRequiredMessages = {}
        let isClaimDataReady = true
        // check all items's required fields
        Object.keys(claimRequest).forEach((id) => {
            const cr = claimRequest[id]
            if (cr.reason === "") {
                isClaimDataReady = false
            }
        })
        const items = this.getSelectedItems()
        if (items.length !== Object.keys(claimRequest).length) {
            isClaimDataReady = false
        }
        log('photoRequiredMessages:', photoRequiredMessages)
        this.setState({ claimRequest, isClaimDataReady, photoRequiredMessages })
    }
    handelSubmit = () => {
        const { shop } = this.props.loggedUserState
        const { handleSettingBackAction, order } = this.props
        const { claimRequest } = this.state
        const orderId = order.data.order.order_id
        if (Object.keys(claimRequest).length > 0) {
            this.props.postClaim(claimRequest, orderId, shop)
            .then(res => {
                const data = this.props.order.data
                this.props.approveClaim(data.claim.id, data.order.order_number, data.order.email, data.shop.shop_name)
                .then(resp => {
                    this.props.logginAction({
                        orderId: data.order.order_number,
                        user: data.order.email,
                        shop: data.shop.shop_name
                    })
                    .then(respp => {
                        this.props.history.push('/lazada/return/status')
                    })
                })
            })
            handleSettingBackAction(false)
            this.setState({ buttonLoading: true, error: null })
        }
    }
    handelNextMode = () => {
        const { handleSettingBackAction } = this.props
        const handlePreMode = this.handlePreMode;
        this.setState({ selectMode: false }, () => {
            handleSettingBackAction(true, handlePreMode)
        })
    }
    handlePreMode = () => {
        const { handleSettingBackAction } = this.props
        this.setState({ selectMode: true }, () => {
            handleSettingBackAction(false)
        })
    }
    selectHandle = (item, checked) => {
        const { itemsSelected } = this.state
        if (checked) {
            itemsSelected[item.item_id] = item
        } else {
            delete itemsSelected[item.item_id]
        }
        this.setState({ itemsSelected })
    }

    getSelectedItems = () => {
        const { order } = this.props;
        const { selectMode, itemsSelected } = this.state;
        return selectMode ? (order.data.items || []) : Object.keys(itemsSelected).map(key => itemsSelected[key])
    }

    render() {
        if (this.props.order.afterClaimed) {
            return <Redirect to="/v/return/status" />
        }
        const { classes } = this.props;
        const { order } = this.props;
        const { selectMode, itemsSelected, photoRequiredMessages, error } = this.state;
        log(this.state)
        const items = this.getSelectedItems()
        const hasAnyItems = items.length
        log("ClamiList : ", items)
        const nextDisable = Object.keys(itemsSelected).length === 0
        return (
            <React.Fragment>
                <div className={classes.main}>
                    <div className={classes.orderTitle}>
                        <Typography variant="title" className={classes.orderTitleSpan}>Order ID:{' '}{order.data.order.order_number}</Typography>
                    </div>
                    {!hasAnyItems ? (
                        <Typography variant="body1" className={classes.warning}> Your order has NO free return items. </Typography>
                    ) : (<div>
                        <div className={classes.listBlock}>
                            <ClaimItemList
                                updateClaimState={this.updateClaimState}
                                selectMode={selectMode}
                                selectHandle={this.selectHandle}
                                items={items}
                                photoRequiredMessages={photoRequiredMessages}
                            />
                        </div>
                        <div className={classes.submit}>
                            {selectMode ?
                                (<Button
                                    fullWidth
                                    variant="extendedFab"
                                    color="primary"
                                    onClick={this.handelNextMode}
                                    disabled={nextDisable}
                                >
                                    Next
                                </Button>)
                                : (<React.Fragment>
                                    <Button
                                        fullWidth
                                        variant="extendedFab"
                                        color="primary"
                                        onClick={this.handelSubmit}
                                        disabled={!this.state.isClaimDataReady || this.state.buttonLoading}
                                    >
                                        Submit
                                    </Button>
                                    {this.state.buttonLoading && <CircularProgress size={12} className={classes.buttonProgress} />}
                                    {error && error !== "" && (
                                        <div style={{ marginTop: 20, textAlign: 'center' }}>
                                            <Typography variant="display3" className={classes.error}>{error}</Typography>
                                        </div>
                                    )}
                                </React.Fragment>)
                            }
                        </div>
                    </div>)
                    }
                </div>
            </React.Fragment>
        )
    }
}



const mapDispatchToProps = dispatch => bindActionCreators({
    postClaim,
    approveClaim,
    logginAction,
    resetApiStatus
}, dispatch)

const mapStateToProps = ({ lazadaApp: { order, loggedUserState, api } }) => ({
    order,
    loggedUserState,
    api
})

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(styles)(CliamList))
