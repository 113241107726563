import React from 'react';
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import {
    Spinner,
    Button,
    TextContainer
} from "@shopify/polaris";

import { DEFAULT_PRIMARY_COLOR } from '../constants'
import { WrappedApiStatusDispatch } from '../actions/withAPIStatus'
import { login } from '../actions/index'
import { API_URL } from 'utils/apiUtils'

function mapStateToProps({ InventoryCashbackShopApp: { app } }) {
    return { app };
}

const mapDispatchToProps = dispatch => bindActionCreators({
    WrappedApiStatusDispatch,
    login
}, dispatch)

export default (WrappedComponent) => (
    connect(mapStateToProps, mapDispatchToProps)(withRouter(
        class extends React.Component {
            state = {
                redirectPending: false
            }
            componentDidMount() {
                const searchString = this.props.location.search.slice(1)
                const searchParams = new URLSearchParams(searchString);
                const { app: { logged } } = this.props
                const hmac = searchParams.get('hmac')
                if (!logged) {
                    if (hmac && hmac !== '') {
                        this.props.WrappedApiStatusDispatch(
                            this.props.login(this.props.location.search)
                        )
                    }
                    else {
                        this.setState({
                            redirectPending: true
                        })
                    }
                }
            }
            render() {
                const { app: { logged, shop_info } } = this.props
                const { redirectPending } = this.state
                if (redirectPending) {
                    return (
                        <div>
                            <div style={{ width: '65px', margin: '0 auto', paddingTop: 50 }}>
                                <Spinner size="large" color="teal" />

                            </div>
                            <div style={{ margin: '0 auto', width: 200 }}>
                                <Button
                                    style={{ margin: '0 auto', position: "block", width: 200 }}
                                    onClick={this.handleManuallyRedirect}
                                >
                                    Click here, if page does not redirect in minutes.
                                </Button>
                            </div>
                        </div>
                    )
                }
                if (logged) {
                    if (shop_info && shop_info.is_valid_charge_status === false) {
                        const redirectUrl = `${API_URL}/v1/merchants/auth/servicecharge?shop=${shop_info.shop_name}&insurance=inventorycashback`
                        return (
                            <div style={{ padding: '0px 40px' }}>
                                <TextContainer>
                                    <h6 style={{ paddingTop: 30, paddingBottom: '20px', fontWeight: 'bold', fontSize: '20px', color: DEFAULT_PRIMARY_COLOR }}>You have to approve application charge to continue using our app</h6>
                                </TextContainer>
                                <div>
                                    <Button
                                        style={{ margin: '0 auto', position: "block" }}
                                        onClick={
                                            () => {
                                                window.top.location.href = redirectUrl
                                            }
                                        }
                                    >
                                        Click here to approve
                              </Button>
                                </div>
                            </div>
                        )
                    }
                    return (
                        <WrappedComponent {...this.props} />
                    )
                } else {
                    return (
                        <div />
                    )
                }
            }
        }
    ))
)
