import React from 'react';
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { Redirect } from 'react-router-dom'

import withStyles from '@material-ui/core/styles/withStyles';
import { API_START, API_ERROR } from '../../constants';
import {
    Typography, Grid, Button,
    InputAdornment, TextField,
    CircularProgress, Checkbox, FormControlLabel,
    MenuItem, Select, FormControl
} from '@material-ui/core'
import { createLogisticRefund } from '../../actions/claim'
import { resetApiStatus } from '../../actions'
import AlertDialog from '../../components/AlertDialog'

const styles = theme => ({
    main: {
        flex: '1 1',
        display: 'flex',
        flexDirection: 'column'
    },
    content: {
        flexGrow: 1,
        overflowY: 'auto'
    },
    container: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    textField: {
        marginLeft: theme.spacing.unit,
        marginRight: theme.spacing.unit,
        paddingTop: theme.spacing.unit * 2,
    },
    uploadTitle: {
        display: 'inline-block',
        verticalAlign: 'middle'
    },
    uploadButton: {
        height: '80px',
        backgroundColor: 'rgb(244, 243, 251)'
    },
    uploadButtonIcon: {
        marginRight: '10px'
    },
    input: {
        display: 'none',
    },
    previewImage: {
        width: '100%'
    },
    buttonProgress: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -12,
        marginLeft: -12,
    },
    formControl: {
        minWidth: 250
    }
});


class NinJa extends React.Component {
    state = {
        weight: "",
        volume: "",
        length: "",
        width: "",
        height: "",
        startTime: "07:00",
        endTime: "20:00",
        buttonLoading: false,
        isAPIError: false,
        errorMsg: '',
        isPickUp: false,
        pickUpTime: '09:00-12:00',
        timeOpen: false,
    }
    componentWillReceiveProps(nextProps) {
        if (nextProps.api.status !== this.props.api.status && nextProps.api.status === API_ERROR) {
            const errorMsg = nextProps.api.errorMsg === 'Request Invalid' ? "NinJa van request invalid" : nextProps.api.errorMsg
            this.setState({
                isAPIError: true,
                errorMsg
            })
        }
    }
    renderTextField = (name) => {
        let inputProps = null
        if (name === "weight") {
            inputProps = {
                endAdornment: (
                    <InputAdornment variant="filled" position="end">
                        k.g
                  </InputAdornment>
                ),
            }
        } else if (name === "length" || name === "height" || name === "width") {
            inputProps = {
                endAdornment: (
                    <InputAdornment variant="filled" position="end">
                        cm
                  </InputAdornment>
                ),
            }
        }
        return (
            <TextField
                id={`outlined-${name}-input`}
                label={name}
                name={name}
                margin="dense"
                variant="outlined"
                value={this.state[name]}
                onChange={this.handleTextChange}
                fullWidth
                InputProps={inputProps}
            />
        )
    }
    handleTextChange = event => {
        this.setState({
            [event.target.name]: event.target.value,
        });
    };
    handleTimeChange = event => {
        this.setState({ time: event.target.value })
    }
    submiteAvailable = () => {
        const { weight, volume, length, width, height } = this.state
        return weight === "" || volume === "" || length === "" || width === "" || height === ""
    }
    handleSubmit = () => {
        const { claim } = this.props
        const { weight, volume, length, width, height, pickUpTime, isPickUp } = this.state
        const dimensions = {
            weight: parseFloat(weight),
            volume: parseFloat(volume),
            length: parseFloat(length),
            width: parseFloat(width),
            height: parseFloat(height),
            size: "small",
        }
        const timeToken = pickUpTime.split("-")
        const timeslot = {
            start_time: timeToken[0],
            end_time: timeToken[1]
        }
        const data = {
            dimensions,
            timeslot,
            sender: {
                ...claim.senderInfo,
                address1: claim.senderInfo.address,
                postcode: claim.senderInfo.zip,
            },
            receiver: {
                ...claim.receiverInfo,
                address1: claim.receiverInfo.address,
                postcode: claim.receiverInfo.zip,
            },
            is_pickup: isPickUp
        }
        if (this.checkDimensions(dimensions)) {
            this.props.createLogisticRefund(this.props.order.data.claim.id, data, "ninjavan")
        } else {
            this.setState({
                isAPIError: true,
                errorMsg: "Sorry, parcels up to 5kg or cumulative cubic dimensions of 80cm (Length + Width + Height) will be accepted via the Ninja"
            })
        }
    }
    handleClose = () => {
        this.props.resetApiStatus()
        this.setState({
            isAPIError: false,
            errorMsg: ""
        })
    }
    handleIsPickUpChange = (event) => {
        this.setState({ isPickUp: event.target.checked })
    }
    handleTimeClose = () => {
        this.setState({ timeOpen: false })
    }
    handleTimeOpen = () => {
        this.setState({ timeOpen: true })
    }
    checkDimensions = (dimensions) => {
        if (dimensions.weight > 5) {
            return false
        }
        if (dimensions.length + dimensions.width + dimensions.height > 80) {
            return false
        }
        return true
    }
    render() {
        const { classes, order } = this.props
        const { isAPIError, errorMsg } = this.state
        if (order.afterClaimed) {
            return <Redirect to='/freereturn/return/status' />
        }
        return (
            <React.Fragment>
                <div className={classes.main}>
                    <div className={classes.content}>
                        <div style={{ margin: '20px 0px', padding: '0px 10px' }}>
                            <Typography variant="display2">Fill in package details</Typography>
                            <Grid container spacing={8} style={{ margin: '10px 0px' }}>
                                <Grid item xs={6} sm={4}>
                                    {this.renderTextField("length")}
                                </Grid>
                                <Grid item xs={6} sm={4}>
                                    {this.renderTextField("width")}
                                </Grid>
                                <Grid item xs={6} sm={4}>
                                    {this.renderTextField("height")}
                                </Grid>
                                <Grid item xs={6} sm={4}>
                                    {this.renderTextField("weight")}
                                </Grid>
                                <Grid item xs={6} sm={4}>
                                    {this.renderTextField("volume")}
                                </Grid>
                            </Grid>
                        </div>
                        <div style={{ margin: '20px 0px', padding: '0px 10px' }}>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={this.state.isPickUp}
                                        onChange={this.handleIsPickUpChange}
                                        value="isPickUp"
                                        color="primary"
                                    />
                                }
                                label="Need picking up service?"
                            />
                            {this.state.isPickUp && (
                                <Grid container spacing={8} style={{ margin: '10px 0px' }} alignItems="center">
                                    <Grid item>
                                        <Typography variant="display1">Select your pick up time</Typography>
                                        <FormControl className={classes.formControl}>
                                            <Select
                                                open={this.state.timeOpen}
                                                onClose={this.handleTimeClose}
                                                onOpen={this.handleTimeOpen}
                                                value={this.state.pickUpTime}
                                                onChange={this.handleTextChange}
                                                name="pickUpTime"
                                                inputProps={{
                                                    name: 'pickUpTime',
                                                    id: 'demo-time-open-select',
                                                }}
                                            >
                                                <MenuItem value={"09:00-12:00"}>09:00-12:00</MenuItem>
                                                <MenuItem value={"09:00-18:00"}>09:00-18:00</MenuItem>
                                                <MenuItem value={"09:00-22:00"}>09:00-22:00</MenuItem>
                                                <MenuItem value={"12:00-15:00"}>12:00-15:00</MenuItem>
                                                <MenuItem value={"15:00-18:00"}>15:00-18:00</MenuItem>
                                                <MenuItem value={"18:00-22:00"}>18:00-22:00</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                </Grid>
                            )}
                        </div>
                    </div>
                    <div style={{ padding: 12, boxShadow: '0px -3px 10px #dddddd', zIndex: 10 }}>
                        <Button fullWidth variant="extendedFab" color="primary" disabled={this.submiteAvailable()} onClick={this.handleSubmit}>
                            Submit
                        </Button>
                        {this.props.api.status === API_START && <CircularProgress size={24} className={classes.buttonProgress} />}
                    </div>
                    <AlertDialog open={isAPIError} handleClose={this.handleClose}>
                        <div style={{ padding: 10 }}>{errorMsg}</div>
                    </AlertDialog>
                </div>
            </React.Fragment >
        );
    }
}

const mapStateToProps = ({ lazadaApp: { order, tempStatus, api, claim } }) => ({
    order,
    tempStatus,
    api,
    claim
})
const mapDispatchToProps = dispatch => bindActionCreators({
    createLogisticRefund,
    resetApiStatus
}, dispatch)

export default connect(
    mapStateToProps, mapDispatchToProps
)(withStyles(styles)(NinJa))
