import { FILL_IN_RETURN_FORM } from '../constants'

const initialState = {
    returnForm: {

    }
}
export default (state = initialState, action) => {
    switch (action.type) {
        case FILL_IN_RETURN_FORM:
            return {
                ...state,
                returnForm: action.payload.data
            };
        default:
            return state
    }
}