import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux'
import {
    withRouter
} from 'react-router-dom'
import {
    Button,
    SkeletonBodyText,
    TextField,
} from "@shopify/polaris";
import {
    Grid, Dialog, DialogContent, DialogActions
} from '@material-ui/core'
import moment from 'moment'
import { DEFAULT_PRIMARY_COLOR, API_DONE, DATE_FORMAT_DEFAULT } from '../../constants'
import ReturnStatusBadge from '../../components/ReturnStatusBadge'
import ReturnItem from '../../components/ReturnItem'
import ConfirmDialog from '../../components/ConfirmDialog'
import { getClaim, updateClaim } from '../../actions/Claim'
import { resetApiStatus } from '../../actions'

class ReturnDetail extends Component {
    state = {
        approveActive: false,
        rejectActive: false,
        rejectReason: '',
        apiStatus: null,
        showImages: [],
        openImageModal: false
    }
    componentWillReceiveProps(nextProps) {
        if (this.state.apiStatus === 'loading') {
            const { api } = nextProps
            if (api.status !== this.props.api.status && api.status === API_DONE) {
                this.setState({ apiStatus: null, approveActive: false, rejectActive: false, rejectReason: '' }, () => {
                    this.props.resetApiStatus()
                    this.props.getClaim(this.props.match.params.id)
                })
            }
        }
    }
    getDataById = (dataList, id) => {
        for (var i = 0, l = dataList.length; i < l; i++) {
            if (dataList[i].return_id === id) {
                return dataList[i]
            }
        }
        return null
    }
    genAddressByObject = ({ Address1, Address2, City, Country, Zip }) => {
        return `${Address1} ${Address2}, ${Country}`
    }
    componentDidMount() {
        const { claim: { data } } = this.props
        if (!data || data.claim.id !== this.props.match.params.id) {
            this.props.getClaim(this.props.match.params.id)
        }
    }
    handleRejcetAction = () => {
        const { match: { params: { id } }, updateClaim } = this.props
        const { rejectReason } = this.state
        this.setState({ rejectReason: '', apiStatus: 'loading' }, () => {
            updateClaim('reject', id, rejectReason)
        })
    }
    handleApproveAction = () => {
        const { match: { params: { id } }, updateClaim } = this.props
        this.setState({ apiStatus: 'loading' }, () => {
            updateClaim('approve', id)
        })
    }
    handleRejectTextChange = value => {
        this.setState({
            rejectReason: value
        });
    };
    handleRejectOpen = () => {
        this.setState({ rejectActive: true })
    }
    handleRejectClose = () => {
        this.setState({ rejectActive: false })
    }
    handleApproveOpen = () => {
        this.setState({ approveActive: true })
    }
    handleApproveClose = () => {
        this.setState({ approveActive: false })
    }

    handleBackAction = () => {
        this.props.history.goBack()
    }
    onClickImage = (images) => () => {
        this.setState({
            showImages: images,
            openImageModal: true
        })
    }
    handleImageModalClose = () => {
        this.setState({
            openImageModal: false,
        }, () => {
            this.setState({
                showImages: []
            })
        })
    }
    render() {
        const {
            claim: { data },
            order: { dataList },
            match: {
                params: {
                    id
                }
            }
        } = this.props
        if (!data || data.claim.id !== id) {
            return <div style={{ padding: 24 }}><SkeletonBodyText /><br /><br /><SkeletonBodyText /></div>
        }
        const returnData = this.getDataById(dataList, id)
        console.log('dataList: ', dataList)
        console.log('id: ', id)
        console.log('returnData: ', returnData)
        const shippingAddressObject = (returnData.shipping_address !== '') ? JSON.parse(returnData.shipping_address) : null
        const billingAddressObject = (returnData.billing_address !== '') ? JSON.parse(returnData.billing_address) : null
        let address = ''
        if (shippingAddressObject != null || billingAddressObject != null) {
            address = !shippingAddressObject.Address1 && !shippingAddressObject.Address2 ?
            this.genAddressByObject(billingAddressObject) :
            this.genAddressByObject(shippingAddressObject)
        }
        const itemsDictionary = {}
        data.items.forEach(item => {
            itemsDictionary[item.item_id] = item
        })
        const { claim: { parcel, payout } } = data
        const returnDetailObject = parcel ? {
            date: parcel.created_at ? moment(parcel.created_at).format(DATE_FORMAT_DEFAULT) : "-",
            courier: parcel.courier || "-",
            trackingId: parcel.shipping.tracking_id || "-",
            status: parcel.shipping.last_shipping_status || "-",
        } : null
        const currency = (payout && payout.currency) || (returnData && returnData.currency) || "-"
        return (
            <div style={{ padding: 24 }}>
                <div>
                    <Button plain icon="arrowLeft" onClick={this.handleBackAction} />
                </div>
                <div style={{ padding: 15 }}>
                    <div>
                        <Grid container spacing={8}>
                            <Grid item>
                                <span style={{ fontSize: 20, fontWeight: 700 }}>Return ID: {returnData.return_id}</span>
                            </Grid>
                            <Grid><ReturnStatusBadge status={data.claim.status} /> </Grid>
                        </Grid>
                        <div>
                            <span style={{ fontSize: 14, color: '#aaaaaa' }}> Order ID: {returnData.order_id} </span>
                        </div>
                    </div>
                    <Grid container style={{ marginTop: 20 }}>
                        <Grid item xs={6} sm={6}>
                            <div><span style={{ fontSize: 14, fontWeight: 700, color: DEFAULT_PRIMARY_COLOR }}>
                                Return details
                        </span></div>
                            <div><span>Return date: {returnDetailObject ? returnDetailObject.date : "-"}</span></div>
                            <div><span>Currency: {currency}</span></div>
                            <div><span>Courier: {returnDetailObject ? returnDetailObject.courier : "-"}</span></div>
                            <div><span>Tracking status: {returnDetailObject ? returnDetailObject.status : "-"}</span></div>
                            <div><span>Tracking ID: {returnDetailObject ? returnDetailObject.trackingId : "-"}</span></div>
                        </Grid>
                        <Grid item xs={6} sm={6}>
                            <div><span style={{ fontSize: 14, fontWeight: 700, color: DEFAULT_PRIMARY_COLOR }}>
                                Customer details
                        </span></div>
                            <div>{returnData.customerString}</div>
                            <div>{returnData.email}</div>
                            <div>{address}</div>
                        </Grid>
                    </Grid>
                    <div style={{ marginTop: 15 }} >
                        <Grid container direction="row"
                            justify="flex-start"
                            alignItems="center"
                            style={{ padding: '10px 25px', marginBottom: '5px', marginTop: '10px', fontWeight: 700 }}>
                            <Grid item xs={12} sm={4}><span>Product</span></Grid>
                            <Grid item xs={4} sm={1}><span>Price</span></Grid>
                            <Grid item xs={4} sm={1}><span>Premium</span></Grid>
                            <Grid item xs={4} sm={2}><span>Return quantity</span></Grid>
                            <Grid item xs={6} sm={2}><span>Return reasons</span></Grid>
                            <Grid item xs={6} sm={2}><span>Return photos</span></Grid>
                        </Grid>
                        {data.claim.items.map(item => {
                            return (<ReturnItem item={item} key={`return-item-${item.itemId}`} lineItem={itemsDictionary[item.itemId]} onClickImage={this.onClickImage} />)
                        })}
                    </div>
                    <div style={{ marginTop: 20, textAlign: 'right', padding: 20 }}>
                        {data.claim.status === 'Pending Approval' || data.claim.status === 'created' ?
                            (
                                <React.Fragment>
                                    <div style={{ display: 'inline-block', padding: 10 }}><Button destructive onClick={this.handleRejectOpen}>Reject</Button></div>
                                    <div style={{ display: 'inline-block', padding: 10 }}><Button primary onClick={this.handleApproveOpen}>Approve</Button></div>
                                </React.Fragment>
                            )
                            : null
                        }
                    </div>
                </div>
                <ConfirmDialog open={this.state.approveActive} handleClose={this.handleApproveClose} handleConfirm={this.handleApproveAction}>
                    <h2>Are you sure to approve this claim</h2>
                </ConfirmDialog>
                <ConfirmDialog
                    open={this.state.rejectActive}
                    handleClose={this.handleRejectClose}
                    handleConfirm={this.handleRejcetAction}
                    yesText={"Confirm"}
                    noText={"Cancel"}
                >
                    <h2>Please describe reject reason</h2>
                    <TextField
                        id="reject-reason"
                        label="reason"
                        name="rejectReason"
                        margin="dense"
                        variant="outlined"
                        onChange={this.handleRejectTextChange}
                        value={this.state.rejectReason}
                        fullWidth
                    />
                </ConfirmDialog>
                <Dialog
                    open={this.state.openImageModal}
                    onClose={this.handleImageModalClose}
                >
                    <DialogContent>
                        <div><span style={{ fontSize: 14, fontWeight: 700, color: DEFAULT_PRIMARY_COLOR }}>
                            Return photos:
                        </span></div>
                        {this.state.showImages.length > 0 &&
                            this.state.showImages.map(image => {
                                return <img src={image} style={{ width: '90%', padding: '20px', margin: '20px' }} alt='' />
                            })
                        }
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.handleImageModalClose} >
                            Close
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>
        )
    }
}

function mapStateToProps({ shopSystem: { order, claim, api } }) {
    return { order, claim, api };
}
const mapDispatchToProps = dispatch => bindActionCreators({
    getClaim,
    updateClaim,
    resetApiStatus
}, dispatch)
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(ReturnDetail));