import { GET_INVOICE_DONE, DATE_FORMAT_DEFAULT } from '../constants';
import moment from 'moment'


const columns = {
    order_id: "Order ID",
    createdAt: "Date",
    premium_price: "Service Fee",
    isHeader: true
}

const parseInvoice = (data) => {
    let result = []
    if (data && data.length > 0) {
        result = data.map(element => {
            const timeString = moment(element.created_at).format(DATE_FORMAT_DEFAULT);
            return (
                Object.assign({}, element,
                    {
                        order_id: element.order_id.slice(1),
                        createdAt: timeString
                    }));
        })
    }
    return result;
}

const initialState = {
    dataList: [{
        id: 'Skeleton-123',
        isSkeleton: true
    }],
    columns: columns
}

function invoice(state = initialState, action) {
    switch (action.type) {
        case GET_INVOICE_DONE:
            const invoice = parseInvoice(action.payload.json.data);
            return {
                ...state,
                shopAddress: action.payload.json.shop_address,
                totalPrice: action.payload.json.total_price,
                dataList: invoice,
                invoiceNumber: action.payload.json.invoice_number,
            };
        default:
            return state
    }
}

export default invoice
