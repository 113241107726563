import React from 'react';
import { compose } from 'recompose';
import { Switch, Route } from 'react-router-dom'

import WithLayout from './components/Layout'
import Products from './containers/Products'
import Policies from './containers/Policies'
import Claims from './containers/Claims'
import Faq from './containers/Faq'
import Tutorial from './containers/Tutorial'

import WithShopify from './hoc/withShopifyConfig'
import WithApiStatus from './hoc/withApiStatus'
import WithAuth from './hoc/withAuth'
import WithTutorial from './hoc/withTutorial'
import WithInstall from 'common/hoc/withInstall'

const withLayout = selected => compose(WithApiStatus, WithAuth, WithTutorial, WithLayout(selected))

const App = () => {
    return (
        <Switch>
            <Route exact path="/inventorycashback" component={WithInstall("inventorycashback")} />
            <PathEnhance />
        </Switch>
    )
}

const Path = () => {
    return (
        <Switch>
            <Route exact path="/inventorycashback/products" component={withLayout(1)(Products)} />
            <Route exact path="/inventorycashback/policies" component={withLayout(2)(Policies)} />
            <Route exact path="/inventorycashback/claims" component={withLayout(3)(Claims)} />
            <Route exact path="/inventorycashback/faq" component={withLayout(4)(Faq)} />
            <Route exact path="/inventorycashback/tutorial" component={withLayout(5)(Tutorial)} />
        </Switch>
    )
}
const PathEnhance = WithShopify(Path)

export default App;
