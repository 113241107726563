const initialState = {
    dataList: [
        {
            name: "data1",
            data: [
                {
                    name: '1月',
                    salesWithFreeReturn: 4000,
                    salesWithOutFreeReturn: 2400
                }, {
                    name: '2月',
                    salesWithFreeReturn: 3000,
                    salesWithOutFreeReturn: 1398
                }, {
                    name: '3月',
                    salesWithFreeReturn: 7000,
                    salesWithOutFreeReturn: 9800
                }, {
                    name: '4月',
                    salesWithFreeReturn: 2780,
                    salesWithOutFreeReturn: 3908
                }, {
                    name: '5月',
                    salesWithFreeReturn: 1890,
                    salesWithOutFreeReturn: 4800
                }, {
                    name: '6月',
                    salesWithFreeReturn: 2390,
                    salesWithOutFreeReturn: 3800
                }, {
                    name: '7月',
                    salesWithFreeReturn: 3490,
                    salesWithOutFreeReturn: 4300
                }
            ]
        }, {
            name: "data2",
            data: [
                {
                    name: 'Product 1',
                    orderWithFreeReturn: 4000,
                    orderNormal: 2400
                }, {
                    name: 'Product 2',
                    orderWithFreeReturn: 6000,
                    orderNormal: 3400
                }, {
                    name: 'Product 3',
                    orderWithFreeReturn: 4100,
                    orderNormal: 3900
                }
            ]
        }
    ]
};

function dashboard(state = initialState, action) {
    switch (action.type) {
        default:
            return state
    }
}

export default dashboard