import React from 'react'
import {
    Badge,
} from "@shopify/polaris";
const PolicyStatus = ({ status }) => {
    if (status == "created") {
        return (
            <Badge status="info">PENDING APPROVAL</Badge>
        )
    }
    if (status == "approved") {
        return (
            <Badge status="success">PROTECED</Badge>
        )
    }
    if (status == "ready") {
        return (
            <Badge status="warning">READY TO CLAIM</Badge>
        )
    }
    if (status == "claimed") {
        return (
            <Badge status="attention">POLICY CLAIMED</Badge>
        )
    }
    if (status == "inactive") {
        return (
            <Badge progress="incomplete">INACTIVE</Badge>
        )
    }
    if (status == "rejected") {
        return (
            <Badge progress="incomplete">REJECTED</Badge>
        )
    }
    if (status == "expired") {
        return (
            <Badge progress="incomplete">EXPIRED</Badge>
        )
    }
    return (
        <Badge status="info">PENDING APPROVAL</Badge>
    )
}

export default PolicyStatus