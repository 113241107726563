import { GET_CLAIM_BY_ID_DONE } from '../constants';

const initialState = {
    data: null
}

function order(state = initialState, action) {
    switch (action.type) {
        case GET_CLAIM_BY_ID_DONE:
            return {
                ...state,
                data: action.payload.data,
            };
        default:
            return state
    }
}

export default order
