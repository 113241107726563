import React, { Component } from 'react'
import * as Sentry from '@sentry/browser';



function withSentry(WrappedComponent) {
    return (
        class extends Component {
            componentDidMount() {
                Sentry.init({ dsn: 'https://a4671c44837a44b9ad43c665b2633d7a@sentry.io/1355653' });
            }
            componentDidCatch(error, errorInfo) {
                Sentry.withScope(scope => {
                    Object.keys(errorInfo).forEach(key => {
                        scope.setExtra(key, errorInfo[key]);
                    });
                    Sentry.captureException(error);
                });
            }

            render() {
                return (
                    <WrappedComponent {...this.props} />
                )
            }
        }
    )
}


export default withSentry