import { combineReducers } from 'redux'

import app from './app';
import dashboard from './dashboard';
import product from './product';
import order from './order';
import invoice from './invoice';
import claim from './claim'
import api from './api'

export default combineReducers({
    app,
    dashboard,
    product,
    order,
    invoice,
    claim,
    api
})
