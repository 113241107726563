
import React from 'react'
import { compose, withState, withHandlers, withProps } from 'recompose'
import {
    Button,
    TextField,
} from "@shopify/polaris";
import {
    Grid, Dialog, Slide
} from '@material-ui/core';

function Transition(props) {
    return <Slide direction="up" {...props} />;
}

const TextInputDialog = ({ open, onClose, valueMap, onSubmit, onChange }) => (
    <Dialog
        open={open}
        onClose={onClose}
        TransitionComponent={Transition}
    >
        <div style={{ padding: "20px" }}>
            <Grid container spacing={16} style={{ margin: "20px 0px" }}>
                {valueMap && Object.keys(valueMap).map(key => {
                    const o = valueMap[key]
                    return (<Grid sm={12} item>
                        <TextField
                            key={`TextInputDialog_${key}`}
                            label={o.label}
                            value={o.value}
                            onChange={(value) => { onChange(key, value) }}
                        />
                    </Grid>)
                })}

            </Grid>
            <Grid container
                direction="row"
                justify="flex-end"
                spacing={16}
                style={{ padding: "10px" }}
            >
                <Grid item>
                    <Button onClick={onSubmit} primary>Submit</Button>
                </Grid>
            </Grid>
        </div>
    </Dialog>
)


const enhance = compose(
    withState('stateMap', 'onChange', {}),
    withHandlers({
        onChange: ({ onChange, stateMap }) => (key, value) => {
            stateMap[key] = value
            onChange(stateMap)
        },
        onSubmit: ({ onChange, submitAction, stateMap, valueMap, staticData }) => () => {
            const defaultMap = {}
            if (valueMap) {
                valueMap.forEach(v => {
                    defaultMap[v.key] = v.value || ""
                })
            }
            submitAction({ ...defaultMap, ...stateMap }, staticData)
            onChange({})
        },
        onClose: ({ onChange, onClose }) => () => {
            onChange({}, onClose())
        },
    }),
    withProps(props => {
        const { valueMap, stateMap } = props
        const newMap = {}
        if (valueMap) {
            valueMap.forEach(v => {
                let value = v.value || ""
                if (stateMap[v.key] || stateMap[v.key] === "") {
                    value = stateMap[v.key]
                }
                newMap[v.key] = {
                    label: v.label,
                    value
                }
            })
        }
        return {
            valueMap: newMap
        }
    }),
)


export default enhance(TextInputDialog)