import axFetch from 'utils/fetch'
import { DefaultHeader } from 'utils/apiUtils'
import { API_RESET_STATUS, LOGIN_ACTION_DONE } from '../constants'

export function login(params) {
    const fetchOptions = {
        method: 'GET',
        headers: {
            ...DefaultHeader
        },
    }
    return dispatch => {
        return axFetch(`/v1/merchants/auth/hmaclogin${params}&insurance=inventorycashback`, fetchOptions)
            .then(json => dispatch(handleLoginDoneAction(json)));
    };

}
function handleLoginDoneAction(json) {
    return {
        type: LOGIN_ACTION_DONE,
        payload: {
            ...json
        }
    }
}

export function resetApiStatus() {
    return dispatch => {
        dispatch({ type: API_RESET_STATUS })
    };
}
