import React from 'react'
import {
    Typography, Grid, Checkbox,
    Select, OutlinedInput, FormControl,
    InputLabel, MenuItem, Divider, ButtonBase,
    TextField
} from '@material-ui/core';
import withStyles from '@material-ui/core/styles/withStyles';
import log from 'utils/debug'

import classNames from 'classnames';
import { RETURN_FORM_COLOR_SECONDARY, RETURN_FORM_COLOR_GREY, RETURN_FORM_COLOR_PRIMARY } from '../../constants'
import UploadPhotoList from '../UploadPhotoList'

const OTHER_REASON = "Others(Please specify in additional notes)"

const styles = theme => ({
    main: {
        marginBottom: '16px',
        minHeight: '140px'
    },
    mainSelecMode: {
        minHeight: '100px'
    },
    mainBlock: {
        marginBottom: '8px',
    },
    dateBlock: {
        textAlign: 'right'
    },
    dateText: {
        color: RETURN_FORM_COLOR_GREY,
    },
    centerLine: {
        height: '100%',
        position: 'absolute',
        borderLeft: '1px solid #dddddd',
    },
    centerLineTail: {
        height: '0%',
    },
    iconBlock: {
        margin: '0px auto',
        width: '0px',
        height: '100%',
        position: 'relative',
    },
    buttonOuterBlock: {
        position: 'absolute',
        marginLeft: '-40px',
        marginTop: '-30px',
        backgroundColor: 'rgba(255, 211, 219, 0.3)',
        width: '80px',
        height: '80px',
        borderRadius: '40px'
    },
    buttonBlock: {
        backgroundColor: 'rgb(255, 211, 219)',
        width: '60px',
        height: '60px',
        borderRadius: '30px',
        position: 'absolute',
        left: '10px',
        top: '10px',
    },
    buttonClearBlock: {
        backgroundColor: 'transparent'
    },
    button: {
        position: 'relative',
        left: '10px',
        top: '10px',
    },
    emptyIconBlock: {
        width: '20px',
        height: '20px',
        border: '2px solid #dddddd',
        backgroundColor: 'white',
        borderRadius: '10px',
        position: 'relative',
        left: '20px',
        top: '20px',
    },
    statusTextDefault: {
        color: RETURN_FORM_COLOR_GREY,
        opacity: 0.5,
    },
    statusTextActived: {
        color: RETURN_FORM_COLOR_SECONDARY,
        opacity: 1,
    },
    image: {
        width: '100%'
    },
    selectorQuantity: {
        textAlign: 'right'
    },
    uploadBlock: {
        margin: '16px 0'
    },
    textField: {
        margin: '4px 0'
    },
    uploadTitle: {
        display: 'inline-block',
        verticalAlign: 'middle'
    },
    variantText: {
        color: RETURN_FORM_COLOR_GREY
    },
    reasonTitle: {
        color: RETURN_FORM_COLOR_PRIMARY
    },
    reasonBlock: {
        marginBottom: 8
    }
})
class ClaimItem extends React.Component {
    state = {
        quantity: 1,
        labelWidth: 4,
        reason: '',
        imgList: [],
        checked: false,
        note: '',
    };
    componentDidMount() {
    }
    handeUpdateState = () => {
        const { updateClaimState, item } = this.props;
        if (updateClaimState) {
            const newState = { ...this.state }
            if (this.state.note && this.state.note !== '') {
                newState['reason'] = `Other: ${this.state.note}`
            }
            updateClaimState(item.item_id, { ...newState, productId: item.shopify_product_id, itemId: item.item_id })
        }
    }

    handleImageChange = (e) => {
        // Assuming only image
        e.preventDefault();

        let reader = new FileReader();
        let file = e.target.files[0];

        reader.onloadend = () => {
            const { imgList } = this.state
            imgList.push({
                file: file,
                imgSrc: reader.result
            })
            this.setState({
                imgList
            }, () => {
                this.handeUpdateState()
            });
        }

        reader.readAsDataURL(file)
    }
    handleRemovePhoto = () => {
        this.setState({
            imgList: []
        }, () => {
            this.handeUpdateState()
        });
    }
    handleQuantityChange = event => {
        this.setState({ quantity: event.target.value }, () => {
            this.handeUpdateState()
        });
    };
    handleReason = event => {
        this.setState({ reason: event.target.value }, () => {
            this.handeUpdateState()
        });
    }
    handleCheckChange = (event, checked) => {
        log(checked)
        const { selectHandle, item } = this.props
        this.setState({ checked }, () => {
            if (selectHandle) {
                selectHandle(item, checked)
            }
        })
    }
    genOptions = (options) => {
        return options.map(o => {
            const json = JSON.parse(o)
            return json["Name"]
        })
    }
    handleNoteChange = (e) => {
        this.setState({
            note: e.target.value
        }, () => {
            this.handeUpdateState()
        })
    }
    render() {
        const { item, classes, selectMode, photoRequiredMessages } = this.props;
        log('claimitemlist, photoRequiredMessages:', photoRequiredMessages)
        // const imageJson = JSON.parse(item.images[0])
        const imgSrc = (item.images && item.images.length > 0 && JSON.parse(item.images[0]).Src) || ""
        const variantTitle = item.variant_title.split("/")
        const options = this.genOptions(item.options)
        const variantTitleArray = variantTitle.map((v, i) => {
            return !!options[i] ? options[i] + ": " + v : v
        })
        const variantText = variantTitleArray.join(" | ")
        const quantityOptions = [];
        for (var i = 1; i <= item.quantity; i++) {
            quantityOptions.push(<MenuItem value={i} key={item.title + variantText + i}>{i}</MenuItem>)
        }
        const mainClassArray = selectMode ? [classes.main, classes.mainSelecMode] : [classes.main]
        const { reason } = this.state

        return (
            <div className={classNames(mainClassArray)}>
                <Grid container className={classes.mainBlock} spacing={8}>
                    <Grid item xs={3} sm={3} className={classes.dateBlock}>
                        <img src={imgSrc} className={classes.image} alt='' />
                    </Grid>
                    <Grid item xs={6} sm={6}>
                        <div><Typography variant="display4">{item.title}</Typography></div>
                        <div><Typography variant="display1" className={classes.variantText}>{variantText}</Typography></div>
                    </Grid>
                    <Grid item xs={3} sm={3} className={classes.selectorQuantity}>
                        {selectMode ?
                            (<Checkbox
                                checked={this.state.checked}
                                onChange={this.handleCheckChange}
                                value="checkedB"
                                color="primary"
                            />)
                            : (<Select
                                type="number"
                                value={this.state.quantity}
                                onChange={this.handleQuantityChange}
                                input={
                                    <OutlinedInput
                                        name="quantity"
                                        labelWidth={this.state.labelWidth}
                                        id="outlined-quantity-native-"
                                    />
                                }
                            >
                                {quantityOptions}
                            </Select>)
                        }
                    </Grid>
                </Grid>
                {selectMode ?
                    null
                    :
                    (<React.Fragment>

                        <div>
                            <div className={classes.reasonBlock}><Typography variant="display2" className={classes.reasonTitle}>Return reason</Typography></div>
                            <div>
                                <FormControl fullWidth error={reason ? false : true} >
                                    <InputLabel htmlFor="outlined-reason-native">Choose your reason</InputLabel>
                                    <Select
                                        displayEmpty
                                        value={this.state.reason}
                                        onChange={this.handleReason}
                                        input={
                                            <OutlinedInput
                                                name="reason"
                                                labelWidth={this.state.labelWidth}
                                                id="outlined-reason-native"
                                            />
                                        }
                                    >
                                        <MenuItem value="Looks different from image on site" >{"Looks different from image on site"}</MenuItem>
                                        <MenuItem value="Arrived too late" >{"Arrived too late"}</MenuItem>
                                        <MenuItem value="Pool quality/Faulty" >{"Pool quality/Faulty"}</MenuItem>
                                        <MenuItem value="Doesn't suit me" >{"Doesn't suit me"}</MenuItem>
                                        <MenuItem value="Incorrect item received" >{"Incorrect item received"}</MenuItem>
                                        <MenuItem value="Parcel damaged on arrival" >{"Parcel damaged on arrival"}</MenuItem>
                                        <MenuItem value={OTHER_REASON} >{OTHER_REASON}</MenuItem>
                                    </Select>
                                </FormControl>
                                {reason === OTHER_REASON ?
                                    <div>
                                        <TextField
                                            id="outlined-other-reason"
                                            label="Note"
                                            margin="dense"
                                            variant="outlined"
                                            value={this.state.note}
                                            onChange={this.handleNoteChange}
                                            fullWidth
                                        />
                                    </div>
                                    : null
                                }
                            </div>
                        </div>
                        <div className={classes.uploadBlock}>
                            <Grid container spacing={8} className={classes.textField}>
                                <Grid item sm={10} xs={9}>
                                    <Typography variant="display3" color="primary" className={classes.uploadTitle}>Upload photos</Typography>
                                </Grid>
                                <Grid item sm={2} xs={3} style={{ textAlign: 'right' }}>
                                    {this.state.imgList && this.state.imgList.length > 0 ?
                                        (<ButtonBase onClick={this.handleRemovePhoto}>
                                            <Typography variant="display3" color="secondary">
                                                Clear all
                                        </Typography>
                                        </ButtonBase>) :
                                        null
                                    }

                                </Grid>
                            </Grid>
                            <UploadPhotoList imgSrc={this.state.imgList[-1]} keyName={item.item_id} imgList={this.state.imgList} handleImageChange={this.handleImageChange} photoRequiredMessage={photoRequiredMessages[item.item_id]} />
                        </div>
                    </React.Fragment>)
                }
                <Divider />
            </div>
        )
    }
}

export default withStyles(styles)(ClaimItem);
