import React from 'react';
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'

import withStyles from '@material-ui/core/styles/withStyles';
import {
    RETURN_FORM_COLOR_PRIMARY, RETURN_FORM_COLOR_GREY,
    CREATE_CLAIM_SUCCESS, UPDATE_CLAIM_PAYWAY_SUCCESS
} from '../../constants';
import {
    Snackbar, IconButton
} from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close';

import StatusList from '../../components/StatusList';
import ItemConfirm from '../ItemConfirm';
import WaitingApproval from './WaitingApproval'
import ClaimProcess from './ClaimProcess'


const styles = theme => ({
    main: {
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        flex: 1,
    },
    panel: {
        boxShadow: 'none',
        '&:before': {
            backgroundColor: 'white'
        }
    },
    header: {
        paddingTop: '24px',
        paddingBottom: '24px',
        textAlign: 'center',
        borderBottom: '1px solid rgb(239,239,239)'
    },
    footer: {
        textAlign: 'center',
    },
    inlineText: {
        display: 'inline-block',
        color: RETURN_FORM_COLOR_PRIMARY
    },
    contentBlock: {
        flexGrow: 1,
        textAlign: 'left'
    },
    testBlock: {
        backgroundColor: 'red',
        height: '80px'
    },
    refundItemBlock: {
        marginTop: '12px',
        marginLeft: '20px',
    },
    itemList: {
        marginTop: '12px',
        marginLeft: '20px',
        marginRight: '20px',
    },
    titleSpan: {
        color: RETURN_FORM_COLOR_PRIMARY,
        lineHeight: 1.7
    },
    info: {
        marginTop: '12px',
        marginLeft: '20px',
        marginBottom: '24px'
    },
    textSpan: {
        lineHeight: 1.1
    },
    footerTextSpan: {
        color: RETURN_FORM_COLOR_GREY
    },
    content: {
        padding: '24px 0 0 0',
    },
    retryText: {
        color: RETURN_FORM_COLOR_GREY
    },
    input: {
        display: 'none'
    },
    uploadButton: {
        marginTop: 20,
        minWidth: '120px',
        height: '30px',
    },
    display: {
        margin: '30px 0 0 0'
    }
});


class StatusPage extends React.Component {
    state = {
        expanded: false,
        imgSrc: null,
        snackBarOpen: false,
        message: ''
    }
    componentDidMount() {
    }
    toggleExpandButton = () => {
        this.setState({ expanded: !this.state.expanded })
    }
    handleClose = () => {
        this.setState({ snackBarOpen: false });
    }
    genStatusDisplayComponent(cliamStatus) {
        switch (cliamStatus) {
            case "created":
                return {
                    displayComponent: <WaitingApproval />,
                    statusIndex: 0
                }
            case "approved":
                return {
                    displayComponent: <ItemConfirm />,
                    statusIndex: 1
                }
            case "rejected":
                return {
                    displayComponent: <WaitingApproval isReject />,
                    statusIndex: 0
                }
            case "canceled":
                return {
                    displayComponent: <ItemConfirm />,
                    statusIndex: 0
                }
            case "refund pending":
                return {
                    displayComponent: <ClaimProcess />,
                    statusIndex: 2
                }
            case "refund reject":
                return {
                    displayComponent: <ClaimProcess isReject />,
                    statusIndex: 2,
                    helperText: 'Please upload your receipt again'
                }
            case "finished":
                return {
                    displayComponent: <ClaimProcess isFinished />,
                    statusIndex: 3
                }
            default:
                return {
                    displayComponent: null,
                    statusIndex: 0
                }
        }
    }
    showCreateClaimSuccessInfo = () => {
        this.setState({
            snackBarOpen: true,
            message: 'Create Claim Success'
        })
    }
    showUpdateClaimSuccessInfo = () => {
        this.setState({
            snackBarOpen: true,
            message: 'Update Claim Success'
        })
    }
    showInfo = (status) => {
        if (this.state.message === '') {
            if (status === CREATE_CLAIM_SUCCESS) {
                this.showCreateClaimSuccessInfo()
            }
            if (status === UPDATE_CLAIM_PAYWAY_SUCCESS) {
                this.showUpdateClaimSuccessInfo()
            }
        }
    }
    render() {
        const { classes, order } = this.props;
        const paywayType = order.data.claim.payway.type
        let isShippedByCustomer = true
        if (paywayType !== 'paypal' && paywayType !== 'bankTransfer') {
            isShippedByCustomer = false
        }
        // let cliamStatus = "Refund-reject"
        let cliamStatus = order.data.claim.status
        // if (order.data.claim.status)
        const { displayComponent, statusIndex, helperText } = this.genStatusDisplayComponent(cliamStatus)
        this.showInfo(order.status)

        return (
            <React.Fragment>
                <main className={classes.main} >
                    <div className={classes.content}>
                        <StatusList statusIndex={statusIndex} helperText={helperText} isShippedByCustomer={isShippedByCustomer} />
                        <div className={classes.display}>
                            {displayComponent}
                        </div>

                    </div>
                    <Snackbar
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'center',
                        }}
                        open={this.state.snackBarOpen}
                        onClose={this.handleClose}
                        ContentProps={{
                            'aria-describedby': 'message-id',
                        }}
                        message={<span id="message-id">{this.state.message}</span>}
                        action={[
                            <IconButton
                                key="close"
                                aria-label="Close"
                                color="inherit"
                                className={classes.close}
                                onClick={this.handleClose}
                            >
                                <CloseIcon />
                            </IconButton>,
                        ]}
                    />
                </main>
            </React.Fragment >
        );
    }
}


const mapDispatchToProps = dispatch => bindActionCreators({
}, dispatch)

const mapStateToProps = ({ returnSystem: { order } }) => ({
    order
})

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(styles)(StatusPage))