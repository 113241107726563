import React from 'react';
import { Redirect, Route } from 'react-router-dom'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import withStyles from '@material-ui/core/styles/withStyles';
import {
    CircularProgress
} from '@material-ui/core'
import { getClaimData } from '../../actions/claim'
import log from 'utils/debug'

const styles = theme => ({
    main: {
        height: '100%',
        width: 'auto',
        [theme.breakpoints.up(600 + theme.spacing.unit * 2 * 2)]: {
            width: 600,
            marginLeft: 'auto',
            marginRight: 'auto',
        },
        backgroundColor: 'white',
    },
    progress: {
    },
    progressBlock: {
        paddingTop: 100,
        margin: '0 auto',
        width: '80px'
    }

});


class AuthorizedRoute extends React.Component {
    getClaimData = (queryToken) => {
        this.props.getClaimData(queryToken)
    }
    render() {
        const { component: Component, pending, loaded, classes, logged, ...rest } = this.props
        const { search } = this.props.location
        if (search !== "") {
            if (loaded) {
                return <Redirect to="/freereturn/return/status" />
            }
            log(search)
            const queryString = search.slice(1)
            const queryToken = queryString.split("&")
            this.getClaimData(queryToken)
            return <div className={classes.main}><div className={classes.progressBlock}><CircularProgress className={classes.progress} size={80} /></div></div>
        }
        return (
            <Route {...rest} render={props => {
                if (!loaded) return <Redirect to="/freereturn/return/login/" />
                return pending
                    ? <div className={classes.main}><div className={classes.progressBlock}><CircularProgress className={classes.progress} size={80} /></div></div>
                    : <Component {...this.props} />
            }} />
        )
    }
}

const stateToProps = ({ returnSystem: { loggedUserState } }) => ({
    pending: loggedUserState.pending,
    loaded: loggedUserState.loaded
})
const mapDispatchToProps = dispatch => bindActionCreators({
    getClaimData
}, dispatch)
export default connect(stateToProps, mapDispatchToProps)(withStyles(styles)(AuthorizedRoute))