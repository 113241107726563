import React from 'react'
import { BrowserRouter, Route, Switch } from 'react-router-dom'

import ReturnSystem from './App/freeReturn/returnSystem'
import ShopSystem from './App/freeReturn/shopSystem/router'
import InventoryCashBackShop from './App/inventoryCashBack/shopSystem/router'
import ICBWarehouseSystem from './App/inventoryCashBack/warehouseSystem/router'
import LazadaSystem from './App/lazada/returnSystem'

import WithSentry from './common/hoc/withSentry'

function RootRouterApp() {
  return (
    <BrowserRouter>
      <Switch>
        <Route strict path="/freereturn/return" component={ReturnSystem} />
        <Route strict path="/freereturn/shop" component={ShopSystem} />
        <Route strict path="/inventorycashback" component={InventoryCashBackShop} />
        <Route strict path="/lazada" component={LazadaSystem} />
        <Route strict path="/warehouse/inventorycashback" component={ICBWarehouseSystem} />
      </Switch>
    </BrowserRouter>
  )
}

export default WithSentry(RootRouterApp);
