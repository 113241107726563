import React, { Component } from 'react';

import Tabs from '../../components/Tabs';

class MyApp extends Component {
  render() {
    return <Tabs />
  }
}

export default MyApp;

