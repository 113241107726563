import React from 'react'
import {
    Badge
} from "@shopify/polaris";

export default ({ status }) => {
    switch (status) {
        case 'created':
            return (
                <Badge status="attention">Pending Approval</Badge>
            );
        case 'approved':
            return (
                <Badge status="success">Approved</Badge>
            );
        case 'rejected':
            return (
                <Badge status="warning">Rejected</Badge>
            );
        case 'refund pending':
            return (
                <Badge status="success">Approved</Badge>
            );
        case 'refund reject':
            return (
                <Badge status="success">Approved</Badge>
            );
        case 'finished':
            return (
                <Badge status="success">Approved</Badge>
            );
        default:
            return (
                <Badge status="attention">Pending Approval</Badge>
            );
    }
}
