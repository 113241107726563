import React from 'react'
import withStyles from '@material-ui/core/styles/withStyles';

import ClaimItem from './claimItem'

const styles = theme => ({
    main: {
        padding: '8px 0'
    }
})

class ClaimList extends React.PureComponent {
    render() {

        const { items, classes, updateClaimState, selectMode, selectHandle, photoRequiredMessages } = this.props;
        return (
            <div className={classes.main}>
                {
                    items.map((map, index) => {
                        return <ClaimItem key={map.item_id + '_index_' + index} item={map} updateClaimState={updateClaimState}
                            selectMode={selectMode} selectHandle={selectHandle} photoRequiredMessages={photoRequiredMessages} />
                    })
                }
            </div>
        )
    }
}

export default withStyles(styles)(ClaimList);
