import React, { Component } from 'react'
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux'
import moment from 'moment'
import {
    Button,
    Card,
    DataTable,
} from "@shopify/polaris";
import {
    Grid, Dialog, Slide, Tooltip
} from '@material-ui/core';
import { listClaims } from '../../actions/claim'
import { WrappedApiStatusDispatch } from '../../actions/withAPIStatus'
import { DATE_FORMAT_DEFAULT } from '../../constants'
import ClaimComp from '../../components/ClaimGroup'
import ClaimGroupStatus from '../../components/ClaimGroupStatus'
import EmptyContent from '../../components/EmptyDisplay'
import log from 'utils/debug'

class Claims extends Component {
    state = {
        claimDataList: [],
        active: false,
        targetClaimGroup: null
    }

    componentDidMount() {
        this.listClaimsAndRefreshState()
    }

    listClaimsAndRefreshState = () => {
        this.props.WrappedApiStatusDispatch(
            listClaims()
                .then(json => {
                    this.initState(json)
                }).catch(err => {
                })
        )
    }

    initState = (dataList) => {
        this.setState({ claimDataList: dataList.claims })
    }
    toggleModal = (claimGroup) => () => {
        this.setState(({ active }) => ({ active: !active, targetClaimGroup: claimGroup }));
    }
    genRows = (data) => {
        return data.map(claimGroup => {
            const createTime = moment(claimGroup.created_at).format(DATE_FORMAT_DEFAULT)
            return [
                claimGroup.claim_id,
                createTime,
                <ClaimGroupStatus subClaims={claimGroup.sub_claims} />,
                <Button
                    onClick={this.toggleModal(claimGroup)}
                >
                    View detail
                </Button>
            ]
        })
    }

    render() {
        const { claimDataList, active, targetClaimGroup } = this.state
        log(claimDataList)
        if (!claimDataList || claimDataList.length === 0) {
            return (
                <EmptyContent
                    title="You do not have any claims at the moment."
                    subTitle="Note: Please submit a claim when the policy is ready"
                />
            )
        }
        const rows = this.genRows(claimDataList)
        return (
            <div style={{ padding: 10 }}>
                <Card>
                    <DataTable
                        columnContentTypes={[
                            'text',
                            'text',
                            'text',
                            'text',
                        ]}
                        headings={[
                            'ID',
                            'Create at',
                            'Status',
                            '',
                        ]}
                        rows={rows}
                    />
                </Card>
                {active &&
                    <ClaimComp
                        data={targetClaimGroup.sub_claims}
                        status={targetClaimGroup.status}
                        externalFunc={this.listClaimsAndRefreshState}
                        toggleModalFuc={this.toggleModal(null)}
                        showModal={active}
                    />
                }
            </div>
        )
    }
}

const mapDispatchToProps = dispatch => bindActionCreators({
    WrappedApiStatusDispatch
}, dispatch)
export default connect(null, mapDispatchToProps)(Claims)