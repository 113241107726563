import React from 'react';
import { API_URL } from 'utils/apiUtils'
import CircularProgress from '@material-ui/core/CircularProgress';

const comp = (insurance) => (
    class extends React.Component {
        componentDidMount() {
            window.location = `${API_URL}/v1/merchants/install_or_login${this.props.location.search}&insurance=${insurance}`
        }
        render() {
            return (
                <div style={{ display: 'flex', justifyContent: "center", padding: 50 }}>
                    <CircularProgress />
                </div>
            )
        }
    }
)



export default comp
