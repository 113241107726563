import React, { Component } from "react";
import { connect } from "react-redux";
import {
    LineChart,
    Line,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    Legend,
    BarChart,
    Bar
} from 'recharts';
import { Card, Heading } from "@shopify/polaris";

class Dashboard extends Component {
    renderLineChart() {
        const { dashboard } = this.props;
        const data = dashboard.dataList[0].data;
        return (
            <LineChart
                width={700}
                height={300}
                data={data}
                margin={{
                    top: 10,
                    right: 30,
                    left: 20,
                    bottom: 5
                }}>
                <XAxis dataKey="name" />
                <YAxis />
                <CartesianGrid strokeDasharray="3 3" />
                <Tooltip />
                <Legend />
                <Line
                    type="monotone"
                    dataKey="salesWithOutFreeReturn"
                    stroke="#8884d8"
                    activeDot={{
                        r: 8
                    }} />
                <Line type="monotone" dataKey="salesWithFreeReturn" stroke="#82ca9d" />
            </LineChart>
        );
    }
    renderBarChart() {
        const { dashboard } = this.props;
        const data = dashboard.dataList[1].data;
        return (
            <BarChart
                width={650}
                height={350}
                data={data}
                margin={{
                    top: 10,
                    right: 30,
                    left: 20,
                    bottom: 5
                }}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="name" />
                <YAxis />
                <Tooltip />
                <Legend />
                <Bar dataKey="orderWithFreeReturn" fill="#8884d8" />
                <Bar dataKey="orderNormal" fill="#82ca9d" />
            </BarChart>
        );
    }
    render() {
        return (
            <div>
                <Card>
                    <div style={{
                        margin: 10
                    }}>
                        <Heading>Total Sales</Heading>
                    </div>
                    {this.renderLineChart()}
                </Card>
                <Card>
                    <div style={{
                        margin: 10
                    }}>
                        <Heading>Free Return Sales</Heading>
                    </div>
                    {this.renderBarChart()}
                </Card>
            </div>
        );
    }
}

function mapStateToProps({ shopSystem: { dashboard } }) {
    return { dashboard };
}
export default connect(mapStateToProps)(Dashboard);