import React from 'react';
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import classNames from 'classnames'

import withStyles from '@material-ui/core/styles/withStyles';
import {
    Typography, TextField, Button, Select, OutlinedInput, FormControl,
    InputLabel, MenuItem, Grid, ButtonBase
} from '@material-ui/core';
import { RETURN_FORM_COLOR_PRIMARY, OTHER_REASON } from '../../constants';
import UploadPhotoComponent from '../../components/UploadPhoto'
import BankIcon from '../../assets/ic_banktransfer.png'
import PaypalIcon from '../../assets/ic_paypal.png'
import { fillinData } from '../../actions'

const styles = theme => ({
    main: {
        flex: '1 1',
        display: 'flex',
        flexDirection: 'column',
        padding: '24px'
    },
    blockDefault: {
        margin: '15px 0'
    },
    input: {
        marginTop: 10
    },
    iconBoxContainer: {
        display: 'flex',
        marginTop: 10
    },
    iconBox: {
        height: '90px',
        flex: '0 0 140px',
        marginRight: 15,
        backgroundColor: '#efefef',
        textAlign: 'center',
        display: 'inline-block',
        borderRadius: 3
    },
    iconBoxSelected: {
        border: '1px solid ' + RETURN_FORM_COLOR_PRIMARY,
        backgroundColor: 'rgba(79, 79, 203, 0.1)',
    },
    contentText: {
        color: RETURN_FORM_COLOR_PRIMARY,
        textAlign: 'center'
    },
    image: {
        width: 55
    },
    buttonBase: {
        height: '100%',
        width: '100%'
    }
});

class ReturnForm extends React.Component {
    state = {
        imgSrc: null,
        file: null,
        courier: '',
        otherCourier: '',
        trackingId: '',
        deliverFee: '',
        refundMethod: '',
        refundCurrency: 'SGD'
    };
    componentWillMount() {
        const { tempStatus: { returnForm } } = this.props
        if (!!returnForm) {
            const o = Object.assign({}, returnForm)
            if (o.originCourier && o.originCourier === OTHER_REASON) {
                o.otherCourier = o.courier
                o.courier = o.originCourier
            }
            this.setState({
                ...o
            })
        }
    }
    handleImageChange = (e) => {
        // Assuming only image
        e.preventDefault();

        let reader = new FileReader();
        let file = e.target.files[0];
        if (file) {
            reader.onloadend = () => {
                this.setState({
                    file: file,
                    imgSrc: reader.result
                });
            }

            reader.readAsDataURL(file)
        }
    }
    handleOtherCourierChange = (e) => {
        this.setState({
            otherCourier: e.target.value
        });
    }
    handleRemovePhoto = () => {
        this.setState({
            file: null,
            imgSrc: null
        });
    }
    handleTextChange = event => {
        this.setState({
            [event.target.name]: event.target.value,
        });
    };
    handleCourier = event => {
        this.setState({ courier: event.target.value });
    }
    decideSubmitAvailable = () => {
        const state = this.state;
        if (!state.imgSrc || state.courier === '' || state.trackingId === '' || state.deliverFee === '') {
            return true
        }
        return state.buttonLoading
    }
    handleSelectRefund = name => () => {
        this.setState({
            refundMethod: name
        })
    }
    handleCurrency = event => {
        this.setState({
            refundCurrency: event.target.value
        })
    }
    handleSubmit = () => {
        // convert otherCourier to courier
        const copy = Object.assign({}, this.state)
        if (copy.courier === OTHER_REASON) {
            copy.originCourier = copy.courier
            copy.courier = copy.otherCourier
        }

        this.props.fillinData(copy)
        if (this.state.refundMethod === 'paypal') {
            this.props.history.push("/freereturn/return/confirm/paypal");
        } else if (this.state.refundMethod === 'bank') {
            this.props.history.push("/freereturn/return/confirm/bank_transfer");
        }
    }
    render() {
        const { classes } = this.props;
        const submiteAvailable = this.decideSubmitAvailable();
        const paypalButtonClasssesArray = [classes.iconBox]
        const bankButtonClasssesArray = [classes.iconBox]
        if (this.state.refundMethod === 'paypal') {
            paypalButtonClasssesArray.push(classes.iconBoxSelected)
        }
        if (this.state.refundMethod === 'bank') {
            bankButtonClasssesArray.push(classes.iconBoxSelected)
        }

        return (
            <React.Fragment>
                <div className={classes.main}>
                    <div className={classes.blockDefault}>
                        <Typography variant="display2">After shipping the items to merchant, fill in the following information to get refund</Typography>
                    </div>
                    <div className={classes.blockDefault}>
                        <Typography variant="display2" color="primary">Courier</Typography>
                        <FormControl fullWidth className={classes.input}>
                            <InputLabel htmlFor="outlined-courier-native">Choose a courier</InputLabel>
                            <Select
                                displayEmpty
                                value={this.state.courier}
                                onChange={this.handleCourier}
                                input={
                                    <OutlinedInput
                                        name="courier"
                                        labelWidth={4}
                                        id="outlined-courier-native"
                                    />
                                }
                            >
                                <MenuItem value="DHL" >{"DHL"}</MenuItem>
                                <MenuItem value="UPS" >{"UPS"}</MenuItem>
                                <MenuItem value="FedEx" >{"FedEx"}</MenuItem>
                                <MenuItem value={OTHER_REASON} >{OTHER_REASON}</MenuItem>
                            </Select>
                        </FormControl>
                        {this.state.courier === OTHER_REASON ?
                            <div>
                                <TextField
                                    id="outlined-other-reason"
                                    label="Courier"
                                    margin="dense"
                                    variant="outlined"
                                    value={this.state.otherCourier}
                                    onChange={this.handleOtherCourierChange}
                                    fullWidth
                                />
                            </div>
                            : null
                        }
                    </div>
                    <div className={classes.blockDefault}>
                        <Typography variant="display2" color="primary">Tracking ID</Typography>
                        <TextField
                            id="outlined-trackin-id-input"
                            label=""
                            name="trackingId"
                            variant="outlined"
                            onChange={this.handleTextChange}
                            value={this.state.trackingId}
                            className={classes.input}
                            fullWidth
                        />
                    </div>
                    <div className={classes.blockDefault}>
                        <Typography variant="display2" color="primary">Delivery cost</Typography>
                        <Grid container spacing={8} className={classes.input}>
                            <Grid item sm={3} xs={3}>
                                <Select
                                    displayEmpty
                                    value={this.state.refundCurrency}
                                    onChange={this.handleCurrency}
                                    input={
                                        <OutlinedInput
                                            name="currency"
                                            labelWidth={4}
                                            id="outlined-currency-native"
                                        />
                                    }
                                >
                                    <MenuItem value="SGD" >{"SGD"}</MenuItem>
                                    <MenuItem value="USD" >{"USD"}</MenuItem>
                                    <MenuItem value="TWD" >{"TWD"}</MenuItem>
                                    <MenuItem value="AUD" >{"AUD"}</MenuItem>
                                    <MenuItem value="HKD" >{"HKD"}</MenuItem>
                                    <MenuItem value="EUR" >{"EUR"}</MenuItem>
                                </Select>
                            </Grid>
                            <Grid item sm={9} xs={9} >
                                <TextField
                                    id="outlined-delivery-input"
                                    label=""
                                    name="deliverFee"
                                    variant="outlined"
                                    onChange={this.handleTextChange}
                                    value={this.state.deliverFee}
                                    fullWidth
                                />
                            </Grid>
                        </Grid>
                    </div>
                    <div className={classes.blockDefault}>
                        <div>
                            <Typography variant="display2" color="primary">Refund method</Typography>
                        </div>
                        <div className={classes.iconBoxContainer}>
                            <div className={classNames(paypalButtonClasssesArray)} >
                                <ButtonBase className={classes.buttonBase} onClick={this.handleSelectRefund('paypal')}>
                                    <div>
                                        <img src={PaypalIcon} alt="return method" className={classes.image} />
                                    </div>
                                </ButtonBase>
                            </div>
                            <div className={classNames(bankButtonClasssesArray)} >
                                <ButtonBase className={classes.buttonBase} onClick={this.handleSelectRefund('bank')}>
                                    <div >
                                        <img src={BankIcon} alt="return method" className={classes.image} />
                                        <Typography variant="display1" className={classes.contentText}>Bank Transfer</Typography>
                                    </div>
                                </ButtonBase>
                            </div>
                        </div>
                    </div>
                    <div className={classes.blockDefault}>
                        <Grid container spacing={8} >
                            <Grid item sm={10} xs={9}>
                                <Typography variant="display2" color="primary" className={classes.uploadTitle}>Upload a receipt</Typography>
                                <Typography variant="display1" color="primary" className={classes.uploadTitle}>(The tracking ID and delivery cost should be visible in the receipt)</Typography>
                            </Grid>
                            <Grid item sm={2} xs={3} style={{ textAlign: 'right' }}>
                                {this.state.imgSrc ?
                                    (<ButtonBase onClick={this.handleRemovePhoto}>
                                        <Typography variant="display3" color="secondary">
                                            Remove
                                        </Typography>
                                    </ButtonBase>) :
                                    null
                                }

                            </Grid>
                            <Grid item sm={12} xs={12}>
                                <UploadPhotoComponent
                                    handleImageChange={this.handleImageChange}
                                    imgSrc={this.state.imgSrc}
                                />
                            </Grid>
                        </Grid>
                    </div>
                    <div className={classes.blockDefault}>
                        <Button fullWidth variant="extendedFab" color="primary"
                            disabled={submiteAvailable} onClick={this.handleSubmit}>
                            Proceed
                        </Button>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

const mapStateToProps = ({ returnSystem: { tempStatus } }) => ({
    tempStatus
})
const mapDispatchToProps = dispatch => bindActionCreators({
    fillinData
}, dispatch)


export default connect(
    mapStateToProps, mapDispatchToProps
)(withStyles(styles)(ReturnForm))
