import React from 'react'

function EmptyContent({ title, subTitle }) {
    return (
        <div style={{ padding: 40, backgroundColor: "#f4f6f8", color: "#999999" }}>
            {title && (
                <div style={{ fontSize: 30, margin: 20 }}>
                    {title}
                </div>
            )}
            {subTitle && (
                <div style={{ margin: 20 }}>
                    {subTitle}
                </div>
            )}
        </div>
    )
}

export default EmptyContent