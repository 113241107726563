import * as Sentry from '@sentry/browser';

export function withApiStatus(props) {
    return (dispatch) => {
        return (promiseObject, customErrorHandle) => {
            return withCatchError(dispatch, props)(withAPI(dispatch, props)(promiseObject), customErrorHandle)
        }
    }
}

export function withAPI(dispatch, props) {
    return (promiseObject) => {
        if (props.API_START) {
            dispatch({ type: props.API_START })
        }
        return promiseObject.then(_ => {
            if (props.API_DONE) {
                dispatch({ type: props.API_DONE })
            }
        })
    }
}



export function withCatchError(dispatch, props) {
    return (promiseObject, customErrorHandle) => {
        return promiseObject.catch(error => {
            const { status } = error
            if (props.API_ERROR) {
                const dispatchDefaultData = {
                    type: props.API_ERROR
                }
                if (error && typeof error.json === 'function') {
                    error.json().then(errorJson => {
                        const dispatchData = handleError(status, dispatchDefaultData, errorJson, customErrorHandle, props)
                        dispatch(dispatchData)
                    })
                } else {
                    Sentry.captureException(error);
                }
            }
        })
    }
}

function handleError(status, dispatchData, errorJson, customErrorHandle, props) {
    const wrappedDispatchData = handleErrorMapping.handleError[status] && handleErrorMapping.handleError[status](dispatchData, errorJson)
    const propsHandler = props && props.handleError
    const handlerMapping = {
        ...propsHandler,
        ...customErrorHandle
    }
    if (handlerMapping[status]) {
        const handler = handlerMapping[status]
        return handler(wrappedDispatchData, errorJson)
    } else if (handleErrorMapping.handleError[status]) {
        return wrappedDispatchData
    }
    else {
        return Object.assign({}, dispatchData, {
            payload: {
                msg: 'Unknown error'
            }
        })
    }
}

const handleErrorMapping = {
    handleError: {
        400: handleError400,
        401: handleError401,
        403: handleError403,
        404: handleError404,
        500: handleError500,
    }
}

function handleError400(dispatchData) {
    return Object.assign({}, dispatchData, {
        payload: {
            msg: 'Request Invalid'
        }
    })
}
function handleError401(dispatchData) {
    return Object.assign({}, dispatchData, {
        payload: {
            msg: 'Unauthorized'
        }
    })
}
function handleError403(dispatchData) {
    return Object.assign({}, dispatchData, {
        payload: {
            msg: 'Request Forbidden'
        }
    })
}
function handleError404(dispatchData) {
    return Object.assign({}, dispatchData, {
        payload: {
            msg: 'Request Not Found'
        }
    })
}
function handleError500(dispatchData) {
    return Object.assign({}, dispatchData, {
        payload: {
            msg: 'Server Error'
        }
    })
}