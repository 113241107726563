import React from 'react';
import { compose, withProps } from "recompose"
import { withScriptjs, withGoogleMap, GoogleMap, Marker } from "react-google-maps"

const MyMapComponent = compose(
    withProps({
        googleMapURL: "https://maps.googleapis.com/maps/api/js?key=AIzaSyDpDnAi6DoQy2IKftTmQpHlDxs8ZBH2t6Y&libraries=visualization",
        loadingElement: <div style={{ height: `100%` }} />,
        containerElement: <div style={{ height: `400px` }} />,
        mapElement: <div style={{ height: `100%` }} />,
    }),
    withScriptjs,
    withGoogleMap
)((props) =>
    (<GoogleMap
        defaultZoom={12}
        defaultCenter={{ lat: 1.3437449, lng: 103.863833 }}
    >
        {props.isMarkerShown && props.markerList && props.markerList.map((m, index) => {
            return <Marker key={`map-marker-${index}`} position={{ lat: m.latitude, lng: m.longitude }} onClick={props.onMarkerClick(index)} />
        })}
    </GoogleMap>)
)

export default MyMapComponent
