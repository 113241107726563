import axFetch from 'utils/fetch'
import { DefaultHeader } from 'utils/apiUtils'


export function listProducts(claims) {
    const fetchOptions = {
        method: 'GET',
        headers: {
            ...DefaultHeader
        },
        credentials: 'include',
    }
    return axFetch(`/v1/inventorycashback/products`, fetchOptions)
}