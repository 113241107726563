const prefix = 'LAZADA_'

export const LOGGING_SUCCESS = prefix + 'LOGGING_SUCCESS'
export const LOGOUT_SUCCESS = prefix + 'LOGOUT_SUCCESS'

export const GET_ORDER_SUCCESS = prefix + 'GET_ORDER_SUCCESS'

export const GET_CLAIM_SUCCESS = prefix + 'GET_CLAIM_SUCCESS'
export const CREATE_CLAIM_SUCCESS = prefix + 'CREATE_CLAIM_SUCCESS'

export const CHANGE_ORDER_ID_SUCCESS = prefix + 'CHANGE_ORDER_ID_SUCCESS'

export const GET_CLAIM_DATA_SUCCESS = prefix + 'GET_CLAIM_DATA_SUCCESS'
export const DEFAULT_BEFORE_API = prefix + 'DEFAULT_BEFORE_API'
export const DEAULT_AFTER_API = prefix + 'DEAULT_AFTER_API'

export const FILL_IN_RETURN_FORM = prefix + 'FILL_IN_RETURN_FORM'

export const UPDATE_CLAIM_PAYWAY_SUCCESS = prefix + 'UPDATE_CLAIM_PAYWAY_SUCCESS'
export const CREATE_LOGISTIC_REFUND_SUCCESS = prefix + 'CREATE_LOGISTIC_REFUND_SUCCESS'
export const GET_LOGISTIC_BY_CLAIM_SUCCESS = prefix + 'GET_LOGISTIC_BY_CLAIM_SUCCESS'
export const UPDATE_SENDER_RECEIVER_INFO = prefix + 'UPDATE_SENDER_RECEIVER_INFO'

export const API_START = prefix + "API_START"
export const API_DONE = prefix + "API_DONE"
export const API_ERROR = prefix + "API_ERROR"
export const API_RESET_STATUS = prefix + "API_RESET_STATUS"

export const RETURN_FORM_COLOR_PRIMARY = '#4f4fcb'
export const RETURN_FORM_COLOR_SECONDARY = '#fd687c'
export const RETURN_FORM_COLOR_BLACK = '#222222'
export const RETURN_FORM_COLOR_GREY = '#a9aebe'
export const RETURN_FORM_COLOR_ERROR = '#d0021b'
export const OTHER_REASON = "Others(Please specify in additional notes)"