import React, { useContext } from 'react'
import Cookies from 'js-cookie'
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux'
import {
  Button
} from "@shopify/polaris";
import {
  Grid, ButtonBase
} from "@material-ui/core"
import { DEFAULT_PRIMARY_COLOR, TOKEN_NAME } from '../../constants'
import { ShopifyConfigContext } from 'common/hoc/withShopifyConfig'
import log from 'utils/debug'
import { withTabContext } from '../../components/Tabs'
import { setupReturnYo, resetReturnYo } from '../../actions/App'
import { API_URL } from 'utils/apiUtils';
import { getScopeFromJWT, checkSetupStatusFromJWT, sendMail } from 'utils/index'
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';


const ButtonStyles = {
  base: {
    width: "100%",
    padding: 10,
    textAlign: "left",
    fontSize: 14,
  },
  active: {
    backgroundColor: "#88889a",
    color: 'white',
    fontWeight: 700,
  },
  triangle: {
    borderColor: "transparent transparent transparent #88889a",
    borderStyle: "solid",
    borderWidth: "7px 0px 7px 10px",
    height: "0px",
    width: "0px",
  }
}
function AnchorButton({ children, active, onClick }) {
  const s = Object.assign({}, ButtonStyles.base, active ? ButtonStyles.active : {})
  return (
    <ButtonBase style={{ width: "100%" }} onClick={onClick}>
      <div style={s}>
        {children}
      </div>
      {active &&
        <div style={ButtonStyles.triangle} />
      }
    </ButtonBase>
  )
}

function ResetButton({ show, onClick }) {
  if (show) {
    return <Button onClick={onClick} >Reset Igloo Returns</Button>
  }
  return null
}
const setupText = "Setup theme is done."
const resetText = "Reset theme is done."


class Tutorial extends React.Component {
  state = {
    active: 0,
    canWriteTheme: false,
    setupButtonText: '',
    openRequireAuth: false,
    openInfo: false,
    openResetCheck: false,
    openSetupDone: false,
    callbackText: "",
  }
  componentDidMount() {
    const afterSetupButton = Cookies.get("ax_click_setup_button")
    if (afterSetupButton) {
      Cookies.remove("ax_click_setup_button")
      if (afterSetupButton === setupText) {
        this.setState({ openInfo: true })
      } else if (afterSetupButton === resetText) {
        this.setState({ openResetCheck: true })
      }
    }
  }
  handleButton = (url) => () => {
    // const shop = getShopFromJWT(TOKEN_NAME)
    log(url)
    window.open(`${url}/admin/themes`)
  }

  handleSetupButton = () => {
    // if (this.state.canWriteTheme) {
    //     this.setState({ openInfo: true })
    // } else {
    //     log('reauthenticate for additional scope')
    //     this.setState({ openRequireAuth: true })
    // }
    this.setState({ openInfo: true })
  }
  handleSetupReturnYo = () => {
    const { setupReturnYo } = this.props;
    setupReturnYo((status) => {
      if (status === 403) {
        this.setState({ openRequireAuth: true, openInfo: false, callbackText: setupText })
      } else {
        this.setState({ openRequireAuth: false, openInfo: false, callbackText: setupText }, () => {
          this.toggleOpenSetupDone()
        })
      }
    });
  }
  handleResetButton = () => {
    this.setState({ openResetCheck: true })
  }
  handleResetReturn = () => {
    const { resetReturnYo } = this.props;
    resetReturnYo((status) => {
      if (status === 403) {
        this.setState({ openRequireAuth: true, openInfo: false, openResetCheck: false, callbackText: resetText })
      } else {
        this.setState({ openRequireAuth: false, openInfo: false, openResetCheck: false, callbackText: resetText }, () => {
          this.toggleOpenSetupDone()
        })
      }
    });
  }

  handleTouchStepButton = (anchor, active) => () => {
    this.setState({ active })
    this.iframe.contentWindow.postMessage({ anchor }, "*")
  }

  componentWillMount() {
    const scopes = getScopeFromJWT(TOKEN_NAME)
    if (scopes.includes("write_themes")) {
      const isSetupTheme = checkSetupStatusFromJWT(TOKEN_NAME)
      this.setState({ canWriteTheme: true, isSetupTheme: isSetupTheme })
    } else {
      const isSetupTheme = checkSetupStatusFromJWT(TOKEN_NAME)
      this.setState({ canWriteTheme: false, isSetupTheme: isSetupTheme })
    }
  }
  handleCloseRequireAuth = () => {
    this.setState({ openRequireAuth: false, openInfo: false })
  }
  handleCloseInfo = () => {
    this.setState({ openRequireAuth: false, openInfo: false, openSetupDone: false, openResetCheck: false })
  }
  handleAuthGo = () => {
    const { callbackText } = this.state
    this.setState({ openRequireAuth: false, openInfo: false }, () => {
      Cookies.set("ax_click_setup_button", callbackText)
      window.top.location.href = `${API_URL}/v1/insurances/freereturn/addscope`
      // window.open(`${API_URL}/v1/insurances/freereturn/addscope`, "Require Write Theme Scope")
    })
  }
  toggleOpenSetupDone = () => {
    this.setState(({ openSetupDone }) => ({ openSetupDone: !openSetupDone }))
  }
  render() {
    const { active, openRequireAuth, openResetCheck, openInfo, openSetupDone, callbackText } = this.state
    return (
      <ShopifyConfigContext.Consumer>
        {value => (
          <div style={{ padding: '0px 40px', flex: 1, display: 'flex', flexDirection: "column" }}>
            <div style={{ margin: 20 }}>
              <span style={{ fontSize: 20, fontWeight: 700, color: DEFAULT_PRIMARY_COLOR }}>
                Please follow tutorial guide below here.
                            </span>
            </div>
            <Grid style={{ margin: '10px 20px 20px 20px' }} container spacing={16}>
              <Grid item><Button onClick={this.handleSetupButton} >Setup Igloo Returns</Button></Grid>
              <Grid item><Button onClick={this.handleResetButton} >Reset Igloo Returns</Button></Grid>
            </Grid>
            <hr style={{ border: 0, borderTop: '1px solid #ddd' }} />
            <Grid container style={{ marginTop: 5, marginBottom: 5, flex: 1 }} spacing={8}>
              <Grid item container direction="column" sm={2} xs={2} spacing={8}>
                <Grid item>
                  <AnchorButton onClick={this.handleTouchStepButton("intro", 0)} active={active === 0}>Intro</AnchorButton>
                </Grid>
                <Grid item>
                  <AnchorButton onClick={this.handleTouchStepButton("howsetup", 1)} active={active === 1}>Setup</AnchorButton>
                </Grid>
                <Grid item>
                  <AnchorButton onClick={this.handleTouchStepButton("uninstallation", 2)} active={active === 2}>Uninstallation</AnchorButton>
                </Grid>
                <Grid item>
                  <AnchorButton onClick={this.handleTouchStepButton("contactus", 3)} active={active === 3}>Contact Us</AnchorButton>
                </Grid>

              </Grid>
              <Grid item sm={10} xs={10}>
                <iframe
                  src="https://static.yopify.axinan.com/returnyo/guide.html#intro"
                  height={'95%'}
                  width={'100%'}
                  frameBorder={0}
                  ref={(e) => { this.iframe = e }} />
              </Grid>

            </Grid>
            <Dialog
              open={openRequireAuth}
              onClose={this.handleCloseRequireAuth}
            >
              <DialogContent>
                <div >
                  <span style={{ fontSize: 16, color: DEFAULT_PRIMARY_COLOR }}>
                    Igloo Returns require your permission to do further action.
                    Igloo Returns will redirect to authorization page to get your permission and,
                    please come back to click setup button again after finishing authorization processing.
                                    </span>
                </div>
              </DialogContent>
              <DialogActions>
                <Button onClick={this.handleCloseRequireAuth} plain color="secondary">
                  No, thanks
                                </Button>
                <div style={{ flex: '0 0 30px' }} />
                <Button onClick={this.handleAuthGo} plain>
                  OK
                                </Button>
                <div style={{ flex: '0 0 10px' }} />
              </DialogActions>
            </Dialog>
            <Dialog
              open={openInfo}
              onClose={this.handleCloseInfo}
            >
              <DialogContent>
                <div >
                  <p style={{ fontSize: 16, color: DEFAULT_PRIMARY_COLOR }}>
                    In setup processing, there are somethings you should know.
                                    </p>
                  <ol>
                    <li>To provide good experience of using Igloo Returns to your customer, Igloo Returns will install tags on your shop theme.</li>
                    <li>And, Igloo Returns will create a coupon product on your shop. The coupon can prove that your customer purchase Igloo Returns service, and with installed tags mentioned above you don't worry it will break customer experience of purchasing.</li>
                    <li>If you need more information, please <a onClick={() => { sendMail("support-yo@axinan.com") }} style={{ color: 'blue', cursor: 'pointer', textDecoration: "underline" }}>contact support</a></li>
                  </ol>
                </div>
              </DialogContent>
              <DialogActions>
                <Button onClick={this.handleCloseInfo} plain color="secondary">
                  CANCEL
                                </Button>
                <div style={{ flex: '0 0 30px' }} />
                <Button onClick={this.handleSetupReturnYo} plain>
                  CONFIRM
                                </Button>
                <div style={{ flex: '0 0 10px' }} />
              </DialogActions>
            </Dialog>
            <Dialog
              open={openSetupDone}
              onClose={this.handleCloseInfo}
            >
              <DialogContent>
                <div >
                  <p>{callbackText}</p>
                </div>
              </DialogContent>
              <DialogActions>
                <Button onClick={this.handleCloseInfo} plain>
                  OK
                                </Button>
                <div style={{ flex: '0 0 10px' }} />
              </DialogActions>
            </Dialog>
            <Dialog
              open={openResetCheck}
              onClose={this.handleCloseInfo}
            >
              <DialogContent>
                <div >

                  <p style={{ fontSize: 16, color: DEFAULT_PRIMARY_COLOR }}>
                    Are you sure?
                                    </p>
                  <p>1. This action will reset the theme modified by Igloo Returns</p>
                  <p>2. and remove the coupon product created by Igloo Returns</p>
                </div>
              </DialogContent>
              <DialogActions>
                <Button onClick={this.handleCloseInfo} plain color="secondary">
                  NO
                                </Button>
                <div style={{ flex: '0 0 30px' }} />
                <Button onClick={this.handleResetReturn} plain>
                  YES
                                </Button>
                <div style={{ flex: '0 0 10px' }} />
              </DialogActions>
            </Dialog>
          </div>
        )}
      </ShopifyConfigContext.Consumer>
    )
  }
}

const mapDispatchToProps = dispatch => bindActionCreators({
  setupReturnYo, resetReturnYo
}, dispatch)
export default connect(null, mapDispatchToProps)(withTabContext(Tutorial));
