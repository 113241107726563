import React from 'react';
import { Switch, Route } from 'react-router-dom'
import { compose } from 'recompose'

import ErrorPage from './error'
import Home from './index'
import ReturnDetail from './containers/ReturnDetail'
// import WithInstall from './hoc/withInstall'
import WithShopify from './hoc/withShopifyConfig'
import WithApiStatus from './hoc/withApiStatus'
import WithInstall from 'common/hoc/withInstall'

const composedHoc = compose(WithShopify, WithApiStatus)
// const composedInstallHoc = compose(WithInstall, composedHoc)

const App = () => {
    return (
        <Switch>
            <Route exact path={`/freereturn/shop/error`} component={ErrorPage} />
            <Route strict path={`/freereturn/shop/return/:id`} component={composedHoc(ReturnDetail)} />
            <Route exact path={`/freereturn/shop/index`} component={composedHoc(Home)} />
            <Route strict path={`/freereturn/shop`} component={WithInstall("freeReturn")} />
        </Switch>
    )
}

export default App;
