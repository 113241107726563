import React, { Component } from 'react';
import {
    Card,
    Layout,
    Link,
    Tooltip,
} from "@shopify/polaris";
import {
    Grid
} from '@material-ui/core'
import { DEFAULT_PRIMARY_COLOR, DATE_FORMAT_DEFAULT } from '../../constants'

class Faq extends Component {
    render() {
        return(
            <div style={{ padding: '0px 40px' }}>
                <Grid container
                    justify='space-between'
                    alignItems="center"
                    style={{
                        margin: '5px 0px'
                    }}>
                    <Grid item style={{ marginRight: 20 }}>
                        <span style={{ fontSize: 24, fontWeight: 700, color: DEFAULT_PRIMARY_COLOR }}>FAQ</span>
                    </Grid>
                </Grid>
                <Layout>
                    <Layout.Section>
                        <Card title="How Inventory Yo works?">
                            <div style={{ padding: '5px 40px' }}>
                                <p>Managing inventory can be an operational nightmare especially when you cannot clear all stocks on time. We protect your inventory, alleviating your cash pressure by buying back your products when you can't sell them all. Here how it works</p> <br />

                                <p>1. Store owner selects SKUs to be covered on <b>Product</b> Tab.</p>
                                <p>2. Inventory Yo charges service fees for every covered SKU sold. One charge is around <Tooltip active content="10% on the sold product price. If you are not having any products sold, Inventory Yo won't charge you any."> <Link>10% </Link></Tooltip> of the product price.</p>
                                <p>3. At the end of covering period (<Tooltip active content="The coverage period is by default 60 days and this period can't be shorter or longer."><Link>60 days</Link></Tooltip>), the store owner can make a claim on the SKU covered. </p>
                                <p>4. The refund will be returned to the merchant in a form of application credit.</p>
                                <p>5. Note that Inventory Yo preserve the right to adjust the numbers mentioned above.</p>
                            </div>
                        </Card>
                        <Card title="How is the service fee charged?">
                            <div style={{ padding: '5px 40px' }}>
                              <p>Inventory Yo will charge the store owner on every covered SKU sold on a monthly basis. One charge is around 10% of the product price and the amount of service charge in a month should not exceed USD 1,000.</p>
                            </div>
                        </Card>
                        <Card title="What is Policy?">
                            <div style={{ padding: '5px 40px' }}>
                              <p>One Policy is a contract between Shopify store owner and Inventory Yo on one particular SKU for a period of time. Inventory Yo is required to pay Shopify store owner on any approved claim against it.</p>
                              <p>Here are steps how you can create a policy:</p>
                              <p>1. Store owner selects SKUs to be covered on <b>Product</b> Tab.</p>
                              <p>2. Inventory Yo approved/rejected the selected SKUs.</p>
                              <p>3. A policy is issued to the store owner regarding the particular SKU once Inventory Yo approved it. You can also check it on <b>Policy</b> Tab.</p>
                            </div>
                        </Card>
                        <Card title="How to view policies status?">
                            <div style={{ padding: '5px 40px' }}>
                                <p> Go to the <b>Policies</b> tab → Click Detail on a Policy. </p>
                            </div>
                        </Card>
                        <Card title="What is Claim?">
                            <div style={{ padding: '5px 40px' }}>
                              <p>A claim is a formal request to Inventory Yo by refunding to Shopify Store owner:</p>
                                <li> The store owner can ask for a <Tooltip active content="full refund means the service charge that Inventory Yo has charged to the merchant on the same calendar month" ><Link> full refund</Link></Tooltip> if he/she don't want to return anything or</li>
                                <li> The store owner can return the SKU covered to Inventory Yo with the amount up to <Tooltip active content="For example, if Inventory Yo has charged you $10 for service charge, you then can claim up to 2.5 * 10 on items covered. Inventory Yo preserves the right to change this number"><Link>2.5 * the amount of service charge</Link></Tooltip>.</li>
                            </div>
                        </Card>
                        <Card title="How to create a return claim?">
                            <div style={{ padding: '5px 40px' }}>
                                <p>Go to the <b>Policies</b> tab → Click <b>Create Claim</b> button and choose policies you want to claim </p>
                                <p>Adjust the return quantity and refund amount of each policy, and then submit.</p>
                            </div>
                        </Card>
                        <Card title="How to return products to Inventory Yo?">
                            <div style={{ padding: '5px 40px' }}>
                                <p> Go to the <b>Claims</b> tab → Edit a claim → Select the items you want to ship and click the <b>Print Shipping Label</b> button to get a shipping label where the return address and recipient are shown </p>
                                <p>Paste the shipping label on the parcel and ship it, and mark the claim as shipped and fill up the corresponding form.</p>
                            </div>
                        </Card>
                        <Card title="How is the refund fee calculated?">
                            <div style={{ padding: '5px 40px' }}>
                                <p>Refund fee = Service fee - ((return quantity * SKU price) / 2.5)</p>
                                <li>If you have a t-shirt and its price is $10. It sold 100 t-shirts in 60 days after it covered by Inventory Yo</li>
                                <li>The Service fee Inventory Yo charged is $100 ($100 * 10 * 0.1). By the end of the period (60 days), if you do not want to return any t-shirt to Inventory Yo, You will get the refund with $100.</li>
                                <li>But, let's say you want Inventory Yo to cover your inventory burden, Inventory Yo allows you to return up to 25 t-shirts maximum(100 * 2.5 / 10).</li>
                                <li>Also, you can also seek partial refund/return, for example, you can return 10 t-shirts to Inventory Yo, Inventory Yo will refund $60 ($100 - (($10 * 10) / 2.5)</li>
                            </div>
                        </Card>
                        <Card title="How can I contact you?">
                            <div style={{ padding: '5px 40px' }}>
                                <p>We are always there to help you! Please send us an email to <a href="support-yo@axinan.com">support-yo@axinan.com</a> and we will try our best to get back to you soon. </p>
                            </div>
                        </Card>
                        {/*this element is used to padding the bottom element as polaris will by accidently truncate the last one*/}
                        <Card> </Card>
                    </Layout.Section>
                </Layout>
            </div>
        )
    }
}
export default Faq
