import React from 'react';
import Cookies from 'js-cookie';
import { compose } from 'recompose';
import { withRouter } from 'react-router-dom'
import { Button } from '@shopify/polaris';
import {
    Dialog, DialogContent, DialogActions
} from '@material-ui/core'
const enhance = compose(withRouter)

const FirstModal = ({ children, handleClose, open }) => {
    return (
        <Dialog
            open={open}
            onClose={handleClose}
        >
            <DialogContent>
                <div>
                    {"Thank for using Inventory Yo."}
                </div>
                <div>
                    {"Because this is your first time of using Inventory Yo"}
                </div>
                <div>
                    {"Please follow guide in toturial tab"}
                </div>
                <div>
                    {"It will teach you how to use Inventory Yo"}
                </div>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} primary>
                    GO TO TUTORIAL
                </Button>
            </DialogActions>
        </Dialog>
    )
}

export default (WrappedComponent) => (
    enhance(
        class extends React.Component {
            state = {
                notFirst: true,
            }
            componentDidMount() {
                const notFirst = Cookies.get("yopify_icb_not_first_time")
                this.setState({ notFirst: !!notFirst })
            }
            handleClcik = () => {
                this.setState({ notFirst: true }, () => {
                    Cookies.set("yopify_icb_not_first_time", true)
                    this.props.history.push("/inventorycashback/tutorial")
                })
            }
            render() {
                const { notFirst } = this.state
                return (
                    <React.Fragment>
                        <WrappedComponent {...this.props} />
                        {!notFirst &&
                            <FirstModal open={!notFirst} handleClose={this.handleClcik} />
                        }
                    </React.Fragment>
                )
            }
        }
    )
)